import './app-router.scss';

import React, { useEffect } from 'react';
import { BrowserRouter, Route, } from 'react-router-dom';

import { useAppDispatch } from './config/store';
import PrivateRoute from './modules/common/auth/private-route';
import ErrorBoundaryRoutes from './modules/common/error/error-boundary-routes';
import { getEnvironment, getSession } from './modules/common/reducers/authentication';

/* *****  Start links   ***** */
import PortalHome from './modules/portal/sections/home/portalHome';

/* *****  Portal Login   ***** */
import PortalLoginScreen from './modules/portal/sections/login/portalLogin';
import PortalLoginScreenDev from './modules/portal/sections/login/portalLoginDev';
import PortalAuthWrapper from './modules/common/ui-components/portal-auth/portalAuthWrapper';
import LandingPageSelector from './modules/login/landing-page-selector';

import Home from './modules/home/home';
import PortalSessionTimeoutModal from './modules/portal/modal/PortalSessionTimeoutModal';

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export const AppRouter = () => {
    const dispatch = useAppDispatch();
    
    useEffect(() => {
        dispatch(getEnvironment());
        dispatch(getSession());
    }, []);

    return (
        <BrowserRouter basename={baseHref}>
            <div id="app-view-container">
                <ErrorBoundaryRoutes>
                    <Route index element={<LandingPageSelector />} />
                    <Route path="index.html" element={<LandingPageSelector />} />
                    <Route path="home" element={
                        <PrivateRoute>
                            <Home />
                        </PrivateRoute>
                    }>
                    </Route>
                    {/* <Route path="registration" element={
                        <PrivateRoute>
                            <Registration />
                        </PrivateRoute>
                    }>
                    </Route> */}
                    {/* <Route path="sandbox" element={<Sandbox />} /> */}

                    {/*  Portal Pages  */}
                    <Route path="pc/home" element={<PortalAuthWrapper unprotected><PortalHome /></PortalAuthWrapper>} />
                    <Route path="pc/login-dev" element={<PrivateRoute><PortalAuthWrapper authPage><PortalLoginScreenDev /></PortalAuthWrapper></PrivateRoute>} />
                    <Route path="pc/login" element={<PortalAuthWrapper authPage><PortalLoginScreen /></PortalAuthWrapper>} />

                </ErrorBoundaryRoutes>
                <PortalSessionTimeoutModal/>
            </div >
        </BrowserRouter >
    );
};

export default AppRouter;
import { PortalServiceController } from "../../service/controllers";
import { PortalAppController } from "../controllers";
import { deepCopy, isEmpty, isNotEmpty } from "ui/modules/common/logic/utilities/utilities";
import { FetchAllUsersServCommand, FetchFilteredUsersServCommand, FetchSessionPortalServCommand, LoginPortalServCommand, LoginSkipPassPortalServCommand, LogoutPortalServCommand } from "../../service/commands/session-commands";
import { PortalCacheExplorer } from "ui/modules/common/logic/utilities/cache-utilities";
import { useNavigate } from "react-router-dom";

/* *** State helpers *** */
const __lookupSession = (store) => {
  return  deepCopy(store.getState().portal.session);
}
const __verifySession= (store) => {
  var session = __lookupSession(store);   
  return((isNotEmpty(session)&& isNotEmpty(session.isLoaded) && session.isLoaded==true)?true:false);
}
const __lookupViewOnly = (store) => {
  return isNotEmpty(store.getState().portalServ.session.viewOnly) ? deepCopy(store.getState().portalServ.session.viewOnly) : false;
}
const __lookupDataOwner = (store) => {
  return deepCopy(store.getState().portalServ.session.dataOwner);
}

/* ***     *** */
const __lookupLoginUser = (store) => {
  return  deepCopy(store.getState().portal.session.loginUser);};
const __verifyLogin= (session) => {
  return((isNotEmpty(session) && isNotEmpty(session.loginUser) && 
          isNotEmpty(session.loginUser.id) && (session.loginUser.id!=-999))?true:false);}

/* ************************ */
/* *** Session Commands *** */
export const RefreshSessionPortalCommand = {
  key:'portal-refresh-session',
  controllerValidationKey : 'portal-app',

  executeCommand : async function(payload,store,controls) {
    const forceLoad = (isNotEmpty(payload)&&isNotEmpty(payload.forceLoad))?payload.forceLoad : false;
    if ((forceLoad == false) && __verifySession(store)) {
      return __lookupSession(store);
    } else {
      var session:any = await PortalServiceController.executeCommand(FetchSessionPortalServCommand);
      console.log("session loginUser id:"+ session.loginUser.id +":");
      session.isLoaded= true;
      store.dispatch({type:"portal/session/set",payload:session});
    }
    return session;
  }
}

/* ******************(*** */
/* *** Login Commands *** */
export const LoginPortalCommand = {
  key:'portal-login',
  controllerValidationKey : 'portal-app',

  executeCommand : async function(payload,store,controls) {
    const loginUser = await PortalServiceController.executeCommand(LoginPortalServCommand,{email:payload.email,password:payload.password});
    const session   = await PortalAppController.executeCommand(RefreshSessionPortalCommand, {forceLoad:true});
    PortalCacheExplorer.prepare(store);
    var isLoggedIn  = await PortalAppController.executeCommand(VerifyLoginUserPortalCommand);
    if (isLoggedIn) {return true;
    } else {return false;};
  },
};

export const LoginSkipPassPortalCommand = {
  key:'portal-login',
  controllerValidationKey : 'portal-app',

  executeCommand : async function(payload,store,controls) {
    const loginUser = await PortalServiceController.executeCommand(LoginSkipPassPortalServCommand,{email:payload.email});
    const session   = await PortalAppController.executeCommand(RefreshSessionPortalCommand, {forceLoad:true});
    PortalCacheExplorer.prepare(store);
    var isLoggedIn  = await PortalAppController.executeCommand(VerifyLoginUserPortalCommand);
    if (isLoggedIn) {return true;
    } else {return false;};
  },
};

/* ******************(*** */
/* *** Logout Commands *** */
export const LogoutPortalCommand = {
  key:'portal-login',
  controllerValidationKey : 'portal-app',

  executeCommand : async function(payload,store,controls) {
    const logoutUser = await PortalServiceController.executeCommand(LogoutPortalServCommand);
    //Manual navigation to effectively clear the redux cache and start fresh.
    window.location.href="/taap-ui/";
  },
};


export const FetchLoginUserPortalCommand = {
  key:'portal-fetch-login-user',
  controllerValidationKey : 'portal-app',

  executeCommand : async function(payload,store,controls) {
    return __lookupLoginUser(store);
  }
}

export const VerifyLoginUserPortalCommand = {
  key:'portal-verify-login-user',
  controllerValidationKey : 'portal-app',

  executeCommand : async function(payload,store,controls) {
    return __verifyLogin(__lookupSession(store));
  }
}

/* *********************** */
/* *** Action Commands *** */
export const InvokeControllerPortalCommand = {
  key:'portal-invoke-controller',
  controllerValidationKey : 'portal-app',

  executeCommand : async function(payload,store,controls) {
    return await payload.controller.executeCommand(payload.commandPayload,store,controls);
  }
}

export const FetchAllUsersPortalCommand = {
  key:'portal-fetch-all-users',
  controllerValidationKey : 'portal-app',

  executeCommand : async function(payload,store,controls) {
    return await PortalServiceController.executeCommand(FetchAllUsersServCommand, payload);
  },
};

export const FetchFilteredUsersPortalCommand = {
  key:'portal-fetch-all-users',
  controllerValidationKey : 'portal-app',

  executeCommand : async function(payload,store,controls) {
    return await PortalServiceController.executeCommand(FetchFilteredUsersServCommand, payload);
  },
};

/* ***************  ****************  ******************** */
// ViewOnly is used across all of the portal to tell if a user has access to change values or not
export const UpdateViewOnlyPortalCommand = {
  key: 'portal-update-view-only',
  controllerValidationKey: 'portal-app',

  executeCommand: async function (payloadParam, store, controls) {
    if (isNotEmpty(payloadParam)) {
      store.dispatch({ type: 'portal-serv/session/view-only/set', payload: payloadParam });
    };
    return __lookupViewOnly(store);
  },
};

export const FetchViewOnlyPortalCommand = {
  key: 'portal-fetch-view-only',
  controllerValidationKey: 'portal-app',

  executeCommand: function (payloadParam, store, controls) {
    return __lookupViewOnly(store);
  },
};

/* ***************  ****************  ******************** */
export const SetDataOwnerPortalCommand = {
  key: 'portal-set-data-owner',
  controllerValidationKey: 'portal-app',

  executeCommand: async function (payloadParam, store, controls) {
    if (isNotEmpty(payloadParam)) {
      store.dispatch({ type: 'portal-serv/session/data-owner/set', payload: payloadParam });
    };
    return __lookupDataOwner(store);
  },
};

export const ResetDataOwnerPortalCommand = {
  key: 'portal-reset-data-owner',
  controllerValidationKey: 'portal-app',

  executeCommand: async function (payloadParam, store, controls) {
    let loginUser = __lookupLoginUser(store);
    if (isNotEmpty(loginUser)) {
      store.dispatch({ type: 'portal-serv/session/data-owner/set', payload: loginUser });
    };
    return __lookupDataOwner(store);
  },
};

export const CloseViewAsPortalCommand = {
  key: 'portal-close-view-as',
  controllerValidationKey: 'portal-app',

  executeCommand: async function (payloadParam, store, controls) {
    PortalAppController.executeCommand(UpdateViewOnlyPortalCommand, false);
    PortalAppController.executeCommand(ResetDataOwnerPortalCommand);
    controls.navigate("/pc/account");
    return true;
  }
}
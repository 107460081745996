import './composite-footer.scss';
import React, { useRef, useState } from 'react';

import * as ImgConst from 'ui/modules/imageConstants';

import { Footer, FooterNav, Grid, GridContainer, Logo, SocialLink, SocialLinks} from '@trussworks/react-uswds';
import IdentifierFooter from './identifier-footer';
import { SocialLinks508 } from './SocialLinks508';
import { FooterNav508 } from './FooterNav508';

export const PortalCompositeFooter = () => {



  const returnToTop = (<GridContainer className="usa-footer__return-to-top">{/*<a href="#">Return to top</a>*/}</GridContainer>)


  const socialLinkItems = [
    <SocialLink key="facebook" name="Facebook" href="https://www.facebook.com/USIndianAffairs/" />,
    <SocialLink key="twitter" name="Twitter" href="https://twitter.com/USIndianAffairs" />,
    <SocialLink key="youtube" name="YouTube" href="https://www.youtube.com/@USIndianAffairs/videos" />,
    <SocialLink key="flicker" name="Instagram" href="https://www.flickr.com/people/62695826@N03/" />,
  ]

  const biaFooterLinkItems = [
    <div>About Us</div>,
    <div>Program and Services</div>,
    <div>Guidance</div>,
    <div>Contact Us</div>,
  ]

  const aboutUsLinks = ['About Us', <a key="2" href="https://www.bia.gov/as-ia">AS-IA</a>,
                                    <a key="3" href="https://www.bia.gov/bia">BIA</a>,
                                    <a key="4" href="https://www.bia.gov/bie">BIE</a>,
                                    <a key="5" href="https://www.bia.gov/btfa">BTFA</a>,
                                    <a key="6" href="https://www.bia.gov/news">News</a>,]

                                    
  const programServicesLinks = ['Program Services', <a key="2" href="https://www.bia.gov/bia/ojs">Law Services</a>,
                                    <a key="3" href="https://www.bia.gov/programs-services/social-services">Social Services</a>,
                                    <a key="4" href="https://www.bia.gov/bia/ois/tgs">Tribal Government</a>,]


  const guidanceLinks = ['Guidance', <a key="2" href="https://www.bia.gov/bia/frequently-asked-questions">FAQ</a>,
                                     <a key="3" href="https://www.bia.gov/as-ia/ofa">Federal Acknowledgement</a>,
                                     <a key="4" href="https://www.bia.gov/bia/ois/dhs/icwa">Indian Child Welfare Act</a>,
                                     <a key="5" href="https://www.bia.gov/service/american-rescue-plan-act">American Rescue Plan Act</a>,
                                     <a key="6" href="https://www.bia.gov/page/indian-affairs-web-standards">Web Standards</a>,]

                                     
  const contactUsLinks = ['Contact Us', <a key="2" href="https://www.doi.gov/">DOI.gov</a>,
                                     <a key="3" href="https://www.bia.gov/as-ia/raca/regulations">Regulations</a>,
                                     <a key="4" href="https://www.usajobs.gov/">USAJobs.gov</a>,]

  const footerPrimary = ( 
    <GridContainer className='primaryFooterContainer' >
      <Grid row>
        <Grid col={9}>
          <FooterNav508 aria-label="Bureau of Indian Affairs links" size="big" links={[aboutUsLinks,programServicesLinks,guidanceLinks,contactUsLinks,]} />
        </Grid>
        <Grid col={3}/>
      </Grid>
    </GridContainer>
  )
  const footerSecondary = (
    <>
      <Grid row gap>
        <Logo size="medium" image={<img className="usa-footer__logo-img" src={ImgConst.biaFooterLogo} alt="U.S. Department of the Interior. Bureau of Indian Affairs. 1824" />}
          heading={<p className="usa-footer__logo-heading">Indian Affairs</p>}/>
        <Grid className="usa-footer__contact-links" mobileLg={{ col: 6 }}>
          <SocialLinks508 links={socialLinkItems} />
          <span className="usa-footer__contact-heading">1849 C Street NW<br/>Washington, DC 20240</span>
        </Grid>
      </Grid>
    </>
  )

  return (
    <>
      <Footer returnToTop={returnToTop} primary={footerPrimary} secondary={footerSecondary} />
      <IdentifierFooter />
    </>
  );
};


export default PortalCompositeFooter;
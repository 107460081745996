import { ENV_PROD } from 'ui/config/constants';
import { useAppSelector } from 'ui/config/store';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PortalPreProdWall from '../portal/sections/login/portalPreProdWall';
import './app-login.scss';


export const LandingPageSelector = () => {

  const taapenv = useAppSelector(state => state.authentication.taapenv);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAllowedPastWall = useAppSelector(state => state.authentication.isAllowedPastWall);
  const location = useLocation();
  const homePath = '/pc/home'
  

  if (ENV_PROD !== taapenv || isAllowedPastWall || window.location.href.includes('taap-ui-mortgage')) {
    return (<Navigate
      to={{
        pathname: homePath,
        search: location.search,
      }}
      replace={false}
      state={{ from: location }}
    />
    );
  }

  return  (
    <><PortalPreProdWall/></>
  );
};


export default LandingPageSelector;

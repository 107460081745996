
import React, { useState } from 'react';

import * as ImgConst from 'ui/modules/imageConstants';
import { ButtonGroup,Modal,ModalHeading,ModalFooter,ModalToggleButton,} from '@trussworks/react-uswds';

import '../../common/portal-standards.scss'

export const PortalErrorContent = (myProps) => {

  /******************************************************************** */
  let [modalKey,setModalKey] = useState(1);
  const refreshModal = () => {
    const newKey = modalKey + 1;setModalKey(newKey);return;}; 
  /******************************************************************** */

  myProps.contentRefreshLoader.refreshErrorView = refreshModal;
  /******************************************************************** */

  const handleClick = (ev) => {};

  return (<div key={'PEC'+modalKey}>
      <ModalHeading id="portal-error-modal-heading">Login Failed</ModalHeading>
      <div className="usa-prose">The email and password combination is incorrect.</div>
      <ModalFooter> <ButtonGroup>
          <ModalToggleButton id='portal-error-close-button' modalRef={myProps.registerRef} closer className="padding-105 text-center">
            Close </ModalToggleButton></ButtonGroup>
      </ModalFooter></div>);};

export default PortalErrorContent;
import getStore from "ui/config/portal/portal-store";
import { DebugPortalServCommand } from "../mock/commands/debug-commands";
import { mergeObjects } from 'ui/modules/common/logic/utilities/array-utilities';



class PortalServiceControllerDef {
  protected controls = {};

  public registerControls(controlsParam) {
    this.controls = mergeObjects(this.controls,controlsParam,);};
  

  public async executeCommand(command,payload=undefined) {
    /** Testing screen out**/
    // console.log("PortalServiceController.executeCommand  command:"+command.key+":");

    this.__validateCommand(command);
    var returnVal = await this.__internalExecuteCommand(command,payload);
    //this.__internalExecuteCommand(DebugPortalServCommand)
    return returnVal;
  }

  protected async __internalExecuteCommand(command,payload=undefined) {
    this.__validateCommand(command);

    /** Setup including command fetch**/
    var returnVal = undefined;
 
    /** */
    try {
      returnVal = await command.executeCommand(payload,getStore(),this.controls);
    } catch (e) {
      console.log("PortalServiceController.executeCommand FAILED for command:"+command.key+":");
      console.log("command error message:"+e.message+"::stacktrace:"+e.stack+":");
      throw e;
    }
 
    /** */
    return returnVal;
  };


  protected __validateCommand (command) {
    if (command.controllerValidationKey != 'portal-service') {
      throw (new Error("PortalServiceController cannot run :"+command.controllerValidationKey+": commmands.\n" +
      "----Update the controllerValidateKey to 'portal-sevice for :"+command.key+":"));};
  }

}

export const PortalServiceController = new PortalServiceControllerDef();


 
import React, { useState } from 'react';
import { RouteProps, useLocation, Navigate, useNavigate } from 'react-router-dom';

import ErrorBoundary from '../../error/error-boundary';
import { PortalAppController } from 'ui/modules/portal/logic/core/controllers';
import { FetchQueryParamPortalCommand, RegisterPagePortalCommand } from 'ui/modules/portal/logic/core/commands/page-commands';
import { VerifyLoginUserPortalCommand } from 'ui/modules/portal/logic/core/commands/core-commands';
import { LaunchLoginPortalCommand } from 'ui/modules/portal/logic/core/commands/navigate-commands';
import { prepareControllers } from '../../logic/utilities/controller-utilities';
import { isNotEmpty } from '../../logic/utilities/utilities';
import { useAppSelector } from 'ui/config/store';
import { ENV_PROD } from 'ui/config/constants';

type IOwnProps = RouteProps & {
  hasAnyAuthorities?: string[];
  children: React.ReactNode;
  authPage?: boolean;
  unprotected?: boolean;
}

export const PortalAuthWrapper = ({ children, hasAnyAuthorities = [], authPage = false, unprotected = false, ...rest }: IOwnProps) => {

  const authenticationInfo = useAppSelector(state => state.authentication);
  const [navParams, setNavParams]: any = useState({});
  const [loadIsStarted, setLoadIsStarted] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [goToNavParams, setGoToNavParams] = useState(false);
  const [goToDashBoardSummary, setGoToDasboardSummary] = useState(false);
  const [goToHome, setGoToHome] = useState(false);
  const [goToRoot, setGoToRoot] = useState(false);
  const location = useLocation();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAllowedPastWall = useAppSelector(state => state.authentication.isAllowedPastWall);
  const taapenv = useAppSelector(state => state.authentication.taapenv);

  prepareControllers({ navigate: useNavigate(), location: useLocation(), authenticationData: authenticationInfo });

  PortalAppController.executeCommand(RegisterPagePortalCommand, { center: 'PORTAL' }).then(async () => {
    if (loadIsStarted == false) {
      setLoadIsStarted(true);
      var isLoggedIn = false;
      if (authPage == false && unprotected == false) {
        isLoggedIn = await PortalAppController.executeCommand(VerifyLoginUserPortalCommand);
        if (!isLoggedIn) {
          setNavParams(await PortalAppController.executeCommand(LaunchLoginPortalCommand, { returnRenderParams: true }));
          setGoToNavParams(true);
          return;
        };
      }
      else if (!authPage && unprotected) {
        if (ENV_PROD === taapenv && !window.location.href.includes('taap-ui-mortgage')) {
          if ((!isAllowedPastWall)) {
            setGoToRoot(true);
          }
          else if (isAuthenticated && isAllowedPastWall && location.search.indexOf('continueToFTTDashboard') > -1) {
            setGoToDasboardSummary(true);
            return;
          }
        }
      }
      else if (authPage == true) {
        isLoggedIn = await PortalAppController.executeCommand(VerifyLoginUserPortalCommand);
        if (isLoggedIn) {
          let forwardVal = await PortalAppController.executeCommand(FetchQueryParamPortalCommand, 'forward');
          if (isNotEmpty(forwardVal) && forwardVal == 'FTT') {
            setGoToDasboardSummary(true); return;
          }
          else { setGoToHome(true); return; };
        };
      }
      setIsLoaded(true);
    };
  });

  if (goToNavParams) {
    return (<Navigate to={{ pathname: navParams.path, search: navParams.searchParams, }} />);
  };
  if (goToDashBoardSummary) {
    return (<Navigate to={{ pathname: '/ftt/dashboard-summary', search: 'category=all', }} />);
  };
  if (goToHome) {
    return (<Navigate to={{ pathname: '/pc/home', search: '', }} />);
  };
  if (goToRoot) {
    return (<Navigate to={{ pathname: '/', search: '', }} />);
  };

  return (<ErrorBoundary>{children}</ErrorBoundary>);
}; export default PortalAuthWrapper;


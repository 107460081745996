import { deepCopy, isNotEmpty } from "ui/modules/common/logic/utilities/utilities";

import { FetchQueryStringPortalCommand, RegisterPagePortalCommand } from "./page-commands";
import { PortalSettings } from "../sestings";
import { PortalAppController } from "../controllers";
import { ENV_PROD } from "ui/config/constants";
import { redirectToLoginGov } from "ui/modules/common/authorization-service";

/********************************************/
const __lookupNavCache = (store) => {
  return deepCopy(store.getState().moduleCache.navCache)};


/********************************************/
const __verifyPagePath = (navCache) => {
  if (isNotEmpty(navCache) && isNotEmpty(navCache.pageInfo) &&
      isNotEmpty(navCache.pageInfo.path)) {return true;} return false;};
const __verifyPageCenter = (navCache) => {
  if (isNotEmpty(navCache) && isNotEmpty(navCache.pageInfo) &&
      isNotEmpty(navCache.pageInfo.center)) {return true;} return false;};
      
/********************************************/
export const NavigatePortalCommand = {
  key : 'portal-launch-login-gov',
  controllerValidationKey : 'portal-app',
      
  executeCommand : async function (payload,store,controls) {
    controls.navigate(payload);},};
      

/********************************************/
export const LaunchLoginGovPortalCommand = {
  key : 'portal-launch-login-gov',
  controllerValidationKey : 'portal-app',

  executeCommand : async function (payload,store,controls) {
    if(ENV_PROD === controls?.authenticationData?.taapenv){
      redirectToLoginGov();
    }
    else {
      var queryString = await PortalAppController.executeCommand(FetchQueryStringPortalCommand);
      controls.navigate("/pc/login-dev?"+queryString);
    } 
  },
};

/********************************************/
export const LaunchLoginGovRegisterPortalCommand  = {
  key : 'portal-launch-login-gov-registration',
  controllerValidationKey : 'portal-app',

  executeCommand : async function (payload,store,controls) {
    if(ENV_PROD === controls?.authenticationData?.taapenv){
      redirectToLoginGov();
    }
    else {
      var queryString = await PortalAppController.executeCommand(FetchQueryStringPortalCommand);
      controls.navigate("/pc/login-dev?"+queryString);
      return;
    }
  },
};


/********************************************/
export const LaunchLoginPortalCommand  = {
  key : 'portal-launch-login',
  controllerValidationKey : 'portal-app',

  transformPayload : async function(payload,store,controls) {
    const returnObj = {};
    if (payload!=undefined && payload.targetCenter!= undefined 
       && payload.targetCenter !='') {
      return {forwardCenter:payload.targetCenter};};

    const navCacheParam = __lookupNavCache(store);
    if (__verifyPagePath(navCacheParam)) {
      return {returnPath:navCacheParam.pageInfo.path};
    };

    if (__verifyPageCenter(navCacheParam)) {
      return {returnCenter:navCacheParam.pageInfo.path};
    };
    return {};
  },

  executeCommand : async function (payload,store,controls) {
    const navParam = await this.registerStatePrepareNavigation(payload,store,controls);
    
    if (payload!=undefined && payload.returnRenderParams==true) {
      return navParam;
    } else {
      controls.navigate(navParam.path+'?'+navParam.searchParams);
    };},

  registerStatePrepareNavigation : async function (payload,store,controls) {
    var newPayload = await this.transformPayload(payload,store,controls);

    if (newPayload != undefined) {
      var forwardCenterParam = '';
      if (newPayload.forwardCenter != undefined) {
        forwardCenterParam = newPayload.forwardCenter;}

      switch (forwardCenterParam) {
        case 'FTT' :
          return {path:'/pc/login' ,searchParams:'forward=FTT'};
        case 'REG' :
          return {path:'/pc/login' ,searchParams:'forward=HOME'};
        case 'MC' :
          return {path:'/pc/login' ,searchParams:'forward=MC'};};

      var returnPathParam = newPayload.returnPath;
      var returnCenterParam = newPayload.returnCenter;
      if (returnPathParam != undefined && returnPathParam!='') {
        store.dispatch({type:'module-cache/nav-cache/login-success-nav/set',payload:{returnPath:returnPathParam}});
      } else if (returnCenterParam != undefined && returnCenterParam!='') {
        store.dispatch({type:'module-cache/nav-cache/login-success-nav/set',payload:{forwardCenter:returnPathParam}});
      }
      return {path:'/pc/login',searchParams:''};
    }
    return ;
  },};

/********************************************/
export const LaunchCenterPortalCommand = {
  key:"portal-launch-center",
  controllerValidationKey : 'portal-app',

  executeCommand : async function(payload,store,controls) {
    var navigateUrl = '.';

    switch (payload.targetCenter) {
      case 'FTT' :
        // await FTTProtectedController.executeCommand(LaunchDashboardFTTCommand);
        window.location.href = '/taap-ui-ftt/ftt/dashboard-summary';
        break;
      case 'MRTG' :
        // await FTTProtectedController.executeCommand(LaunchDashboardFTTCommand);
        window.location.href = '/taap-ui-mortgage/mrtg-center/dashboard-home';
        break;
      case 'REG' :
        await PortalAppController.executeCommand(LaunchLoginPortalCommand);
        break;
      case 'MC' :
        navigateUrl = '/mc/message-center';
        controls.navigate(navigateUrl);
        break;
    }    
    return undefined;
  },};

/********************************************/
export const LaunchRegisterPortalCommand = {
  key:"portal-launch-register",
  controllerValidationKey : 'portal-protected',

  executeCommand : async function(payload,store,controls) {
    await LaunchCenterPortalCommand.executeCommand(payload,store,controls)
    return undefined;},};

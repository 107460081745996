// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../content/images/IMG_3754_resized.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".home-header {\n  height: 10rem;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;\n  background-size: cover;\n}\n\n.home-header-links {\n  background-color: rgba(0, 0, 0, 0.8);\n  width: 100%;\n}\n\n.home-header-link {\n  padding: 2rem;\n  display: inline;\n}\n\n.darkRed {\n  color: rgb(153, 0, 0);\n}\n\n.purple {\n  color: rgb(153, 51, 255);\n}", "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/home/home.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,oEAAA;EACA,sBAAA;AACJ;;AACA;EACI,oCAAA;EACA,WAAA;AAEJ;;AACA;EACI,aAAA;EACA,eAAA;AAEJ;;AAAA;EACI,qBAAA;AAGJ;;AADA;EACI,wBAAA;AAIJ","sourcesContent":[".home-header{\n    height:10rem;\n    background: url('../../../content/images/IMG_3754_resized.jpg') no-repeat center;\n    background-size: cover;\n}\n.home-header-links{\n    background-color: rgba(0,0,0,.8);\n    width:100%;\n}\n\n.home-header-link{\n    padding: 2rem;\n    display: inline;\n}\n.darkRed{\n    color: rgba(153,0,0,1.0);\n}\n.purple{\n    color: rgba(153,51,255,1.0);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

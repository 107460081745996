import getStore from 'ui/config/portal/portal-store';
import { clearAuthentication, onResponseSuccess } from 'ui/modules/common/reducers/authentication';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import AppRouter from './app-router';
import { setupAxiosInterceptors } from './modules/common/axios';

const rootEl = document.getElementById('react');
const root = createRoot(rootEl);

// oauth-authorization-server (keycloak) authorization
// authChecks();

const store = getStore();

const actions = bindActionCreators({ clearAuthentication,  onResponseSuccess}, store.dispatch);
setupAxiosInterceptors(() => actions.onResponseSuccess());


const render = Component =>
  root.render(
    <Provider store={store}>
      <Component />
    </Provider>
  );

render(AppRouter);
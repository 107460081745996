import React from 'react';
import { useNavigate } from "react-router-dom";
import { mockButtonsClickDispatcher } from "../logic/mock/mock-buttons";
import { Button } from "@trussworks/react-uswds";
import { useAppSelector } from 'ui/config/store';
import { API_DATA_SOURCE, ENV_LOCAL, ENV_QA10, ENV_QA9, MOCK_DATA_SOURCE } from 'ui/config/constants';
import { lookupDataSourceType } from '../logic/utilities/environment-utilities';

const MockButtonRow = () => {
  const taapenv = useAppSelector(state => state.authentication.taapenv);
      
  /* *** Navigation setup and logic *** */
  const displayDataSource = () => {
    let ds = lookupDataSourceType(taapenv);
    if (ds == MOCK_DATA_SOURCE) {
      return(<><b>Data Source:</b> REACT (In Memory Refreshable Data)</>);};
    if (ds == API_DATA_SOURCE) {
      return(<><b>Data Source:</b> MYSQL (Persistent Datbase Supported Data)</>);};
    return(<></>);};

  /* *** Navigation setup and logic *** */
  const displayMockServiceButtons = () => {
    let ds = lookupDataSourceType(taapenv);
    if (ds == MOCK_DATA_SOURCE) {
      return (
        <>
              <Button className="testing-portal-mock-button" id="mock-offload-button" type="button" onClick={mockButtonsClickDispatcher}>Offload Session</Button>
              <Button className="testing-portal-mock-button" id="mock-login-reg-empty-button" type="button" onClick={mockButtonsClickDispatcher}>Login-Registered-Empty-Applist</Button>
              <Button className="testing-portal-mock-button" id="mock-login-reg-single-button" type="button" onClick={mockButtonsClickDispatcher}>Login-Registered-Single-Applist</Button>
              <Button className="testing-portal-mock-button" id="mock-login-reg-multiple-button" type="button" onClick={mockButtonsClickDispatcher}>Login-Registered-Full-Apps</Button>
              <Button className="testing-portal-mock-button" id="mock-login-unregistered-button" type="button" onClick={mockButtonsClickDispatcher}>Login Unregistered</Button>
        </>
      )
    } else {return(<></>);};};


  /* *** Navigation setup and logic *** */
  const displayLaunchButtons = () => {
    let ds = lookupDataSourceType(taapenv);
    if (ds == MOCK_DATA_SOURCE) {
      return (
        <>
          <Button className="testing-portal-mock-button" id="mock-logout-button" type="button" onClick={mockButtonsClickDispatcher}>Logout</Button> | 
          <Button className="testing-nav-mock-button" id="mock-launch-home-button" type="button" onClick={mockButtonsClickDispatcher}>Home</Button>
          <Button className="testing-nav-mock-button" id="mock-launch-message-page-button" type="button" onClick={mockButtonsClickDispatcher}>Messages</Button>
          <Button className="testing-nav-mock-button" id="mock-launch-action-page-button" type="button" onClick={mockButtonsClickDispatcher}>Actions</Button>
          <Button className="testing-nav-mock-button" id="mock-launch-processing-page-button" type="button" onClick={mockButtonsClickDispatcher}>Processing</Button>
        </>
      )
    } else {
      return(
        <>
          {/* Before restoring the logout button ensure that it's actually cleaning up the entire session - it doesn't seem to be at the moment */}
          {/* <Button className="testing-portal-mock-button" id="mock-logout-button" type="button" onClick={mockButtonsClickDispatcher}>Logout</Button> |  */}
          <Button className="testing-nav-mock-button" id="mock-launch-home-button" type="button" onClick={mockButtonsClickDispatcher}>Home</Button>
        </>
      );};};

    




  /* ***************************
  Initialize 
  **************************** */
  
  /* *** component return *** */
  return (
    <>
      <div className="testing-mock-button-row">
          {displayMockServiceButtons()}
          {displayLaunchButtons()}
          {displayDataSource()}
      </div>
    </>
  )
}
export default MockButtonRow;
/*  eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import classnames from 'classnames'
import './SocialLinks508.scss'

// Copied and modified for 508 compliance from https://github.com/trussworks/react-uswds/blob/3230085eda0423fc90dcf7c18965cb36783ef198/src/components/Footer/SocialLinks/SocialLinks.tsx
type SocialLinksProps = {
    links: React.ReactNode[]
}

export const SocialLinks508 = ({
    className,
    links,
}: SocialLinksProps & JSX.IntrinsicElements['div']): React.ReactElement => {
    const classes = classnames(
        'usa-footer__social-links grid-row grid-gap-1',
        className
    )
    return (
        <div className={classes}>
            {/* 508 changes involve putting the links into an unordered list */}
            <ul className='social-links-508'>
                {links.map((link, i) => (
                    <li key={`socialLink${i}`}>
                        <div className="grid-col-auto">
                            {link}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}
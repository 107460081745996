
import { Grid, GridContainer } from '@trussworks/react-uswds';
import React, { useState } from 'react';

import '../../../../common/portal-standards.scss'
import * as ImgConst from 'ui/modules/imageConstants';

import './portalHomeHeader.scss'

export const PortalHomeHeader = () => {
  return (
    <>
        <section className="portal-header-focus__banner" aria-label="Introduction">
          <GridContainer className="portal-empty-shell"><Grid row>
            <Grid col={4}>
            <div className="portal-header-focus__callout">
              <h1 className="portal-header-focus__heading">
                All New: <span className="portal-header-focus__heading--alt"> Trust Asset Assistance Portal</span>
              </h1>
              <p className="portal-header-focus__description">Welcome to the new digital center built to save you time and simplify your effort.</p>
            </div>
            </Grid><Grid col={8}>
            </Grid>
          </Grid></GridContainer>
        </section>
    </>
)}

export default PortalHomeHeader;

import React, { useState } from 'react';

import * as ImgConst from 'ui/modules/imageConstants';
import { ButtonGroup,ModalHeading,ModalFooter,ModalToggleButton, Button,} from '@trussworks/react-uswds';

import '../../common/portal-standards.scss'
import { FetchQueryParamPortalCommand, FetchSuccessNavPortalCommand, RegisterPagePortalCommand } from '../logic/core/commands/page-commands';
import { PortalAppController } from '../logic/core/controllers';
import { isNotEmpty } from 'ui/modules/common/logic/utilities/utilities';
import { NavigatePortalCommand } from '../logic/core/commands/navigate-commands';

export const PortalSuccessContent= (myProps) => {

  let [modalKey,setModalKey] = useState(1);
  /******************************************************************** */
  const refreshModal = () => {
    const newKey = modalKey + 1;setModalKey(newKey);return;}; 
 
  myProps.contentRefreshLoader.refreshSuccessView = refreshModal;
  /******************************************************************** */
  const handleClick = async (ev)=>{
    await PortalAppController.executeCommand(RegisterPagePortalCommand,{center:'portal'});
    var forwardParam:any = await PortalAppController.executeCommand(FetchQueryParamPortalCommand,'forward');
    var forwardNavObj:any = await PortalAppController.executeCommand(FetchSuccessNavPortalCommand);
  
    var forwardCenter:any = '';
    var forwardPath:any = '';
    if (isNotEmpty(forwardParam)) {
      forwardCenter = forwardParam;
    } else if (forwardNavObj != undefined) {
      if (forwardNavObj.returnPath == undefined && forwardNavObj.returnCenter != undefined) {
        forwardCenter = forwardNavObj.returnCenter;
      } else if (forwardNavObj.returnPath!=undefined) {
        forwardPath = forwardNavObj.returnPath;
      }
    }

    /************************************** Use forward center ***********************/
    switch(forwardCenter) {
      case 'HOME' : 
        forwardPath = '/pc/home';
    };

    /************************************** Use forward center ***********************/
    //refresh center session
    if (isNotEmpty(forwardPath) && forwardPath.includes('FTT')) {
      // await FTTAppController.executeCommand(RefreshSessionFTTCommand,{forceLoad:true});
    }
    //launch foward path
    await PortalAppController.executeCommand(NavigatePortalCommand,forwardPath);
  };


  return (<div key={'PSC-'+modalKey}>
      <ModalHeading id="portal-suc-modal-heading">Login Successful</ModalHeading>
      <div className="usa-prose">You have gained access to the Trust Asset Assistance Portal.  From here you can visit any of the provided focus centers.  Note: Each center will require registration and approval to gain access.</div>
      <ModalFooter> <ButtonGroup>
          <Button type='button' id='portal-suc-save-button' onClick={handleClick}>
             Close </Button></ButtonGroup>
      </ModalFooter></div>);};

export default PortalSuccessContent;
// contentRefreshLoader={myProps.contentRefreshLoader} registerRef={myProps.registerRef} 
import getStore from "ui/config/portal/portal-store";
import { DebugPortalCommand } from "./commands/debug-commands";
import { RefreshSessionPortalCommand, VerifyLoginUserPortalCommand } from "./commands/core-commands";
import { LaunchLoginPortalCommand } from "./commands/navigate-commands";
import { mergeObjects } from "ui/modules/common/logic/utilities/array-utilities";

export class PortalAppControllerDef {
  
  protected controls = {};

  public registerControls(controlsParam) {
    this.controls = mergeObjects(this.controls,controlsParam,);};
  
    public async executeCommand (command,payload=undefined) {
    /** Testing screen out**/
    // console.log("PortalController.executeCommand  command:"+command.key+"::payload:"+payload+":");
    this.__validatePortalAppCommands(command);
    if (command.key!=RefreshSessionPortalCommand.key) {await this.refreshSession();};
    var returnVal = await this.__internalExecuteCommand(command,payload);
    //this.__internalExecuteCommand(DebugPortalCommand,payload);
    return returnVal;
  };

  protected async refreshSession() {
    // console.log("PortalController.executeCommand  call:"+RefreshSessionPortalCommand.key+":");
    const session = await this.__internalExecuteCommand(RefreshSessionPortalCommand);
    //this.__internalExecuteCommand(DebugPortalCommand);
  };

  protected async isLoggedIn() {
    // console.log("PortalController.executeCommand  call:"+VerifyLoginUserPortalCommand.key+":");
    let isLoggedIn = await this.__internalExecuteCommand(VerifyLoginUserPortalCommand);
    return isLoggedIn;
  };

  protected async __internalExecuteCommand(command,payload=undefined) {
    this.__validatePortalCommands(command);
    
    /** Setup*/
    var returnVal = undefined;

    /** */
    try {
      returnVal = await command.executeCommand(payload,getStore(),this.controls);
    } catch (e) {
      console.log("PortalAppController.executeCommand FAILED for command:"+command.key+"::payload:"+payload+":");
      console.log("command error message:"+e.message+"::stacktrace:"+e.stack+":");
      throw e;
    }
 
    /** */
    return returnVal;
  };



  protected __validatePortalAppCommands(command) {
    if (command.controllerValidationKey != 'portal-app') {
      throw (new Error('PortalAppController cannot run :'+command.controllerValidationKey+": commmands.\n" +
      "----Update the controllerValidateKey to 'portal-app' for :"+command.key+":"));};
  }
  protected __validatePortalCommands(command) {
    if (command.controllerValidationKey != 'portal-app' &&
        command.controllerValidationKey != 'portal-protected') {
        throw (new Error('Portal Controller--Internal Error - Cannot run :'+command.controllerValidationKey+": commmands.\n" ));};
  }

};

export class PortalProtectedControllerDef extends PortalAppControllerDef {
  public async executeCommand(command,payload=undefined) {
    /** Testing screen out**/
    // console.log("PortalProtectedController.executeCommand  command:"+command.key+":");

    this.__validatePortalAuthWrapperCommands(command);
    this.refreshSession();
    if (await this.isLoggedIn()) {
      var returnVal = await this.__internalExecuteCommand(command,payload);
      //this.__internalExecuteCommand(DebugPortalCommand,payload);
      return returnVal;
    }
    console.log("-- Detected user is not logged in   skip command:"+command.key+":   run command:"+LaunchLoginPortalCommand.key+":");
    await this.__internalExecuteCommand(LaunchLoginPortalCommand,payload);
  }


  protected __validatePortalAuthWrapperCommands(command) {
    if (command.controllerValidationKey != 'portal-protected') {
      throw (new Error('PortalProtectedController cannot run :'+command.controllerValidationKey+": commmands.\n" +
                              "----Update the controllerValidateKey to 'portal-protected'"));};
  }

};

export const PortalAppController = new PortalAppControllerDef();
export const PortalProtectedController = new PortalProtectedControllerDef();
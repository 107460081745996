import { MockFetchAllUsersServCommand, MockFetchFilteredUsersServCommand, MockFetchSessionPortalServCommand, MockLoginPortalServCommand, MockLogoutPortalServCommand, MockOffloadSessionPortalServCommand, MockRegisteredSessionPortalServCommand, MockUnregisteredSessionPortalServCommand } from "../../mock/commands/session-commands";
import { ApiFetchAllUsersServCommand, ApiFetchFilteredUsersServCommand, ApiFetchSessionPortalServCommand, ApiLoginPortalServCommand, ApiLoginSkipPassPortalServCommand, ApiLogoutPortalServCommand } from "../../api/commands/session-commands";
import { lookupDataSourceType } from "../../../../common/logic/utilities/environment-utilities";

import { PortalAppController } from "../../core/controllers";
import { LogoutPortalCommand } from "../../core/commands/core-commands";
import { API_DATA_SOURCE, MOCK_DATA_SOURCE } from "ui/config/constants";
import { deepCopy, isNotEmpty } from "ui/modules/common/logic/utilities/utilities";


/** *******************************************************
 Helper Functions
******************************************************* **/
const __fetchDataSourceType = (env) => {
  return lookupDataSourceType(env);
}

// DataOwner is used to reference an account when users are "Viewing As" another account
const __lookupLoginUserPortalServ = (store) => {
  if (isNotEmpty(store.getState().portalServ.session.dataOwner)) {
    return deepCopy(store.getState().portalServ.session.dataOwner);
  } else {
    return deepCopy(store.getState().portalServ.session.loginUser);
  }
}

const __lookupLoginUserPortal = (store) => {
  return deepCopy(store.getState().portal.session.loginUser);
}

/** *******************************************************
 Shared Commands (Used by API and MOCK)
******************************************************* **/
export const FetchLoginUserPortalServCommand = {
  key:'portal-serv-fetch-login-user',
  controllerValidationKey : 'portal-service',

  executeCommand : async function(payload,store,controls) {
    return __lookupLoginUserPortalServ(store);
  },
};

export const FetchLoginUserPortalHomeCommand = {
  key:'portal-serv-fetch-login-user',
  controllerValidationKey : 'portal-service',

  executeCommand : async function(payload,store,controls) {
    return __lookupLoginUserPortal(store);
  },
};

/** *******************************************************
 Redirect Commands (These Commands Direct the Call to API or MOCK)
******************************************************* **/
export const FetchSessionPortalServCommand  = {
  key:'portal-serv-fetch-session',
  controllerValidationKey : 'portal-service',
  executeCommand : async function(payload,store,controls) {
    switch (__fetchDataSourceType(controls.authenticationData.taapenv)) {
      case MOCK_DATA_SOURCE: return await MockFetchSessionPortalServCommand.executeCommand(payload,store,controls);
      case API_DATA_SOURCE: return await ApiFetchSessionPortalServCommand.executeCommand(payload,store,controls);
    }
  },
};

export const LoginPortalServCommand = {
  key:'portal-serv-login',
  controllerValidationKey : 'portal-service',

  executeCommand : async function(payload,store,controls) {
    switch (__fetchDataSourceType(controls.authenticationData.taapenv)) {
      case MOCK_DATA_SOURCE: return await MockLoginPortalServCommand.executeCommand(payload,store,controls);
      case API_DATA_SOURCE: return await ApiLoginPortalServCommand.executeCommand(payload,store,controls);
    }
  },
};

export const LoginSkipPassPortalServCommand = {
  key:'portal-serv-login',
  controllerValidationKey : 'portal-service',

  // For the "View As" functionality we can skip the password since there is no auth needed
  // TODO - Mock already does this but API will need this added 
  executeCommand : async function(payload,store,controls) {
    switch (__fetchDataSourceType(controls.authenticationData.taapenv)) {
      case MOCK_DATA_SOURCE: return await MockLoginPortalServCommand.executeCommand(payload,store,controls);
      case API_DATA_SOURCE: return await ApiLoginSkipPassPortalServCommand.executeCommand(payload,store,controls);
    }
  },
};

export const LogoutPortalServCommand = {
  key:"portal-serv-logout",
  controllerValidationKey : 'portal-service',

  executeCommand : async function(payload,store,controls) {
    switch (__fetchDataSourceType(controls.authenticationData.taapenv)) {
      case MOCK_DATA_SOURCE: return await MockLogoutPortalServCommand.executeCommand(payload,store,controls);
      case API_DATA_SOURCE: return await ApiLogoutPortalServCommand.executeCommand(payload,store,controls);
    }
  },
};

export const FetchAllUsersServCommand = {
  key:"portal-fetch-all-users",
  controllerValidationKey : 'portal-service',

  executeCommand : async function(payload,store,controls) {
    switch (__fetchDataSourceType(controls.authenticationData.taapenv)) {
      case MOCK_DATA_SOURCE: return await MockFetchAllUsersServCommand.executeCommand(payload,store,controls);
      case API_DATA_SOURCE: return await ApiFetchAllUsersServCommand.executeCommand(payload,store,controls);
    }
  },
};

export const FetchFilteredUsersServCommand = {
  key:"portal-fetch-all-users",
  controllerValidationKey : 'portal-service',

  executeCommand : async function(payload,store,controls) {
    switch (__fetchDataSourceType(controls.authenticationData.taapenv)) {
      case MOCK_DATA_SOURCE: return await MockFetchFilteredUsersServCommand.executeCommand(payload,store,controls);
      case API_DATA_SOURCE: return await ApiFetchFilteredUsersServCommand.executeCommand(payload,store,controls);
    }
  },
};

/** *******************************************************
 Mock Commands
******************************************************* **/
export const TestOffloadSessionPortalServCommand = {
  key:'portal-serv-mock-offload-session',
  controllerValidationKey : 'portal-service',

  executeCommand : async function(payload,store,controls) {
    return await MockOffloadSessionPortalServCommand.executeCommand(payload,store,controls);},};

export const TestRegisteredSessionPortalServCommand = {
  key:'portal-serv-mock-login-registered',  
  controllerValidationKey : 'portal-service',

  executeCommand : async function(payload,store,controls) {
    return await MockRegisteredSessionPortalServCommand.executeCommand(payload,store,controls);},};

export const TestUnregisteredSessionPortalServCommand = {
  key:'portal-serv-mock-login-unregistered',
  controllerValidationKey : 'portal-service',

  executeCommand : async function(payload,store,controls) {
    return await MockUnregisteredSessionPortalServCommand.executeCommand(payload,store,controls);},};



export const TestLogoutSessionPortalServCommand = {
  key:'portal-serv-mock-login-unregistered',
  controllerValidationKey : 'portal-service',

  executeCommand : async function(payload,store,controls) {
    return await PortalAppController.executeCommand(LogoutPortalCommand);},};
import React, { useState } from 'react';
import Helmet from 'react-helmet';

import * as ImgConst from 'ui/modules/imageConstants';
import { GridContainer, Grid, Button, MediaBlockBody, Link} from '@trussworks/react-uswds';
import PortalCompositeHeader from 'ui/modules/common/ui-components/composite-header-standard';

import './portalLogin.scss';
import '../../../common/portal-standards.scss';
import MockButtonRow from 'ui/modules/common/ui-components/mock-buttons';
import { PortalAppController } from '../../logic/core/controllers';
import { LaunchLoginGovPortalCommand, LaunchLoginGovRegisterPortalCommand, LaunchRegisterPortalCommand } from '../../logic/core/commands/navigate-commands';
import PortalCompositeFooter from 'ui/modules/common/ui-components/composite-footer';

export const PortalLoginScreen = (myProps) => {
  

  const launchLoginGovLogin = (event) => {
    event.preventDefault();
    PortalAppController.executeCommand(LaunchLoginGovPortalCommand);
  }

  const launchLoginGovRegister = (event) => {
    event.preventDefault();
    PortalAppController.executeCommand(LaunchLoginGovRegisterPortalCommand);
  }

  const getCenterName = (center) => {
    switch (center) {
      case "ftt":
        return "Fee-to-Trust Center";
      case "mrtg":
        return "Mortgage Center";
      default:
        return "Home Screen";
    }
  }

  return (
  <>
    <Helmet><title>TAAP : Trust Asset Assistance Portal : Login page </title></Helmet>
    <MockButtonRow/>
    <PortalCompositeHeader toolName={getCenterName(myProps.center)}/>
    <main className='.portal-main'>

      <div className="bg-base-lightest">
        <GridContainer className="usa-section">
          <Grid row={true} className="margin-x-neg-205 margin-bottom-7 flex-justify-center">
            <Grid col={12} mobileLg={{ col: 10 }} tablet={{ col: 8 }} desktop={{ col: 6 }}
              className="padding-x-205 margin-bottom-7">
              <h1 className="desktop:display-none font-sans-lg margin-bottom-4 tablet:margin-top-neg-3"></h1>

              <div className="bg-white padding-y-3 padding-x-5 border border-base-lighter">
                <h1 className="margin-bottom-1">Sign in to your account</h1>

                <div className="usa-prose">
                  <p className="margin-top-1">You must sign in with a valid account on the Login.gov website in order to use the Trust Asset Assistance Portal.</p></div>

                <p><Link className="usa-button width-full" variant="unstyled" href={'#'} onClick={launchLoginGovLogin}>Sign in with Login.gov</Link></p>

                <div className="border-top border-base-lighter margin-top-6 padding-top-1">
                  <p><strong>{"Don't have an account?"}</strong></p>

                  <p>{"If you don't have an account already, sign up here:"}</p>

                  <p><Link className="usa-button width-full" variant="unstyled" href={'#'} onClick={launchLoginGovRegister}>  Create Login.gov account</Link></p>
                </div>
              </div>
            </Grid>

            <Grid col={12} mobileLg={{ col: 10 }} tablet={{ col: 8 }} desktop={{ col: 6 }} className="padding-x-205">
              <div className="border-top border-base-lighter padding-top-4 desktop:border-0 desktop:padding-top-0">
                <h2 className="display-none desktop:display-block"> Start by logging into Login.gov </h2>

                <div className="usa-prose">
                  <p> Whether you are a returning user or registering for the first time, you need to login using a Login.gov account.</p>

                  <section className="usa-graphic-list">
                    <div className="usa-graphic-list__row">
                      <div className="usa-media-block margin-y-2" key='1'>
                        <img  className="usa-media-block__img height-7 width-7" src={ImgConst.circ}  alt=""/>
                        <MediaBlockBody>
                              <p><strong>Are you a returning user?</strong>{' '}
                                Registered users will be redirected to the {getCenterName(myProps.center)}{' '}
                                welcome page dashboard after signing in on Login.gov.</p>
                        </MediaBlockBody>
                      </div>

                      <div className="usa-media-block margin-y-2" key='2'>
                        <img className="usa-media-block__img height-7 width-7" src={ImgConst.circ} alt="" />
                        <MediaBlockBody>
                          <p><strong>New to the Trust Asset Assistance Portal?</strong>{' '}
                            After logging in on Login.gov, you will be redirected to this page in order to register a new account.</p>
                        </MediaBlockBody>
                      </div>


                      <div className="usa-media-block margin-y-2" key='3'>
                        <img className="usa-media-block__img height-7 width-7" src={ImgConst.circ}  alt=""  />
                        <MediaBlockBody>
                          <p><strong>Don't have a Login.gov account? </strong>{' '}
                            Don't worry.  You can start the process by creating a Login.gov account today.
                            Select the button located at the bottom of the left hand column to start the account creation process.</p>
                        </MediaBlockBody>
                      </div>

                    </div>
                  </section>
                </div>
              </div>
            </Grid>
          </Grid>
        </GridContainer>
      </div>

    </main>
    <PortalCompositeFooter/>
  </>
  )}
export default PortalLoginScreen;
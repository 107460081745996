import { combineReducers } from 'redux';
import produce from 'immer';
import { deepCopy } from 'ui/modules/common/logic/utilities/utilities';

/* **************************************************************
************************************************************** */
export const portalServReducer = combineReducers({
  session  : portalServSessionReducer,
  accounts : portalServAccountsReducer,
});

/* **************************************************************
************************************************************** */
export function portalServSessionReducer(session=null, action) {
  switch (action.type) {
    
    case 'portal-serv/session/set': {
      return deepCopy(action.payload);
    }
    case 'portal-serv/session/login': {
      const loginAccount = deepCopy(action.payload);
      return produce(session,draft=>{draft.loginUser=loginAccount});
    }
    case 'portal-serv/session/logout': {
      return produce(session,draft=>{draft.loginUser={id:-999}});
    }
    case 'portal-serv/session/view-only/set': {
      const viewOnlyParam = deepCopy(action.payload);
      return produce(session,draft=>{draft.viewOnly=viewOnlyParam});
    }
    case 'portal-serv/session/data-owner/set': {
      const dataOwnerParam = deepCopy(action.payload);
      return produce(session,draft=>{draft.dataOwner=dataOwnerParam});
    }
    default:
      return session;
  }
}
/* **************************************************************
************************************************************** */
export function portalServAccountsReducer(accounts=null, action) {
  return accounts;
}

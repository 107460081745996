import { combineReducers } from 'redux';
import produce from 'immer';
import { deepCopy } from 'ui/modules/common/logic/utilities/utilities';

/* *********************************************
********************************************* */
export const moduleCacheReducer = combineReducers({
  navCache : navCacheReducer,
})

/* *********************************************
********************************************* */
export function navCacheReducer(navCache=null, action) {
  var pageInfoParam=undefined;
  var loginSuccessNavParam=undefined;
  var refreshKeyParam=undefined;
  switch (action.type) {

    case 'module-cache/nav-cache/set': {
      pageInfoParam = deepCopy(action.payload);
      return pageInfoParam;
    }
    case 'module-cache/nav-cache/page-info/set': {
      pageInfoParam = deepCopy(action.payload);
      return produce(navCache,draft=>{draft.pageInfo=pageInfoParam;});
    }
    case 'module-cache/nav-cache/login-success-nav/set': {
      loginSuccessNavParam = deepCopy(action.payload);
      return produce(navCache,draft=>{draft.loginSuccessNav=loginSuccessNavParam;});
    }
    case 'module-cache/nav-cache/login-success-nav/unset': {
      return produce(navCache,draft=>{draft.loginSuccessNav={};});
    }
    case 'module-cache/nav-cache/refresh-key/set': {
      refreshKeyParam = deepCopy(action.payload);
      return produce(navCache,draft=>{draft.refreshKey=refreshKeyParam;});
    }
  }
  return navCache;
}

/* *********************************************
********************************************* */
export const portalReducer = combineReducers({
  session : portalSessionReducer,
})

/* *********************************************
********************************************* */
export function portalSessionReducer(session=null, action) {
  var sessionParam=undefined;

  switch (action.type) {
    
    case 'portal/session/set': {
      sessionParam = deepCopy(action.payload);
      sessionParam.isLoaded = true;
      return sessionParam;
    }
    case 'portal/session/unload': {
      sessionParam = {isLoaded: false, token: -999, loginUser:{id:-999}};
      return sessionParam;
    }
  }
  return session;
}

import React, { useState } from 'react';

import * as ImgConst from 'ui/modules/imageConstants';
import {Modal,} from '@trussworks/react-uswds';

import '../../common/portal-standards.scss'
import PortalErrorContent from './PortalErrorContent';

export const PortalErrorModal = (myProps) => {

  /******************************************************************** */
  let [modalKey,setModalKey] = useState(1);
  const refreshModal = () => {let newVal = modalKey +1;setModalKey(newVal);};
  /******************************************************************** */
  
  return (<><Modal key={'port-err-mod-'+modalKey} isLarge  ref={myProps.registerRef} id="portal-error-modal"
         aria-labelledby="portal-error-modal-heading" aria-describedby="portal-error-modal-description">
           <PortalErrorContent modalRefresh={refreshModal} keyPrefix='val-mod-' contentRefreshLoader={myProps.contentRefreshLoader} registerRef={myProps.registerRef}/></Modal></>);};

export default PortalErrorModal;
/**
 * remove leading slash
 *
 * @param {string} seedUrl url taht needs preparation
 */

import { isNotEmpty } from "./utilities";


export const mergeObjects= (baseObj, additionalObj,) => {
  let returnObject = {};
  if (isNotEmpty(baseObj)) {
    Object.keys(baseObj).forEach((keyVal,indx) => {returnObject[keyVal] = baseObj[keyVal];});};
  if (isNotEmpty(additionalObj)) {
    Object.keys(additionalObj).forEach((keyVal,indx) => {returnObject[keyVal] = additionalObj[keyVal];});};
  return returnObject;};

import React, { useState } from 'react';

import { Modal, } from '@trussworks/react-uswds';

import '../../common/portal-standards.scss';
import PortalSuccessContent from './PortalSuccessContent';

export const PortalSuccessModal = (myProps) => {

  /******************************************************************** */
  let [modalKey,setModalKey] = useState(1);
  const refreshModal = () => {let newVal = modalKey +1;setModalKey(newVal);};
  /******************************************************************** */
   




  return (<>
    <Modal key={'port-suc-mod-'+modalKey} isLarge ref={myProps.registerRef} id="portal-suc-modal"
      aria-labelledby="port-suc-modal-heading" aria-describedby="port-suc-modal-description">
      <PortalSuccessContent  modalRefresh={refreshModal} keyPrefix='-suc-'
        contentRefreshLoader={myProps.contentRefreshLoader} registerRef={myProps.registerRef}  /></Modal></>);};

export default PortalSuccessModal;
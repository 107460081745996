/* ************************** 
Import Section
* ************************** */

//Framework Imports
import { Grid, GridContainer, Link, MediaBlockBody } from '@trussworks/react-uswds';
import React, { useState } from 'react';
import Helmet from 'react-helmet';

//Portal Imports
import PortalCompositeHeader from 'ui/modules/common/ui-components/composite-header-standard';
import * as ImgConst from 'ui/modules/imageConstants';

//Portal Home Imports
import MockButtonRow from 'ui/modules/common/ui-components/mock-buttons';
import PortalHomeHeader from './components/portalHomeHeader';
import './portalHome.scss';

import PortalCompositeFooter from 'ui/modules/common/ui-components/composite-footer';
import { LaunchCenterPortalCommand, LaunchRegisterPortalCommand } from '../../logic/core/commands/navigate-commands';
import { PortalAppController, PortalProtectedController } from '../../logic/core/controllers';
import { PortalServiceController } from '../../logic/service/controllers';
import { FetchLoginUserPortalHomeCommand } from '../../logic/service/commands/session-commands';
import { isNotEmpty } from 'ui/modules/common/logic/utilities/utilities';


/* ****** Component ****** */
export const PortalHome = () => {
  const [user, setUser] = useState({email:''});
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoadingStarted, setIsLoadingStarted] = useState(false);

  if (isLoadingStarted == false) {
    setIsLoadingStarted(true);
    PortalServiceController.executeCommand(FetchLoginUserPortalHomeCommand).then((userVal) => {
      setUser(userVal);
      setIsLoaded(true);
    })
  }

  /* ****** Nav Setup and Logic ****** */
  const isLoggedIn = () => { 
    return (isNotEmpty(user) && isNotEmpty(user.email)); 
  }

  const clickDispatcher = (event) => {
    event.preventDefault();
    console.log("Portal home  .....  clickDispatcher:" + event.target.id + ":");
    const payload = { targetCenter: '' };
    switch (event.target.id) {
      case "ftt-button":
        payload.targetCenter = "FTT";
        PortalAppController.executeCommand(LaunchCenterPortalCommand,payload);
        return;
      case "mrtg-button":
        payload.targetCenter = "MRTG";
        PortalAppController.executeCommand(LaunchCenterPortalCommand,payload);
        return;
      case "message-button":
        payload.targetCenter = "MC";
        PortalProtectedController.executeCommand(LaunchCenterPortalCommand,payload);
        return;
      case "register-button":
        payload.targetCenter = "REG";
        PortalProtectedController.executeCommand(LaunchRegisterPortalCommand,payload);
        return;
    }
    return;
  }

  const getBody = () => {
    if (isLoggedIn() == true) {
      return (<><Grid row gap className="usa-graphic-list__row">
                  {fttSection()}
                  {mortgageSection()}
                </Grid><Grid row gap className="usa-graphic-list__row">
                </Grid></>);
    } else {
      return (<><Grid row gap className="usa-graphic-list__row">
                {registerSection()}
                {fttSection()}
              </Grid><Grid row gap className="usa-graphic-list__row">
                {mortgageSection()}
              </Grid></>);
    }
  }

  const registerSection = () => {
    return (<><Grid tablet={{ col: true }} className="usa-media-block">
                <img className="usa-media-block__img" src={ImgConst.circ} alt='' />
                <MediaBlockBody>
                  <h2 className="usa-graphic-list__heading">  Are you new?  Register an account today</h2>
                  <ul>
                    <li>The process begins with creating an account</li>
                    <li>All registrations are secured by the Login.gov program</li>
                    <li>Already registered with Login.gov?  You are halfway there</li>
                  </ul>
                  <Link className="usa-button" variant="unstyled" href={'#'} id="register-button" onClick={clickDispatcher}>Register an account today</Link>
                </MediaBlockBody>
              </Grid></>);
  }

  const fttSection = () => {
    return (<><Grid tablet={{ col: true }} className="usa-media-block">
                <img className="usa-media-block__img" src={ImgConst.circ} alt='' />
                <MediaBlockBody>
                  <h2 className="usa-graphic-list__heading">  Fee-to-Trust Center</h2>
                  <ul>
                    <li>Submit new applications</li>
                    <li>Monitor application progress</li>
                    <li>Receive feedback</li>
                    <li>Submit updates</li>
                  </ul>
                  <Link className="usa-button" variant="unstyled" href={'#'} id="ftt-button" onClick={clickDispatcher}>Launch the FTT Submit Center</Link>
                </MediaBlockBody>
              </Grid></>);
  }

  const mortgageSection = () => {
    return (<><Grid tablet={{ col: true }} className="usa-media-block">
                <img className="usa-media-block__img" src={ImgConst.circ} alt='' />
                <MediaBlockBody>
                  <h2 className="usa-graphic-list__heading">  Mortgage Center</h2>
                  <ul>
                    <li>Submit new applications</li>
                    <li>Monitor application progress</li>
                    <li>Receive feedback</li>
                    <li>Submit updates</li>
                  </ul>
                  <Link className="usa-button" variant="unstyled" href={'#'} id="mrtg-button" onClick={clickDispatcher}>Launch the Mortgage Submit Center</Link>
                </MediaBlockBody>
              </Grid></>);
  }

  if (isLoaded == false) {
    return (<></>);
  }

  /* ****** Component Return ****** */
  return (
    <>
      <Helmet><title>TAAP : Trust Asset Assistance Portal : Home page </title></Helmet>
      <MockButtonRow />
      <PortalCompositeHeader toolName="Trust Asset Assistance Portal" />
      <main className="portal-main" id="main-content">
        <PortalHomeHeader />
        <section className="grid-container portal-home-tagline-section">
          <Grid row gap>
            <Grid tablet={{ col: 4 }}>
              <h2 className="portal-home-tagline margin-top-0 tablet:margin-bottom-0">  Digital Center built to serve you   </h2>
            </Grid>
            <Grid tablet={{ col: 8 }} className="usa-prose">
              <p>
                The new Trust Asset Assistance Portal is built to simplify your workload.  This portal will serve you by leveraging modern digital tools.  Please choose a center to begin.
              </p>
            </Grid>
          </Grid>
        </section>

        <section className="usa-graphic-list usa-section usa-section--dark">
          <GridContainer>
            {getBody()}
          </GridContainer>
        </section>

      </main>
      <PortalCompositeFooter /></>);
}; export default PortalHome;
import { handleGetAllUsers, handleGetFilteredUsers, handleGetPortalSession, handlePortalLogout, handlePostAuthenticateUser } from "../helpers/session-calls";


/* ***     *** */
export const ApiFetchSessionPortalServCommand = {
  key:'portal-serv-fetch-session',
  controllerValidationKey : 'portal-service',

  executeCommand : async function(payload,store,controls) {
    let response:any={};
    try {
      response = await handleGetPortalSession();
      response = response.data.payload;
    } catch {
      response = {token:-999,loginUser:{id:-999}}
    }
    return response
  },
};

export const ApiLoginPortalServCommand = {
  key:'portal-serv-login',
  controllerValidationKey : 'portal-service',

  executeCommand : async function(payload,store,controls) {
    return await handlePostAuthenticateUser(payload);
  },
};

export const ApiLoginSkipPassPortalServCommand = {
  key:'portal-serv-login-skip-pass',
  controllerValidationKey : 'portal-service',

  executeCommand : async function(payload,store,controls) {
    // TODO
    return;
  },
}

export const ApiLogoutPortalServCommand = {
  key:"portal-serv-logout",
  controllerValidationKey : 'portal-service',

  executeCommand : async function(payload,store,controls) {
    return await handlePortalLogout();
  },
};

export const ApiFetchAllUsersServCommand = {
  key:'portal-fetch-all-users',
  controllerValidationKey : 'portal-service',

  executeCommand : async function(payload,store,controls) {
    return await handleGetAllUsers(payload);
  },
};

export const ApiFetchFilteredUsersServCommand = {
  key:'portal-fetch-user-by-email',
  controllerValidationKey : 'portal-service',

  executeCommand : async function(payload,store,controls) {
    return await handleGetFilteredUsers(payload);
  },
}

import { PortalServiceController } from "ui/modules/portal/logic/service/controllers";
import { LogoutPortalServCommand, TestLogoutSessionPortalServCommand, TestOffloadSessionPortalServCommand, TestRegisteredSessionPortalServCommand, TestUnregisteredSessionPortalServCommand } from "ui/modules/portal/logic/service/commands/session-commands";

export const mockButtonsClickDispatcher = async (event) => {
  console.log("Portal home  .....  mockButtonsClickDispatcher:"+event.target.id+":");
  switch (event.target.id) {
    case 'mock-offload-button' :  await PortalServiceController.executeCommand(TestOffloadSessionPortalServCommand);return;
    case 'mock-login-reg-empty-button' :  await PortalServiceController.executeCommand(TestRegisteredSessionPortalServCommand,'EMPTY');return;
    case 'mock-login-reg-single-button' :  await PortalServiceController.executeCommand(TestRegisteredSessionPortalServCommand,'SINGLE');return;
    case 'mock-login-reg-multiple-button' :  await PortalServiceController.executeCommand(TestRegisteredSessionPortalServCommand,'MULTIPLE');return;
    case 'mock-login-unregistered-button' :  await PortalServiceController.executeCommand(TestUnregisteredSessionPortalServCommand);return;
    case 'mock-logout-button' : await PortalServiceController.executeCommand(TestLogoutSessionPortalServCommand);return;
    // case 'mock-launch-home-button' : navigateTo('/pc/home');return;
    // case 'mock-launch-message-page-button' : navigateTo('/ftt/message-center?id=120652');return;
    // case 'mock-launch-action-page-button' : navigateTo('/ftt/app-actions?id=120652');return;
    // case 'mock-launch-processing-page-button' : navigateTo('/ftt/app-processing?id=120652');return;

  }
}


// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../content/images/PolicyFormshome_banner.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loginBody {\n  width: 100%;\n  height: 100%;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center top;\n  background-size: cover;\n  padding-top: 4rem;\n  padding-bottom: 4rem;\n}", "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/login/app-login.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,wEAAA;EACA,sBAAA;EACA,iBAAA;EACA,oBAAA;AACJ","sourcesContent":[".loginBody{\n    width: 100%; \n    height: 100%;\n    background: url('../../../content/images/PolicyFormshome_banner.jpg') no-repeat center top;\n    background-size: cover;\n    padding-top: 4rem;\n    padding-bottom: 4rem;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React from 'react';

import * as ImgConst from 'ui/modules/imageConstants';

import { GovBanner, Header, Identifier, IdentifierGov, IdentifierIdentity, IdentifierLink, IdentifierLinkItem, IdentifierLinks, IdentifierLogo, IdentifierLogos, IdentifierMasthead, Link, Title } from '@trussworks/react-uswds';
import { Col, Container, Row } from 'reactstrap';

export const IdentifierFooter = () => {

//    const imgDirectory          = '../../../../content/images/';
//    const biaFooterLogoImg      = imgDirectory + 'bia_footer_logo.png';
//    const circleImg             = imgDirectory + 'circle-gray-20.svg';
    
    const identifierLinksText = [
        'About the Department of the Interior',
        'Accessibility support',
        'Disclaimer',
        'FOIA requests',
        'No FEAR Act data',
        'Notices',
        'Office of the Inspector General',
        'Performance reports',
        'Privacy policy', 
        'Vulnerability Disclosure Policy',
    ]
    
    const identifierAddresses = [
        'https://www.doi.gov/about',
        'https://www.doi.gov/accessibility',
        'https://www.bia.gov/disclaimer',
        'https://www.bia.gov/as-ia/foia',
        'https://www.doi.gov/pmb/eeo/reports-repository',
        'https://www.bia.gov/notices',
        'https://www.doioig.gov/',
        'https://www.doi.gov/bpp',
        'https://www.bia.gov/privacy-policy',
        'https://www.bia.gov/vulnerability-disclosure-policy',
    ]

    return (
        <Identifier>
        <IdentifierMasthead aria-label="Agency identifier">
          <IdentifierLogos>
            <IdentifierLogo href="https://www.doi.gov/"><img className="usa-identifier__logo-img" src={ImgConst.doiFooterLogo} alt="U.S. Department of the Interior. March 3, 1849"/></IdentifierLogo>
            <IdentifierLogo href="https://www.bia.gov/"><img className="usa-identifier__logo-img" src={ImgConst.biaFooterLogo} alt="U.S. Department of the Interior. Bureau of Indian Affairs. 1824"/></IdentifierLogo>
          </IdentifierLogos>
          <IdentifierIdentity domain={'indianaffairs.gov'}>
            An official website of the{' '}<Link href="https://www.doi.gov/">{`U.S. Department of the Interior`}</Link>
          </IdentifierIdentity>
        </IdentifierMasthead>
        <IdentifierLinks navProps={{ 'aria-label': 'Important links' }}>
          {identifierLinksText.map((text, idx) => (
            <IdentifierLinkItem key={idx}>  <IdentifierLink href={identifierAddresses[idx]}>{text}</IdentifierLink> </IdentifierLinkItem>
          ))}
        </IdentifierLinks>
        <IdentifierGov aria-label="U.S. government information and services">
          <div className="usa-identifier__usagov-description">  Looking for U.S. government information and services?</div>
          &nbsp;
          <Link href="https://www.usa.gov/" className="usa-link">Visit USA.gov</Link>
        </IdentifierGov>
      </Identifier>
    );
};


export default IdentifierFooter;
export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

/*** ****************** */
export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';

/*** ****************** */
export const ENV_PROD = 'prod';
export const ENV_LOCAL = 'local';
export const ENV_QA9 = 'qa9';
export const ENV_QA10 = 'qa10';
export const ENV_QA_LIST = [ENV_QA9,ENV_QA10];

export const isENV_QA = (envParam) => {return (ENV_QA_LIST.includes(envParam)) ? true : false;}

/*** ****************** */
export const API_DATA_SOURCE  = 'API';
export const MOCK_DATA_SOURCE = 'MOCK';

/*** ****************** */
export const FTT_DOC_CODE_SINGLE='single_document';
export const FTT_DOC_CODE_CUSTOM_USER='file_custom_user';
export const FTT_DOC_CODE_REQUEST_LETTER = "file_request_letter";
export const FTT_DOC_CODE_PLAT_MAP = "file_plat_map";
export const FTT_DOC_CODE_INDIAN_EVIDENCE = "file_indian_status_evidence";
export const FTT_DOC_CODE_TITLE_EVIDENCE = "file_title_evidence";
export const FTT_DOC_CODE_OWNERSHIP = "file_deed";
export const FTT_DOC_CODE_TRIBAL_RESOLUTION = "file_council_resolution";

/*** ****************** */
export const MESSAGE_CHANGE='MESSAGE_CHANGE';
export const MESSAGE_SEND='MESSAGE_SEND';
export const MESSAGE_SAVE='MESSAGE_SAVE';
export const MESSAGE_DELETE='MESSAGE_DELETE';
export const MESSAGE_NEW='MESSAGE_NEW';
export const MESSAGE_READ='MESSAGE_READ';

export const RESUBMIT_UPLOAD_ADDTIONAL_DOCUMENTS = "Must upload a digital file and description for all additional documents that were added";
export const RESUBMIT_CONFIRM_ADDTIONAL_DOCUMENTS = "Must confirm the digital file contains all additional documents and provide descriptions";

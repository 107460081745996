import React, { useState } from 'react';

import { GovBanner, GridContainer, Grid, Header, Title, Link, Button } from '@trussworks/react-uswds';
import * as ImgConst from 'ui/modules/imageConstants';
import './composite-header-standard.scss';
import { PortalAppController } from 'ui/modules/portal/logic/core/controllers';
import { CloseViewAsPortalCommand, FetchViewOnlyPortalCommand, LogoutPortalCommand, ResetDataOwnerPortalCommand } from 'ui/modules/portal/logic/core/commands/core-commands';
import { isNotEmpty } from '../logic/utilities/utilities';
import { useNavigate } from 'react-router-dom';


export const PortalCompositeHeader = (myProps) => {
  const navigate = useNavigate();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [viewOnly, setViewOnly] =  useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoadingStarted, setIsLoadingStarted] = useState(false);
  
  if (isLoadingStarted == false) {
    setIsLoadingStarted(true);
    PortalAppController.executeCommand(FetchViewOnlyPortalCommand).then((viewOnlyVal) => {
      setViewOnly(viewOnlyVal);
      setIsLoaded(true);
    });
  }

  if (isLoaded == false) {
    return (<></>);
  }
  
  const toggleMobileNav = (): void => {
    setMobileNavOpen((prevOpen) => !prevOpen)
  }

  // Hide account buttons by default and show when necessary
  const displayAccountButtons = (showButtons) => {
    if (isNotEmpty(showButtons) && showButtons == true && viewOnly == false) {
      return <Grid row className="portal-account-button-row">
                <Grid col={10} className="portal-logout-label-margin">
                  <Link className="portal-button-label" variant="unstyled" href={'#'} onClick={(event) => {
                      event.preventDefault(); 
                      navigate("/pc/account")}}>
                      My Account
                  </Link> 
                </Grid>
                <Grid col={2} className="portal-logout-label-margin">
                  <Link className="portal-button-logout-label" variant="unstyled" href={'#'} onClick={(event) => {
                      event.preventDefault(); 
                      PortalAppController.executeCommand(LogoutPortalCommand);}}>
                      Logout
                  </Link>
                </Grid>
              </Grid>;
    }
    return <></>;
  }

  const displayViewOnlyBanner = () => {
    if (viewOnly == true) {
      return <GridContainer>
              <Grid row className="portal-error-colors portal-view-only-header"><Grid>
                <div><b>You are currently viewing the portal as another user.</b></div>
                <div><b>You will not be able to make any changes until the view is closed.</b></div>
              </Grid><Grid className="portal-float-right">
                  <Link className="usa-button portal-float-right" variant="unstyled" href={'#'} onClick={(event) => {
                        event.preventDefault(); 
                        PortalAppController.executeCommand(CloseViewAsPortalCommand);}}>
                        Close View
                  </Link>
              </Grid></Grid>
            </GridContainer>
    } else {
      return <></>;
    }
  }

  // Show either the full title without buttons, or the shortened title with buttons
  const getTitleRow = (showButtons) => {
    if (isNotEmpty(showButtons)) {
      return (<><Grid className='portal-logo-tool-container' col={6}>
        <Title className="portal-logo-tool-title">{myProps.toolName}</Title>
      </Grid><Grid col={2}>
        {displayAccountButtons(showButtons)}
      </Grid></>);
    } else {
      return (<><Grid className='portal-logo-tool-container' col={8}>
        <Title className="portal-logo-tool-title">{myProps.toolName}</Title>
      </Grid></>);
    }
  }

  return (
    <>
      <GovBanner />
      <div className={`usa-overlay ${mobileNavOpen ? 'is-visible' : ''}`}></div>
      <Header extended className='portal-header'>
        <GridContainer><Grid row><Grid col={4}>
          <div className="portal-logo-department">U.S. Department of the Interior</div>
          <div className="portal-logo-agency">
            <a className="portal-logo-agency-link" href="https://bia.gov" rel="home" title="Home" aria-label="Indian Affairs">
              <span className="portal-logo-agency-text">Indian Affairs</span></a>
          </div>
          </Grid>
          {getTitleRow(myProps.showButtons)}
          </Grid>
        </GridContainer>
      </Header>
      {displayViewOnlyBanner()}
    </>
)}


export default PortalCompositeHeader;
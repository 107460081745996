import { openId, api } from './axios';

const clientId = "newClient";
const redirectUri = "http://localhost:8080/taap-ui"

//Login function for login.gov
export const redirectToLoginGov = () => {
    window.location.href =
        "/taap-core/oauth2/authorization/logingov";
}
//Login function when using the oauth-authorization-server (keycloak) project
// export const login = () => {
//     let responseTypeParam = "response_type=code";
//     let scopeParam = "scope=openid%20write%20read";
//     let clientIdParam = "client_id=" + clientId;
//     let redirectUriParam = "redirect_uri=" + redirectUri;
//     let params = [responseTypeParam, scopeParam, clientIdParam, redirectUriParam];
//     window.location.href =
//         "http://localhost:8083/auth/realms/baeldung/protocol/openid-connect/auth?" + params.join("&");
// }

//BEGIN oauth-authorization-server (keycloak) related methods
export const authChecks = () => {
    checkForLoginCode();
    putTokenInAxios();
}

const checkForLoginCode = () => {
    let i = window.location.href.indexOf('code=');
    if (i != -1) {
        retrieveToken(window.location.href.substring(i + 5));
    }
    
}
const retrieveToken = async (code) => {
    let params = new URLSearchParams();
    params.append('grant_type', 'authorization_code');
    params.append('client_id', clientId);
    params.append('redirect_uri', redirectUri);
    params.append('code', code);
    
    let headers = { 'Content-type': '' };
    const response = await openId.post(
        "/realms/baeldung/protocol/openid-connect/token",
        params,
        {
            headers: { "Content-Type": "application/x-www-form-urlencoded; charset=utf-8" }
        }
        ).then(
            (response) => {
                console.log(response);
            saveToken(response.data);
        }
        )
        .catch(
            (err) => {
                console.log(err);
            }
        );
}

const saveToken = (token) => {
    // let expireDate = new Date().getTime() + (1000 * token.expires_in);
    localStorage.setItem("access_token", token.access_token);
    putTokenInAxios();
    window.location.href = 'http://localhost:8080/taap-ui';
}
const putTokenInAxios = () => {
    let token = localStorage.getItem("access_token");
    if (token) {
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
}

//END oauth-authorization-server (keycloak) related methods
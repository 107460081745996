/********************************************************************* */
/********************************************************************* */
/********************************************************************* */
/********************************************************************* */
let idSeed=9990000;
export var portalStartState = {
  portal : {
    session : {isLoaded:false, token:-999,loginUser : {id:-999}}
  },
  portalServ : {
    accounts : [{id: '2888', email:'empty@bia.gov', 
                  portalInfo: {firstName:'Stanley',middleName:'',lastName:'Tor',},},
                {id: '8725', email:'single@bia.gov', 
                  portalInfo: {firstName:'Kent',middleName:'',lastName:'Lowry',},},
                {id: '34433', email:'multiple@bia.gov', 
                  portalInfo: {firstName:'Agness',middleName:'',lastName:'Cross',},},
                {id: '67341', email:'realty-office@bia.gov',
                  portalInfo: {firstName:'BIA',middleName:'',lastName:'REALTY-OFFICE',},},
                {id: '46482', email:'test-user1@bia.gov',
                  portalInfo: {firstName:'John',middleName:'',lastName:'Baker',},},
                {id: '93483', email:'test-user2@bia.gov',
                  portalInfo: {firstName:'Jenny',middleName:'',lastName:'Adams',},},
                {id: '82134', email:'test-user3@bia.gov',
                  portalInfo: {firstName:'James',middleName:'',lastName:'Palmer',},},
                {id: '6512', email:'unregistered@bia.gov', 
                  portalInfo: {firstName:'',middleName:'',lastName:'',},},],
    session : {token:-999, loginUser : {id:-999}},
  },
  moduleCache : {
    navCache : {pageInfo: {center:'portal',path:'/pc/home',params:[]},
                loginSuccessNav : {}, refreshKey: 0}
  }
};



 


import React, { useEffect, useRef, useState } from 'react';

import { Button, ButtonGroup, Modal, ModalFooter, ModalHeading, ModalRef } from '@trussworks/react-uswds';

import getStore, { useAppDispatch } from 'ui/config/portal/portal-store';
import { isNotEmpty } from 'ui/modules/common/logic/utilities/utilities';
import '../../common/portal-standards.scss';
import { LogoutPortalCommand, RefreshSessionPortalCommand, VerifyLoginUserPortalCommand } from '../logic/core/commands/core-commands';
import { LaunchLoginPortalCommand } from '../logic/core/commands/navigate-commands';
import { PortalAppController } from '../logic/core/controllers';
import './PortalSessionTimeoutModal.scss';
import { getEnvironment } from 'ui/modules/common/reducers/authentication';

export const PortalSessionTimeoutModal = (myProps) => {

  /******************************************************************** */
  const [modalKey, setModalKey] = useState(1);
  //citing effectState as a dependency down in the useEffect below will help us not launch the timeout timer with every re-render of this component
  const [effectState, setEffectState] = useState('');
  const sessionModalRef = useRef<ModalRef>(null);
  const dispatch = useAppDispatch();

  const createSessionTimeoutTimer = (timerLengthMilliseconds = 60000) => {
    const TEN_MINUTES_AS_SECONDS = 10 * 60;
    const FIVE_MINUTES_AS_SECONDS = 5 * 60;
    const ONE_MINUTE_AS_SECONDS = 60;
    const ONE_MINUTE_AS_MILLISECONDS = 60000;
    console.log("createSessionTimeoutTimer called");
    return setTimeout(async () => {
      const isLoggedIn = await PortalAppController.executeCommand(VerifyLoginUserPortalCommand);
      let timeoutMilliseconds = ONE_MINUTE_AS_MILLISECONDS;
      if (isLoggedIn) {
        const store = getStore();
        const sessionTimeoutSeconds = store.getState().portal.session?.sessionTimeoutSeconds;
        const lastResponseTime = store.getState().authentication.lastSuccessfulResponseTime;
        if (isNotEmpty(sessionTimeoutSeconds) && isNotEmpty(lastResponseTime) && sessionTimeoutSeconds > 0 && lastResponseTime > 0) {
          const secondsBeforeSessionExpiryToLaunchDialog = sessionTimeoutSeconds > TEN_MINUTES_AS_SECONDS ? FIVE_MINUTES_AS_SECONDS : ONE_MINUTE_AS_SECONDS
          const maxInactiveSeconds = sessionTimeoutSeconds - secondsBeforeSessionExpiryToLaunchDialog;

          //We'll try to calculate our timeout so that the dialog will open at secondsBeforeSessionExpiryToLaunchDialog
          timeoutMilliseconds = sessionModalRef.current.modalIsOpen ? ONE_MINUTE_AS_MILLISECONDS : (maxInactiveSeconds * 1000) - (Date.now() - lastResponseTime);
          timeoutMilliseconds = timeoutMilliseconds > 0 ? timeoutMilliseconds : 10000;


          const secondsDiff = (Date.now() - lastResponseTime) / 1000;
          if (secondsDiff > maxInactiveSeconds) {
            //If we think we're already expired - send to login page
            if (secondsDiff > sessionTimeoutSeconds) {
              handleLogout();
            }
            //Otherwise we think we're still in the window of secondsBeforeSessionExpiryToLaunchDialog so we'll launch the dialog if it's not already open
            else if (!sessionModalRef.current.modalIsOpen) {
              sessionModalRef.current.toggleModal();
            }
          }
        }
      }
      createSessionTimeoutTimer(timeoutMilliseconds);
    }, timerLengthMilliseconds);
  }

  const handleOKButtonClick = async () => {
    await PortalAppController.executeCommand(RefreshSessionPortalCommand, { forceLoad: true });
    const isLoggedIn = await PortalAppController.executeCommand(VerifyLoginUserPortalCommand);
    //Calling getEnvironment is a bit of a workaround when using mock data so that we'll still be able to reset our session timer.
    dispatch(getEnvironment());
    if (sessionModalRef.current.modalIsOpen) {
      sessionModalRef.current.toggleModal();
    }
    if (!isLoggedIn) {
      await PortalAppController.executeCommand(LaunchLoginPortalCommand);
    }
  }
  const handleLogout = async () => {
    if (sessionModalRef.current.modalIsOpen) {
      sessionModalRef.current.toggleModal();
    }
    await PortalAppController.executeCommand(LogoutPortalCommand);
    await PortalAppController.executeCommand(LaunchLoginPortalCommand);
  }


  useEffect(() => {
    const checkForSessionTimeoutTimerId = createSessionTimeoutTimer();
    return () => clearTimeout(checkForSessionTimeoutTimerId);
  }, [effectState]);
  /******************************************************************** */



  return (<Modal ref={sessionModalRef} forceAction key={'port-sess-timeout-mod-' + modalKey} id="portal-sess-timeout-modal"
    aria-labelledby="portal-sess-timeout-modal-heading" aria-describedby="portal-sess-timeout-modal-description">
    <ModalHeading id="portal-sess-timeout-modal-heading">
      Your session will end soon.
    </ModalHeading>
    <div className="usa-prose">
      <p id="portal-sess-timeout-modal-description">
        Please choose to stay signed in or sign out. Otherwise, you'll be signed out automatically in 5 minutes.
      </p>
    </div>
    <ModalFooter>
      <ButtonGroup>
        <Button type='button' onClick={handleOKButtonClick} id="portal-sess-timeout-modal-yes-button">Yes, stay signed in</Button>
        <Button type='button' onClick={handleLogout} id="portal-sess-timeout-modal-no-button">Sign out</Button>
      </ButtonGroup>
    </ModalFooter>
  </Modal>);
};

export default PortalSessionTimeoutModal;
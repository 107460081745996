import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { portalStartState } from 'ui/modules/portal/logic/core/states';
import { moduleCacheReducer, portalReducer } from 'ui/modules/portal/logic/core/reducers';
import { portalServReducer } from 'ui/modules/portal/logic/mock/service-reducers';
import authentication from 'ui/modules/common/reducers/authentication';

const store = configureStore({
  reducer: {
    portal : portalReducer,
    portalServ : portalServReducer,
    moduleCache : moduleCacheReducer,
    authentication
  },
  preloadedState :portalStartState,});

const getStore = () => store;

export type IRootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<IRootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, IRootState, unknown, AnyAction>;
export default getStore;
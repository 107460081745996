import axios from 'axios';

export const API_BASE_URL="/taap-core/api";

export const root =  axios.create({
  baseURL: "/taap-ui"
});
export const api =  axios.create({
  baseURL: API_BASE_URL
});
export const openId =  axios.create({
  baseURL: "http://localhost:8083/auth"
});

export const setupAxiosInterceptors = (onSuccessfulResponse) => {
  const onResponseSuccess = response => {
    onSuccessfulResponse();
    return response;
  }
  api.interceptors.response.use(onResponseSuccess);
};

export default api;
import { deepCopy, isNotEmpty } from "ui/modules/common/logic/utilities/utilities";
import { PortalAppController } from "../controllers";

/***  ***/
const __lookupNavigationCache = (store) => {
  return deepCopy(store.getState().moduleCache.navCache)};

/***  ***/
const __verifyQueryParams = (navCache) => {
  if (isNotEmpty(navCache) && isNotEmpty(navCache.pageInfo) && 
      isNotEmpty(navCache.pageInfo.params)) {return true;}; return false;};
const __lookupQueryParams = (store) => {
  return deepCopy(store.getState().moduleCache.navCache.pageInfo.params)};

/***  ***/
const __verifyFullPath = (navCache) => {
  if (isNotEmpty(navCache) && isNotEmpty(navCache.pageInfo) && 
      isNotEmpty(navCache.pageInfo.path)) {return true;}; return false;};
const __lookupFullPath = (store) => {
  return deepCopy(store.getState().moduleCache.navCache.pageInfo.path)};

  /***  ***/
const __verifyLoginSuccessNav = (navCache) => {
  if (isNotEmpty(navCache) && isNotEmpty(navCache.loginSuccessNav)) {return true;}; return false;};
const __lookupLoginSuccessNav = (store) => {
  return deepCopy(store.getState().moduleCache.navCache.loginSuccessNav)};
  

  /* *** URL values *** */
const __determineUrl = (location) => {
  return location.pathname + location.search}
const __prepareParamArray = (location) => {
  var queryParams = new URLSearchParams(location.search);
  const returnArray = {};
  queryParams.forEach((value,key) => {
    returnArray[key]=value;
  });
  return returnArray;
}

export const RegisterPagePortalCommand = {
  key: "portal-store-page-info",
  controllerValidationKey: 'portal-app',

  executeCommand : async function(payload,store,controls) {
    var pageInfoParam = {center: payload.center, path:__determineUrl(controls.location), 
                         params:__prepareParamArray(controls.location)};
    store.dispatch({type:'module-cache/nav-cache/page-info/set',payload:pageInfoParam});
  }
}

export const FetchFullPathPortalCommand = {
  key: "portal-fetch-full-path-param",
  controllerValidationKey: 'portal-app',

  executeCommand : async function(payload,store,controls) {
    if (__verifyFullPath(__lookupNavigationCache(store))) {
      return __lookupFullPath(store);}; 
      await PortalAppController.executeCommand(RegisterPagePortalCommand);
    if (__verifyFullPath(__lookupNavigationCache(store))) {
      return __lookupFullPath(store);} 
    return '';},};

export const FetchQueryParamPortalCommand = {
  key: "portal-fetch-query-param",
  controllerValidationKey: 'portal-app',

  executeCommand : async function(payload,store,controls) {
    let lookupMap = {};
    if (__verifyQueryParams(__lookupNavigationCache(store))) {
      lookupMap = __lookupQueryParams(store);};
    if (isNotEmpty(payload)) {
      return lookupMap[payload];};
  return lookupMap;},};

export const FetchQueryStringPortalCommand = {
  key: "portal-fetch-quer-string",
  controllerValidationKey: 'portal-app',

  executeCommand : async function(payload,store,controls) {
    let returnString = '';
    if (__verifyQueryParams(__lookupNavigationCache(store))) {
      let params = __lookupQueryParams(store);
      Object.keys(params).forEach((key)=> {
        returnString = key+'='+params[key]+'&';
      });
    };
    return returnString;
  },
};

export const FetchSuccessNavPortalCommand = {
  key: "portal-fetch-success-nav-param",
  controllerValidationKey: 'portal-app',

  executeCommand : async function(payload,store,controls) {
    if (__verifyLoginSuccessNav(__lookupNavigationCache(store))) {
      return __lookupLoginSuccessNav(store);
    };
    return {};
  },
};
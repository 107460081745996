import './home.scss';
import React from 'react';
import { Accordion, Table, Title } from '@trussworks/react-uswds';
import { AccordionItemProps } from '@trussworks/react-uswds/lib/components/Accordion/Accordion';

export const Home = () => {
  const newAppContents = (
    <div>
      * Don't see a form you need? You may not have access. Visit the Access request page.
      <Table striped fullWidth>
        <thead>
          <tr>
            <th scope="col">Title</th>
            <th scope="col">Description</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><span className="darkRed">FTT Application</span></td>
            <td>Please use this form to apply for a Fee-to-Trust acquisition.</td>
            <td><span className="purple">Select to start a new application.</span></td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
  const inProgressContents = (
    <div>
      * IMPORTANT - in progress applications have been saved but not submitted.<br/>
      * Open an application to edit and/or submit
      <Table striped fullWidth>
        <thead>
          <tr>
            <th scope="col">Application</th>
            <th scope="col">Type</th>
            <th scope="col">Status</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><span className="darkRed">FTT0001</span></td>
            <td>FTT application</td>
            <td>Ready for Submission</td>
            <td><span className="purple">Select to view, edit or submit</span></td>
          </tr>
          <tr>
            <td><span className="darkRed">FTT0011</span></td>
            <td>FTT application</td>
            <td>75% Complete</td>
            <td><span className="purple">Select to view, edit or submit</span></td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
  const submittedContents = (
    <div>
      * Here are your submitted application that are awaiting a final decision. If an application is not showing below check the In progress or Decided tables.
      <Table striped fullWidth>
        <thead>
          <tr>
            <th scope="col">Application</th>
            <th scope="col">Type</th>
            <th scope="col">Status</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><span className="darkRed">FTT0005</span></td>
            <td>FTT application</td>
            <td>Submitted</td>
            <td><span className="purple">Select to view</span></td>
          </tr>
          <tr>
            <td><span className="darkRed">FTT0007</span></td>
            <td>FTT application</td>
            <td>In Review</td>
            <td><span className="purple">Select to view</span></td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
  const decidedContents = (
    <div>
      * Here are your decided applications. If an application is not showing below check the In progress or Submitted tables.
      <Table striped fullWidth>
        <thead>
          <tr>
            <th scope="col">Application</th>
            <th scope="col">Type</th>
            <th scope="col">Status</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><span className="darkRed">FTT0008</span></td>
            <td>FTT application</td>
            <td>Accepted</td>
            <td><span className="purple">Select to view</span></td>
          </tr>
          <tr>
            <td><span className="darkRed">FTT0012</span></td>
            <td>FTT application</td>
            <td>Invoiced</td>
            <td><span className="purple">Select to view</span></td>
          </tr>
        </tbody>
      </Table>
    </div>
  )

  const accordionItems: AccordionItemProps[] = [
    {
      title: 'Start a New Application',
      content: newAppContents,
      expanded: true,
      id: 'newApp',
      headingLevel: 'h1'
    },
    {
      title: 'In progress Applications',
      content: inProgressContents,
      expanded: true,
      id: 'inProgress',
      headingLevel: 'h1'
    },
    {
      title: 'Submitted Applications',
      content: submittedContents,
      expanded: true,
      id: 'submitted',
      headingLevel: 'h1'
    },
    {
      title: 'Decided Applications',
      content: decidedContents,
      expanded: true,
      id: 'decided',
      headingLevel: 'h1'
    },
  ];



  return (
    <>
      <div className="home-header">
        <div className="home-header-links">
          <a className="home-header-link usa-footer__primary-link text-with-background" href="#">
            <span>Welcome</span>
          </a>
          <span className="text-with-background">|</span>
          <a className="home-header-link usa-footer__primary-link text-with-background" href="#">
            <span className="text-with-background">My Dashboard</span>
          </a>
          <span className="text-with-background">|</span>
          <a className="home-header-link usa-footer__primary-link text-with-background" href="#">
            <span className="text-with-background">Access Requests</span>
          </a>
          <span className="text-with-background">|</span>
          <a className="home-header-link usa-footer__primary-link text-with-background" href="#">
            <span className="text-with-background">Web Site Guide</span>
          </a>
          <span className="text-with-background">|</span>
          <a className="home-header-link usa-footer__primary-link text-with-background" href="/taap-core/logout">
            <span className="text-with-background">Logout</span>
          </a>
        </div>
      </div>
      <div id="home-body" style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
        <Title>My Dashboard</Title>
        <div style={{ paddingLeft: '1rem' }}>* Use the sections below	to submit, save	and	monitor	applications.</div>
        <div style={{ paddingLeft: '1rem',  paddingBottom: '1rem'}}>* Each section is collapsible. Select a collapsed section to show its contents.</div>
        <Accordion items={accordionItems} multiselectable={true} bordered={true} />
      </div>
    </>
  );
};


export default Home;

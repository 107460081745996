import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { GridContainer, Grid, Button, Fieldset, Form, Label, TextInput, ErrorMessage } from '@trussworks/react-uswds';

import './portalLogin.scss';
import '../../../common/portal-standards.scss';
import { useAppDispatch, useAppSelector } from 'ui/config/store';
import { submitFromPreProdWall } from 'ui/modules/common/reducers/authentication';
import { Navigate } from 'react-router-dom';


// This is a screen with a single password entry field. The purpose is to keep the general public out while this application is still in dev and uat
export const PortalPreProdWall = () => {

  const [showPassword, setShowPassword] = React.useState(false);

  const [loginPassword, setLoginPassword] = React.useState('');

  const taapenv = useAppSelector(state => state.authentication.taapenv);
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const authErrorMessage = useAppSelector(state => state.authentication.authErrorMessage);
  const isAllowedPastWall = useAppSelector(state => state.authentication.isAllowedPastWall);


  const handleEntry = (event) => {
    switch (event.target.id) {
      case 'password-field': setLoginPassword(event.target.value); return;
    }; return;
  };


  const handleEnterClick = (e) => {
    e.preventDefault();
    const data = { password: loginPassword };
    dispatch(submitFromPreProdWall(data));
    return;
  };


  if (isAllowedPastWall) {
    return (<Navigate
      to={{
        pathname: '/pc/home',
        search: location.search,
      }}
      replace={false}
      state={{ from: location }}
    />
    );
  }
  return (
    <>
      <Helmet><title>TAAP : Trust Asset Assistance Portal</title></Helmet>
      <main className='.portal-main'>
        <div className="bg-base-lightest">
          <GridContainer className="usa-section">
            <Grid
              row={true}
              className="margin-x-neg-205 margin-bottom-7 flex-justify-center">
              <Grid col={12} mobileLg={{ col: 10 }} tablet={{ col: 8 }} desktop={{ col: 6 }} className="padding-x-205 margin-bottom-7">

                <h1 className="desktop:display-none font-sans-lg margin-bottom-4 tablet:margin-top-neg-3"></h1>
                <div className="bg-white padding-y-3 padding-x-5 border border-base-lighter">
                  <h1 className="margin-bottom-0">Trust Asset Assistance Portal</h1>
                  <br /><br />
                  <Form onSubmit={((event) => { })}>
                    <Fieldset legend="">
                      {authErrorMessage && <ErrorMessage>{authErrorMessage}</ErrorMessage>}
                      <Label htmlFor="password-field">Password:{' '}</Label>
                      <TextInput id="password-field" name="password-field" value={loginPassword}
                        onChange={handleEntry} type={showPassword ? 'text' : 'password'}
                        autoCapitalize="off" autoCorrect="off" required={true} aria-required="true" />

                      <p className="usa-form__note">
                        <a title="Show password" href="javascript:void(0);" className="usa-show-password"
                          aria-controls="password-create-account password-create-account-confirm"
                          onClick={(): void => setShowPassword((showPassword) => !showPassword)}>
                          {showPassword ? 'Hide password' : 'Show password'}
                        </a>
                      </p>
                      <Button type="button" onClick={handleEnterClick}>Enter</Button>
                    </Fieldset>
                  </Form>
                </div>
              </Grid>

            </Grid>
          </GridContainer>
        </div>
      </main></>);
};
export default PortalPreProdWall;
export const stateCodesMap = {
  stateCodeObjects: [
    { "code": "AL", "label": "ALABAMA" }
    , { "code": "AK", "label": "ALASKA" }
    , { "code": "AZ", "label": "ARIZONA" }
    , { "code": "AR", "label": "ARKANSAS" }
    , { "code": "AE", "label": "ARMED FORCES" }
    , { "code": "AA", "label": "ARMED FORCES AMERICA" }
    , { "code": "AP", "label": "ARMY/AIR PO" }
    , { "code": "CA", "label": "CALIFORNIA" }
    , { "code": "CO", "label": "COLORADO" }
    , { "code": "CT", "label": "CONNECTICUT" }
    , { "code": "DE", "label": "DELAWARE" }
    , { "code": "DC", "label": "DIST OF COL" }
    , { "code": "FL", "label": "FLORIDA" }
    , { "code": "GA", "label": "GEORGIA" }
    , { "code": "HI", "label": "HAWAII" }
    , { "code": "ID", "label": "IDAHO" }
    , { "code": "IL", "label": "ILLINOIS" }
    , { "code": "IN", "label": "INDIANA" }
    , { "code": "IA", "label": "IOWA" }
    , { "code": "KS", "label": "KANSAS" }
    , { "code": "KY", "label": "KENTUCKY" }
    , { "code": "LA", "label": "LOUISIANA" }
    , { "code": "ME", "label": "MAINE" }
    , { "code": "MD", "label": "MARYLAND" }
    , { "code": "MA", "label": "MASSACHUSETTS" }
    , { "code": "MI", "label": "MICHIGAN" }
    , { "code": "MN", "label": "MINNESOTA" }
    , { "code": "MS", "label": "MISSISSIPPI" }
    , { "code": "MO", "label": "MISSOURI" }
    , { "code": "MT", "label": "MONTANA" }
    , { "code": "NE", "label": "NEBRASKA" }
    , { "code": "NV", "label": "NEVADA" }
    , { "code": "NH", "label": "NEW HAMPSHIRE" }
    , { "code": "NJ", "label": "NEW JERSEY" }
    , { "code": "NM", "label": "NEW MEXICO" }
    , { "code": "NY", "label": "NEW YORK" }
    , { "code": "NC", "label": "NORTH CAROLINA" }
    , { "code": "ND", "label": "NORTH DAKOTA" }
    , { "code": "OH", "label": "OHIO" }
    , { "code": "OK", "label": "OKLAHOMA" }
    , { "code": "OR", "label": "OREGON" }
    , { "code": "PA", "label": "PENNSYLVANIA" }
    , { "code": "RI", "label": "RHODE ISLAND" }
    , { "code": "SC", "label": "SOUTH CAROLINA" }
    , { "code": "SD", "label": "SOUTH DAKOTA" }
    , { "code": "TN", "label": "TENNESSEE" }
    , { "code": "TX", "label": "TEXAS" }
    , { "code": "UT", "label": "UTAH" }
    , { "code": "VT", "label": "VERMONT" }
    , { "code": "VA", "label": "VIRGINIA" }
    , { "code": "WA", "label": "WASHINGTON" }
    , { "code": "WV", "label": "WEST VIRGINIA" }
    , { "code": "WI", "label": "WISCONSIN" }
    , { "code": "WY", "label": "WYOMING" }
  ]
}

export const countiesByStateMap = {
  "AK": [
    { "code": "501", "label": "ALEUTIAN ISLAND" }
    , { "code": "502", "label": "ANCHORAGE" }
    , { "code": "985", "label": "ARCTIC SLOPE" }
    , { "code": "503", "label": "BARROW" }
    , { "code": "504", "label": "BETHEL" }
    , { "code": "505", "label": "BRISTOL BAY" }
    , { "code": "506", "label": "CAPE NOME" }
    , { "code": "507", "label": "CHITINA" }
    , { "code": "508", "label": "CORDOVA" }
    , { "code": "986", "label": "CALISTA" }
    , { "code": "975", "label": "COOK INLET" }
    , { "code": "509", "label": "FAIRBANKS" }
    , { "code": "510", "label": "FORT GIBBON" }
    , { "code": "511", "label": "HAINES" }
    , { "code": "512", "label": "HOMER" }
    , { "code": "513", "label": "ILIAMNA" }
    , { "code": "514", "label": "JUNEAU" }
    , { "code": "515", "label": "KENAI" }
    , { "code": "516", "label": "KETCHIKAN" }
    , { "code": "517", "label": "KODIAK" }
    , { "code": "518", "label": "KOTZEBUE" }
    , { "code": "519", "label": "KUSKOKWIM" }
    , { "code": "520", "label": "KVICHAK" }
    , { "code": "521", "label": "MANLEY HOT SPRI" }
    , { "code": "522", "label": "MT. MCKINLEY" }
    , { "code": "523", "label": "NENANA" }
    , { "code": "524", "label": "NULATO" }
    , { "code": "525", "label": "PALMER" }
    , { "code": "526", "label": "PETERSBURG" }
    , { "code": "527", "label": "RAMPART" }
    , { "code": "528", "label": "SELDOVIA" }
    , { "code": "529", "label": "SEWARD" }
    , { "code": "530", "label": "SITKA" }
    , { "code": "531", "label": "SKAGWAY" }
    , { "code": "532", "label": "TALKEETNA" }
    , { "code": "533", "label": "VALDEZ" }
    , { "code": "534", "label": "WRANGELL" }
  ],
  "AL": [
    { "code": "001", "label": "AUTAUGA" }
    , { "code": "003", "label": "BALDWIN" }
    , { "code": "203", "label": "BALDWIN STATE W" }
    , { "code": "005", "label": "BARBOUR" }
    , { "code": "007", "label": "BIBB" }
    , { "code": "009", "label": "BLOUNT" }
    , { "code": "011", "label": "BULLOCK" }
    , { "code": "013", "label": "BUTLER" }
    , { "code": "015", "label": "CALHOUN" }
    , { "code": "017", "label": "CHAMBERS" }
    , { "code": "019", "label": "CHEROKEE" }
    , { "code": "021", "label": "CHILTON" }
    , { "code": "023", "label": "CHOCTAW" }
    , { "code": "025", "label": "CLARKE" }
    , { "code": "027", "label": "CLAY" }
    , { "code": "029", "label": "CLEBURNE" }
    , { "code": "031", "label": "COFFEE" }
    , { "code": "033", "label": "COLBERT" }
    , { "code": "035", "label": "CONECUH" }
    , { "code": "037", "label": "COOSA" }
    , { "code": "039", "label": "COVINGTON" }
    , { "code": "041", "label": "CRENSHAW" }
    , { "code": "043", "label": "CULLMAN" }
    , { "code": "045", "label": "DALE" }
    , { "code": "047", "label": "DALLAS" }
    , { "code": "049", "label": "DE KALB" }
    , { "code": "051", "label": "ELMORE" }
    , { "code": "053", "label": "ESCAMBIA" }
    , { "code": "055", "label": "ETOWAH" }
    , { "code": "057", "label": "FAYETTE" }
    , { "code": "059", "label": "FRANKLIN" }
    , { "code": "061", "label": "GENEVA" }
    , { "code": "063", "label": "GREENE" }
    , { "code": "065", "label": "HALE" }
    , { "code": "067", "label": "HENRY" }
    , { "code": "069", "label": "HOUSTON" }
    , { "code": "071", "label": "JACKSON" }
    , { "code": "073", "label": "JEFFERSON" }
    , { "code": "075", "label": "LAMAR" }
    , { "code": "077", "label": "LAUDERDALE" }
    , { "code": "079", "label": "LAWRENCE" }
    , { "code": "081", "label": "LEE" }
    , { "code": "083", "label": "LIMESTONE" }
    , { "code": "085", "label": "LOWNDES" }
    , { "code": "087", "label": "MACON" }
    , { "code": "089", "label": "MADISON" }
    , { "code": "091", "label": "MARENGO" }
    , { "code": "093", "label": "MARION" }
    , { "code": "095", "label": "MARSHALL" }
    , { "code": "097", "label": "MOBILE" }
    , { "code": "297", "label": "MOBILE STATE WA" }
    , { "code": "099", "label": "MONROE" }
    , { "code": "101", "label": "MONTGOMERY" }
    , { "code": "103", "label": "MORGAN" }
    , { "code": "105", "label": "PERRY" }
    , { "code": "107", "label": "PICKENS" }
    , { "code": "109", "label": "PIKE" }
    , { "code": "111", "label": "RANDOLPH" }
    , { "code": "113", "label": "RUSSELL" }
    , { "code": "117", "label": "SHELBY" }
    , { "code": "115", "label": "ST. CLAIR" }
    , { "code": "119", "label": "SUMTER" }
    , { "code": "121", "label": "TALLADEGA" }
    , { "code": "123", "label": "TALLAPOOSA" }
    , { "code": "125", "label": "TUSCALOOSA" }
    , { "code": "127", "label": "WALKER" }
    , { "code": "129", "label": "WASHINGTON" }
    , { "code": "131", "label": "WILCOX" }
    , { "code": "133", "label": "WINSTON" }
  ],
  "AR": [
    { "code": "001", "label": "ARKANSAS" }
    , { "code": "003", "label": "ASHLEY" }
    , { "code": "005", "label": "BAXTER" }
    , { "code": "007", "label": "BENTON" }
    , { "code": "009", "label": "BOONE" }
    , { "code": "011", "label": "BRADLEY" }
    , { "code": "013", "label": "CALHOUN" }
    , { "code": "015", "label": "CARROLL" }
    , { "code": "017", "label": "CHICOT" }
    , { "code": "019", "label": "CLARK" }
    , { "code": "021", "label": "CLAY" }
    , { "code": "023", "label": "CLEBURNE" }
    , { "code": "025", "label": "CLEVELAND" }
    , { "code": "027", "label": "COLUMBIA" }
    , { "code": "029", "label": "CONWAY" }
    , { "code": "031", "label": "CRAIGHEAD" }
    , { "code": "033", "label": "CRAWFORD" }
    , { "code": "035", "label": "CRITTENDEN" }
    , { "code": "037", "label": "CROSS" }
    , { "code": "039", "label": "DALLAS" }
    , { "code": "041", "label": "DESHA" }
    , { "code": "043", "label": "DREW" }
    , { "code": "045", "label": "FAULKNER" }
    , { "code": "047", "label": "FRANKLIN" }
    , { "code": "049", "label": "FULTON" }
    , { "code": "051", "label": "GARLAND" }
    , { "code": "053", "label": "GRANT" }
    , { "code": "055", "label": "GREENE" }
    , { "code": "057", "label": "HEMPSTEAD" }
    , { "code": "059", "label": "HOT SPRING" }
    , { "code": "061", "label": "HOWARD" }
    , { "code": "063", "label": "INDEPENDENCE" }
    , { "code": "065", "label": "IZARD" }
    , { "code": "067", "label": "JACKSON" }
    , { "code": "069", "label": "JEFFERSON" }
    , { "code": "071", "label": "JOHNSON" }
    , { "code": "073", "label": "LAFAYETTE" }
    , { "code": "075", "label": "LAWRENCE" }
    , { "code": "077", "label": "LEE" }
    , { "code": "079", "label": "LINCOLN" }
    , { "code": "081", "label": "LITTLE RIVER" }
    , { "code": "083", "label": "LOGAN" }
    , { "code": "085", "label": "LONOKE" }
    , { "code": "087", "label": "MADISON" }
    , { "code": "089", "label": "MARION" }
    , { "code": "091", "label": "MILLER" }
    , { "code": "093", "label": "MISSISSIPPI" }
    , { "code": "095", "label": "MONROE" }
    , { "code": "097", "label": "MONTGOMERY" }
    , { "code": "099", "label": "NEVADA" }
    , { "code": "101", "label": "NEWTON" }
    , { "code": "103", "label": "OUACHITA" }
    , { "code": "105", "label": "PERRY" }
    , { "code": "107", "label": "PHILLIPPS" }
    , { "code": "109", "label": "PIKE" }
    , { "code": "111", "label": "POINSETT" }
    , { "code": "113", "label": "POLK" }
    , { "code": "115", "label": "POPE" }
    , { "code": "117", "label": "PRAIRIE" }
    , { "code": "119", "label": "PULASKI" }
    , { "code": "121", "label": "RANDOLPH" }
    , { "code": "125", "label": "SALINE" }
    , { "code": "127", "label": "SCOTT" }
    , { "code": "129", "label": "SEARCY" }
    , { "code": "131", "label": "SEBASTIAN" }
    , { "code": "133", "label": "SEVIER" }
    , { "code": "135", "label": "SHARP" }
    , { "code": "123", "label": "ST FRANCIS" }
    , { "code": "137", "label": "STONE" }
    , { "code": "139", "label": "UNION" }
    , { "code": "141", "label": "VAN BUREN" }
    , { "code": "143", "label": "WASHINGTON" }
    , { "code": "145", "label": "WHITE" }
    , { "code": "147", "label": "WOODRUFF" }
    , { "code": "149", "label": "YELL" }
  ],
  "AZ": [
    { "code": "001", "label": "APACHE" }
    , { "code": "A22", "label": "CNTY" }
    , { "code": "003", "label": "COCHISE" }
    , { "code": "005", "label": "COCONINO" }
    , { "code": "007", "label": "GILA" }
    , { "code": "009", "label": "GRAHAM" }
    , { "code": "011", "label": "GREENLEE" }
    , { "code": "012", "label": "LA PAZ" }
    , { "code": "013", "label": "MARICOPA" }
    , { "code": "015", "label": "MOHAVE" }
    , { "code": "017", "label": "NAVAJO" }
    , { "code": "019", "label": "PIMA" }
    , { "code": "021", "label": "PINAL" }
    , { "code": "023", "label": "SANTA CRUZ" }
    , { "code": "025", "label": "YAVAPAI" }
    , { "code": "027", "label": "YUMA" }
  ],
  "CA": [
    { "code": "001", "label": "ALAMEDA" }
    , { "code": "201", "label": "ALAMEDA STATE W" }
    , { "code": "003", "label": "ALPINE" }
    , { "code": "005", "label": "AMADOR" }
    , { "code": "116", "label": "ANACAPA ISLAND" }
    , { "code": "216", "label": "ANACAPA STATE W" }
    , { "code": "007", "label": "BUTTE" }
    , { "code": "009", "label": "CALAVERAS" }
    , { "code": "002", "label": "CANADA" }
    , { "code": "011", "label": "COLUSA" }
    , { "code": "013", "label": "CONTRA COSTA" }
    , { "code": "213", "label": "CONTRA COSTA ST" }
    , { "code": "015", "label": "DEL NORTE" }
    , { "code": "215", "label": "DEL NORTE STATE" }
    , { "code": "017", "label": "EL DORADO" }
    , { "code": "019", "label": "FRESNO" }
    , { "code": "021", "label": "GLENN" }
    , { "code": "023", "label": "HUMBOLDT" }
    , { "code": "223", "label": "HUMBOLDT STATE" }
    , { "code": "025", "label": "IMPERIAL" }
    , { "code": "027", "label": "INYO" }
    , { "code": "029", "label": "KERN" }
    , { "code": "031", "label": "KINGS" }
    , { "code": "033", "label": "LAKE" }
    , { "code": "035", "label": "LASSEN" }
    , { "code": "037", "label": "LOS ANGELES" }
    , { "code": "237", "label": "LOS ANGELES STA" }
    , { "code": "039", "label": "MADERA" }
    , { "code": "041", "label": "MARIN" }
    , { "code": "241", "label": "MARIN STATE WAT" }
    , { "code": "043", "label": "MARIPOSA" }
    , { "code": "245", "label": "MEDOCINA STATE" }
    , { "code": "045", "label": "MENDOCINO" }
    , { "code": "047", "label": "MERCED" }
    , { "code": "049", "label": "MODOC" }
    , { "code": "051", "label": "MONO" }
    , { "code": "053", "label": "MONTEREY" }
    , { "code": "253", "label": "MONTEREY STATE" }
    , { "code": "055", "label": "NAPA" }
    , { "code": "255", "label": "NAPA STATE WATE" }
    , { "code": "057", "label": "NEVADA" }
    , { "code": "059", "label": "ORANGE" }
    , { "code": "259", "label": "ORANGE STATE WA" }
    , { "code": "777", "label": "OFFSHORE" }
    , { "code": "061", "label": "PLACER" }
    , { "code": "063", "label": "PLUMAS" }
    , { "code": "065", "label": "RIVERSIDE" }
    , { "code": "067", "label": "SACRAMENTO" }
    , { "code": "069", "label": "SAN BENITO" }
    , { "code": "071", "label": "SAN BERNARDINO" }
    , { "code": "118", "label": "SAN CLEMENTE IS" }
    , { "code": "218", "label": "SAN CLEMENTE ST" }
    , { "code": "073", "label": "SAN DIEGO" }
    , { "code": "273", "label": "SAN DIEGO STATE" }
    , { "code": "075", "label": "SAN FRANCISCO" }
    , { "code": "275", "label": "SAN FRANCISCO S" }
    , { "code": "077", "label": "SAN JOAQUIN" }
    , { "code": "279", "label": "SAN LUIS OBISPO" }
    , { "code": "079", "label": "SAN LUIS OBISPO" }
    , { "code": "081", "label": "SAN MATEO" }
    , { "code": "281", "label": "SAN MATEO STATE" }
    , { "code": "120", "label": "SAN MIGUEL ISLA" }
    , { "code": "220", "label": "SAN MIGUEL STAT" }
    , { "code": "313", "label": "SAN NICOLAS ARE" }
    , { "code": "122", "label": "SAN NICOLAS ISL" }
    , { "code": "222", "label": "SAN NICOLAS STA" }
    , { "code": "083", "label": "SANTA BARBARA -" }
    , { "code": "124", "label": "SANTA BARBARA I" }
    , { "code": "224", "label": "SANTA BARBARA S" }
    , { "code": "283", "label": "SANTA BARBARA S" }
    , { "code": "312", "label": "SANTA CATALINA" }
    , { "code": "226", "label": "SANTA CATALINA" }
    , { "code": "126", "label": "SANTA CATALINA" }
    , { "code": "085", "label": "SANTA CLARA" }
    , { "code": "087", "label": "SANTA CRUZ - PR" }
    , { "code": "311", "label": "SANTA CRUZ AREA" }
    , { "code": "128", "label": "SANTA CRUZ ISLA" }
    , { "code": "287", "label": "SANTA CRUZ STAT" }
    , { "code": "228", "label": "SANTA CRUZ STAT" }
    , { "code": "130", "label": "SANTA ROSA ISLA" }
    , { "code": "230", "label": "SANTA ROSA STAT" }
    , { "code": "089", "label": "SHASTA" }
    , { "code": "091", "label": "SIERRA" }
    , { "code": "093", "label": "SISKIYOU" }
    , { "code": "095", "label": "SOLANO" }
    , { "code": "295", "label": "SOLANO STATE WA" }
    , { "code": "097", "label": "SONOMA" }
    , { "code": "297", "label": "SONOMA STATE WA" }
    , { "code": "099", "label": "STANISLAUS" }
    , { "code": "101", "label": "SUTTER" }
    , { "code": "103", "label": "TEHAMA" }
    , { "code": "105", "label": "TRINITY" }
    , { "code": "107", "label": "TULARE" }
    , { "code": "109", "label": "TUOLUMNE" }
    , { "code": "111", "label": "VENTURA" }
    , { "code": "211", "label": "VENTURA STATE W" }
    , { "code": "113", "label": "YOLO" }
    , { "code": "115", "label": "YUBA" }
  ],
  "CO": [
    { "code": "001", "label": "ADAMS" }
    , { "code": "003", "label": "ALAMOSA" }
    , { "code": "005", "label": "ARAPAHOE" }
    , { "code": "007", "label": "ARCHULETA" }
    , { "code": "009", "label": "BACA" }
    , { "code": "011", "label": "BENT" }
    , { "code": "013", "label": "BOULDER" }
    , { "code": "015", "label": "CHAFFEE" }
    , { "code": "017", "label": "CHEYENNE" }
    , { "code": "019", "label": "CLEAR CREEK" }
    , { "code": "021", "label": "CONEJOS" }
    , { "code": "023", "label": "COSTILLA" }
    , { "code": "025", "label": "CROWLEY" }
    , { "code": "027", "label": "CUSTER" }
    , { "code": "029", "label": "DELTA" }
    , { "code": "031", "label": "DENVER" }
    , { "code": "033", "label": "DOLORES" }
    , { "code": "035", "label": "DOUGLAS" }
    , { "code": "037", "label": "EAGLE" }
    , { "code": "041", "label": "EL PASO" }
    , { "code": "039", "label": "ELBERT" }
    , { "code": "043", "label": "FREMONT" }
    , { "code": "045", "label": "GARFIELD" }
    , { "code": "047", "label": "GILPIN" }
    , { "code": "049", "label": "GRAND" }
    , { "code": "051", "label": "GUNNISON" }
    , { "code": "053", "label": "HINSDALE" }
    , { "code": "055", "label": "HUERFANO" }
    , { "code": "057", "label": "JACKSON" }
    , { "code": "059", "label": "JEFFERSON" }
    , { "code": "061", "label": "KIOWA" }
    , { "code": "063", "label": "KIT CARSON" }
    , { "code": "067", "label": "LA PLATA" }
    , { "code": "065", "label": "LAKE" }
    , { "code": "069", "label": "LARIMER" }
    , { "code": "071", "label": "LAS ANIMAS" }
    , { "code": "073", "label": "LINCOLN" }
    , { "code": "075", "label": "LOGAN" }
    , { "code": "077", "label": "MESA" }
    , { "code": "079", "label": "MINERAL" }
    , { "code": "081", "label": "MOFFAT" }
    , { "code": "083", "label": "MONTEZUMA" }
    , { "code": "085", "label": "MONTROSE" }
    , { "code": "087", "label": "MORGAN" }
    , { "code": "089", "label": "OTERO" }
    , { "code": "091", "label": "OURAY" }
    , { "code": "093", "label": "PARK" }
    , { "code": "095", "label": "PHILLIPS" }
    , { "code": "097", "label": "PITKIN" }
    , { "code": "099", "label": "PROWERS" }
    , { "code": "101", "label": "PUEBLO" }
    , { "code": "103", "label": "RIO BLANCO" }
    , { "code": "105", "label": "RIO GRANDE" }
    , { "code": "107", "label": "ROUTT" }
    , { "code": "109", "label": "SAGUACHE" }
    , { "code": "111", "label": "SAN JUAN" }
    , { "code": "113", "label": "SAN MIGUEL" }
    , { "code": "115", "label": "SEDGWICK" }
    , { "code": "117", "label": "SUMMIT" }
    , { "code": "119", "label": "TELLER" }
    , { "code": "121", "label": "WASHINGTON" }
    , { "code": "123", "label": "WELD" }
    , { "code": "125", "label": "YUMA" }
  ],
  "CT": [
    { "code": "001", "label": "FAIRFIELD" }
    , { "code": "201", "label": "FAIRFIELD STATE" }
    , { "code": "003", "label": "HARTFORD" }
    , { "code": "005", "label": "LITCHFIELD" }
    , { "code": "007", "label": "MIDDLESEX" }
    , { "code": "207", "label": "MIDDLESEX STATE" }
    , { "code": "009", "label": "NEW HAVEN" }
    , { "code": "209", "label": "NEW HAVEN STATE" }
    , { "code": "011", "label": "NEW LONDON" }
    , { "code": "211", "label": "NEW LONDON STAT" }
    , { "code": "013", "label": "TOLLAND" }
    , { "code": "015", "label": "WINDHAM" }
  ],
  "DC": [
    { "code": "001", "label": "DIST OF COL" }
  ],
  "DE": [
    { "code": "001", "label": "KENT" }
    , { "code": "003", "label": "NEW CASTLE" }
    , { "code": "777", "label": "OFFSHORE" }
    , { "code": "005", "label": "SUSSEX" }
    , { "code": "205", "label": "SUSSEX STATE WA" }
  ],
  "FL": [
    { "code": "001", "label": "ALACHUA" }
    , { "code": "003", "label": "BAKER" }
    , { "code": "005", "label": "BAY" }
    , { "code": "205", "label": "BAY STATE WATER" }
    , { "code": "007", "label": "BRADFORD" }
    , { "code": "009", "label": "BREVARD" }
    , { "code": "209", "label": "BREVARD STATE W" }
    , { "code": "011", "label": "BROWARD" }
    , { "code": "211", "label": "BROWARD STATE W" }
    , { "code": "013", "label": "CALHOUN" }
    , { "code": "015", "label": "CHARLOTTE" }
    , { "code": "215", "label": "CHARLOTTE STATE" }
    , { "code": "017", "label": "CITRUS" }
    , { "code": "217", "label": "CITRUS STATE WA" }
    , { "code": "019", "label": "CLAY" }
    , { "code": "021", "label": "COLLIER" }
    , { "code": "221", "label": "COLLIER STATE W" }
    , { "code": "023", "label": "COLUMBIA" }
    , { "code": "025", "label": "DADE" }
    , { "code": "225", "label": "DADE STATE WATE" }
    , { "code": "027", "label": "DE SOTO" }
    , { "code": "029", "label": "DIXIE" }
    , { "code": "229", "label": "DIXIE STATE WAT" }
    , { "code": "031", "label": "DUVAL" }
    , { "code": "231", "label": "DUVAL STATE WAT" }
    , { "code": "033", "label": "ESCAMBIA" }
    , { "code": "233", "label": "ESCAMBIA STATE" }
    , { "code": "035", "label": "FLAGLER" }
    , { "code": "235", "label": "FLAGLER STATE W" }
    , { "code": "037", "label": "FRANKLIN" }
    , { "code": "237", "label": "FRANKLIN STATE" }
    , { "code": "039", "label": "GADSDEN" }
    , { "code": "041", "label": "GILCHRIST" }
    , { "code": "043", "label": "GLADES" }
    , { "code": "045", "label": "GULF" }
    , { "code": "245", "label": "GULF STATE WATE" }
    , { "code": "047", "label": "HAMILTON" }
    , { "code": "049", "label": "HARDEE" }
    , { "code": "051", "label": "HENDRY" }
    , { "code": "053", "label": "HERNANDO" }
    , { "code": "253", "label": "HERNANDO STATE" }
    , { "code": "055", "label": "HIGHLANDS" }
    , { "code": "057", "label": "HILLSBOROUGH" }
    , { "code": "257", "label": "HILLSBOROUGH ST" }
    , { "code": "059", "label": "HOLMES" }
    , { "code": "061", "label": "INDIAN RIVER" }
    , { "code": "261", "label": "INDIAN RIVER ST" }
    , { "code": "063", "label": "JACKSON" }
    , { "code": "065", "label": "JEFFERSON" }
    , { "code": "265", "label": "JEFFERSON STATE" }
    , { "code": "067", "label": "LAFAYETTE" }
    , { "code": "069", "label": "LAKE" }
    , { "code": "071", "label": "LEE" }
    , { "code": "271", "label": "LEE STATE WATER" }
    , { "code": "073", "label": "LEON" }
    , { "code": "075", "label": "LEVY" }
    , { "code": "275", "label": "LEVY STATE WATE" }
    , { "code": "077", "label": "LIBERTY" }
    , { "code": "079", "label": "MADISON" }
    , { "code": "081", "label": "MANATEE" }
    , { "code": "281", "label": "MANATEE STATE W" }
    , { "code": "083", "label": "MARION" }
    , { "code": "085", "label": "MARTIN" }
    , { "code": "285", "label": "MARTIN STATE WA" }
    , { "code": "087", "label": "MONROE" }
    , { "code": "287", "label": "MONROE STATE WA" }
    , { "code": "089", "label": "NASSAU" }
    , { "code": "289", "label": "NASSAU STATE WA" }
    , { "code": "091", "label": "OKALOOSA" }
    , { "code": "291", "label": "OKALOOSA STATE" }
    , { "code": "093", "label": "OKEECHOBEE" }
    , { "code": "095", "label": "ORANGE" }
    , { "code": "097", "label": "OSCEOLA" }
    , { "code": "099", "label": "PALM BEACH" }
    , { "code": "299", "label": "PALM BEACH STAT" }
    , { "code": "101", "label": "PASCO" }
    , { "code": "301", "label": "PASCO STATE WAT" }
    , { "code": "103", "label": "PINELLAS" }
    , { "code": "303", "label": "PINELLAS STATE" }
    , { "code": "105", "label": "POLK" }
    , { "code": "107", "label": "PUTNAM" }
    , { "code": "113", "label": "SANTA ROSA" }
    , { "code": "313", "label": "SANTA ROSA STAT" }
    , { "code": "115", "label": "SARASOTA" }
    , { "code": "315", "label": "SARASOTA STATE" }
    , { "code": "117", "label": "SEMINOLE" }
    , { "code": "111", "label": "ST LUCIE" }
    , { "code": "311", "label": "ST LUCIE STATE" }
    , { "code": "109", "label": "ST. JOHNS" }
    , { "code": "309", "label": "ST. JOHNS STATE" }
    , { "code": "119", "label": "SUMTER" }
    , { "code": "121", "label": "SUWANNEE" }
    , { "code": "123", "label": "TAYLOR" }
    , { "code": "323", "label": "TAYLOR STATE WA" }
    , { "code": "125", "label": "UNION" }
    , { "code": "127", "label": "VOLUSIA" }
    , { "code": "327", "label": "VOLUSIA STATE W" }
    , { "code": "129", "label": "WAKULLA" }
    , { "code": "329", "label": "WAKULLA STATE W" }
    , { "code": "131", "label": "WALTON" }
    , { "code": "331", "label": "WALTON STATE WA" }
    , { "code": "133", "label": "WASHINGTON" }
  ],
  "GA": [
    { "code": "001", "label": "APPLING" }
    , { "code": "003", "label": "ATKINSON" }
    , { "code": "005", "label": "BACON" }
    , { "code": "007", "label": "BAKER" }
    , { "code": "009", "label": "BALDWIN" }
    , { "code": "011", "label": "BANKS" }
    , { "code": "013", "label": "BARROW" }
    , { "code": "015", "label": "BARTOW" }
    , { "code": "017", "label": "BEN HILL" }
    , { "code": "019", "label": "BERRIEN" }
    , { "code": "021", "label": "BIBB" }
    , { "code": "023", "label": "BLECKLEY" }
    , { "code": "025", "label": "BRANTLEY" }
    , { "code": "027", "label": "BROOKS" }
    , { "code": "029", "label": "BRYAN" }
    , { "code": "329", "label": "BRYAN STATE WAT" }
    , { "code": "031", "label": "BULLOCH" }
    , { "code": "033", "label": "BURKE" }
    , { "code": "035", "label": "BUTTS" }
    , { "code": "037", "label": "CALHOUN" }
    , { "code": "039", "label": "CAMDEN" }
    , { "code": "339", "label": "CAMDEN STATE WA" }
    , { "code": "043", "label": "CANDLER" }
    , { "code": "045", "label": "CARROLL" }
    , { "code": "047", "label": "CATOOSA" }
    , { "code": "049", "label": "CHARLTON" }
    , { "code": "051", "label": "CHATHAM" }
    , { "code": "351", "label": "CHATHAM STATE W" }
    , { "code": "053", "label": "CHATTAHOOCHEE" }
    , { "code": "055", "label": "CHATTOOGA" }
    , { "code": "057", "label": "CHEROKEE" }
    , { "code": "059", "label": "CLARKE" }
    , { "code": "061", "label": "CLAY" }
    , { "code": "063", "label": "CLAYTON" }
    , { "code": "065", "label": "CLINCH" }
    , { "code": "067", "label": "COBB" }
    , { "code": "069", "label": "COFFEE" }
    , { "code": "071", "label": "COLQUITT" }
    , { "code": "073", "label": "COLUMBIA" }
    , { "code": "075", "label": "COOK" }
    , { "code": "077", "label": "COWETA" }
    , { "code": "079", "label": "CRAWFORD" }
    , { "code": "081", "label": "CRISP" }
    , { "code": "083", "label": "DADE" }
    , { "code": "085", "label": "DAWSON" }
    , { "code": "089", "label": "DE KALB" }
    , { "code": "087", "label": "DECATUR" }
    , { "code": "091", "label": "DODGE" }
    , { "code": "093", "label": "DOOLY" }
    , { "code": "095", "label": "DOUGHERTY" }
    , { "code": "097", "label": "DOUGLAS" }
    , { "code": "099", "label": "EARLY" }
    , { "code": "101", "label": "ECHOLS" }
    , { "code": "103", "label": "EFFINGHAM" }
    , { "code": "105", "label": "ELBERT" }
    , { "code": "107", "label": "EMANUEL" }
    , { "code": "109", "label": "EVANS" }
    , { "code": "111", "label": "FANNIN" }
    , { "code": "113", "label": "FAYETTE" }
    , { "code": "115", "label": "FLOYD" }
    , { "code": "117", "label": "FORSYTH" }
    , { "code": "119", "label": "FRANKLIN" }
    , { "code": "121", "label": "FULTON" }
    , { "code": "123", "label": "GILMER" }
    , { "code": "125", "label": "GLASCOCK" }
    , { "code": "127", "label": "GLYNN" }
    , { "code": "427", "label": "GLYNN STATE WAT" }
    , { "code": "129", "label": "GORDON" }
    , { "code": "131", "label": "GRADY" }
    , { "code": "133", "label": "GREENE" }
    , { "code": "135", "label": "GWINNETT" }
    , { "code": "137", "label": "HABERSHAM" }
    , { "code": "139", "label": "HALL" }
    , { "code": "141", "label": "HANCOCK" }
    , { "code": "143", "label": "HARALSON" }
    , { "code": "145", "label": "HARRIS" }
    , { "code": "147", "label": "HART" }
    , { "code": "149", "label": "HEARD" }
    , { "code": "151", "label": "HENRY" }
    , { "code": "153", "label": "HOUSTON" }
    , { "code": "155", "label": "IRWIN" }
    , { "code": "157", "label": "JACKSON" }
    , { "code": "159", "label": "JASPER" }
    , { "code": "161", "label": "JEFF DAVIS" }
    , { "code": "163", "label": "JEFFERSON" }
    , { "code": "165", "label": "JENKINS" }
    , { "code": "167", "label": "JOHNSON" }
    , { "code": "169", "label": "JONES" }
    , { "code": "171", "label": "LAMAR" }
    , { "code": "173", "label": "LANIER" }
    , { "code": "175", "label": "LAURENS" }
    , { "code": "177", "label": "LEE" }
    , { "code": "179", "label": "LIBERTY" }
    , { "code": "479", "label": "LIBERTY   STATE" }
    , { "code": "181", "label": "LINCOLN" }
    , { "code": "183", "label": "LONG" }
    , { "code": "185", "label": "LOWNDES" }
    , { "code": "187", "label": "LUMPKIN" }
    , { "code": "193", "label": "MACON" }
    , { "code": "195", "label": "MADISON" }
    , { "code": "197", "label": "MARION" }
    , { "code": "189", "label": "MC DUFFIE" }
    , { "code": "491", "label": "MC INTOSH" }
    , { "code": "191", "label": "MC INTOSH" }
    , { "code": "199", "label": "MERIWETHER" }
    , { "code": "201", "label": "MILLER" }
    , { "code": "205", "label": "MITCHELL" }
    , { "code": "207", "label": "MONROE" }
    , { "code": "209", "label": "MONTGOMERY" }
    , { "code": "211", "label": "MORGAN" }
    , { "code": "213", "label": "MURRAY" }
    , { "code": "215", "label": "MUSCOGEE" }
    , { "code": "217", "label": "NEWTON" }
    , { "code": "219", "label": "OCONEE" }
    , { "code": "221", "label": "OGLETHORPE" }
    , { "code": "223", "label": "PAULDING" }
    , { "code": "225", "label": "PEACH" }
    , { "code": "227", "label": "PICKENS" }
    , { "code": "229", "label": "PIERCE" }
    , { "code": "231", "label": "PIKE" }
    , { "code": "233", "label": "POLK" }
    , { "code": "235", "label": "PULASKI" }
    , { "code": "237", "label": "PUTNAM" }
    , { "code": "239", "label": "QUITMAN" }
    , { "code": "241", "label": "RABUN" }
    , { "code": "243", "label": "RANDOLPH" }
    , { "code": "245", "label": "RICHMOND" }
    , { "code": "247", "label": "ROCKDALE" }
    , { "code": "249", "label": "SCHLEY" }
    , { "code": "251", "label": "SCREVEN" }
    , { "code": "253", "label": "SEMINOLE" }
    , { "code": "255", "label": "SPALDING" }
    , { "code": "257", "label": "STEPHENS" }
    , { "code": "259", "label": "STEWART" }
    , { "code": "261", "label": "SUMTER" }
    , { "code": "263", "label": "TALBOT" }
    , { "code": "265", "label": "TALIAFERRO" }
    , { "code": "267", "label": "TATTNALL" }
    , { "code": "269", "label": "TAYLOR" }
    , { "code": "271", "label": "TELFAIR" }
    , { "code": "273", "label": "TERRELL" }
    , { "code": "275", "label": "THOMAS" }
    , { "code": "277", "label": "TIFT" }
    , { "code": "279", "label": "TOOMBS" }
    , { "code": "281", "label": "TOWNS" }
    , { "code": "283", "label": "TREUTLEN" }
    , { "code": "285", "label": "TROUP" }
    , { "code": "287", "label": "TURNER" }
    , { "code": "289", "label": "TWIGGS" }
    , { "code": "291", "label": "UNION" }
    , { "code": "293", "label": "UPSON" }
    , { "code": "295", "label": "WALKER" }
    , { "code": "297", "label": "WALTON" }
    , { "code": "299", "label": "WARE" }
    , { "code": "301", "label": "WARREN" }
    , { "code": "303", "label": "WASHINGTON" }
    , { "code": "305", "label": "WAYNE" }
    , { "code": "307", "label": "WEBSTER" }
    , { "code": "309", "label": "WHEELER" }
    , { "code": "311", "label": "WHITE" }
    , { "code": "313", "label": "WHITFIELD" }
    , { "code": "315", "label": "WILCOX" }
    , { "code": "317", "label": "WILKES" }
    , { "code": "319", "label": "WILKINSON" }
    , { "code": "321", "label": "WORTH" }
  ],
  "HI": [
    { "code": "010", "label": "HAWAII" }
    , { "code": "015", "label": "HONOLULU" }
    , { "code": "020", "label": "KALAWAO" }
    , { "code": "025", "label": "KAUAI" }
    , { "code": "030", "label": "MAUI" }
    , { "code": "035", "label": "MAUI" }
  ],
  "IA": [
    { "code": "001", "label": "ADAIR" }
    , { "code": "003", "label": "ADAMS" }
    , { "code": "005", "label": "ALLAMAKEE" }
    , { "code": "007", "label": "APPANOOSE" }
    , { "code": "009", "label": "AUDUBON" }
    , { "code": "011", "label": "BENTON" }
    , { "code": "013", "label": "BLACK HAWK" }
    , { "code": "015", "label": "BOONE" }
    , { "code": "017", "label": "BREMER" }
    , { "code": "019", "label": "BUCHANAN" }
    , { "code": "021", "label": "BUENA VISTA" }
    , { "code": "023", "label": "BUTLER" }
    , { "code": "025", "label": "CALHOUN" }
    , { "code": "027", "label": "CARROLL" }
    , { "code": "029", "label": "CASS" }
    , { "code": "031", "label": "CEDAR" }
    , { "code": "033", "label": "CERRO GORDO" }
    , { "code": "035", "label": "CHEROKEE" }
    , { "code": "037", "label": "CHICKASAW" }
    , { "code": "039", "label": "CLARKE" }
    , { "code": "041", "label": "CLAY" }
    , { "code": "043", "label": "CLAYTON" }
    , { "code": "045", "label": "CLINTON" }
    , { "code": "047", "label": "CRAWFORD" }
    , { "code": "049", "label": "DALLAS" }
    , { "code": "051", "label": "DAVIS" }
    , { "code": "053", "label": "DECATUR" }
    , { "code": "055", "label": "DELAWARE" }
    , { "code": "057", "label": "DES MOINES" }
    , { "code": "059", "label": "DICKINSON" }
    , { "code": "061", "label": "DUBUQUE" }
    , { "code": "063", "label": "EMMET" }
    , { "code": "065", "label": "FATETTE" }
    , { "code": "067", "label": "FLOYD" }
    , { "code": "069", "label": "FRANKLIN" }
    , { "code": "071", "label": "FREMONT" }
    , { "code": "073", "label": "GREENE" }
    , { "code": "075", "label": "GRUNDY" }
    , { "code": "077", "label": "GUTHRIE" }
    , { "code": "079", "label": "HAMILTON" }
    , { "code": "081", "label": "HANCOCK" }
    , { "code": "083", "label": "HARDIN" }
    , { "code": "085", "label": "HARRISON" }
    , { "code": "087", "label": "HENRY" }
    , { "code": "089", "label": "HOWARD" }
    , { "code": "091", "label": "HUMBOLDT" }
    , { "code": "093", "label": "IDA" }
    , { "code": "095", "label": "IOWA" }
    , { "code": "097", "label": "JACKSON" }
    , { "code": "099", "label": "JASPER" }
    , { "code": "101", "label": "JEFFERSON" }
    , { "code": "103", "label": "JOHNSON" }
    , { "code": "105", "label": "JONES" }
    , { "code": "107", "label": "KEOKUK" }
    , { "code": "109", "label": "KOSSUTH" }
    , { "code": "111", "label": "LEE" }
    , { "code": "113", "label": "LINN" }
    , { "code": "115", "label": "LOUISA" }
    , { "code": "117", "label": "LUCAS" }
    , { "code": "119", "label": "LYON" }
    , { "code": "121", "label": "MADISON" }
    , { "code": "123", "label": "MAHASKA" }
    , { "code": "125", "label": "MARION" }
    , { "code": "127", "label": "MARSHALL" }
    , { "code": "129", "label": "MILLS" }
    , { "code": "131", "label": "MITCHELL" }
    , { "code": "133", "label": "MONONA" }
    , { "code": "135", "label": "MONROE" }
    , { "code": "137", "label": "MONTGOMERY" }
    , { "code": "139", "label": "MUSCATINE" }
    , { "code": "141", "label": "O BRIEN" }
    , { "code": "143", "label": "OSCEOLA" }
    , { "code": "145", "label": "PAGE" }
    , { "code": "147", "label": "PALO ALTO" }
    , { "code": "149", "label": "PLYMOUTH" }
    , { "code": "151", "label": "POCAHONTAS" }
    , { "code": "153", "label": "POLK" }
    , { "code": "155", "label": "POTTAWATTAMIE" }
    , { "code": "157", "label": "POWESHIEK" }
    , { "code": "159", "label": "RINGGOLD" }
    , { "code": "161", "label": "SAC" }
    , { "code": "163", "label": "SCOTT" }
    , { "code": "165", "label": "SHELBY" }
    , { "code": "167", "label": "SIOUX" }
    , { "code": "169", "label": "STORY" }
    , { "code": "171", "label": "TAMA" }
    , { "code": "173", "label": "TAYLOR" }
    , { "code": "175", "label": "UNION" }
    , { "code": "177", "label": "VAN BUREN" }
    , { "code": "179", "label": "WAPELLO" }
    , { "code": "181", "label": "WARREN" }
    , { "code": "183", "label": "WASHINGTON" }
    , { "code": "185", "label": "WAYNE" }
    , { "code": "187", "label": "WEBSTER" }
    , { "code": "189", "label": "WINNEBAGO" }
    , { "code": "191", "label": "WINNESHIEK" }
    , { "code": "193", "label": "WOODBURY" }
    , { "code": "195", "label": "WORTH" }
    , { "code": "197", "label": "WRIGHT" }
  ],
  "ID": [
    { "code": "001", "label": "ADA" }
    , { "code": "003", "label": "ADAMS" }
    , { "code": "005", "label": "BANNOCK" }
    , { "code": "007", "label": "BEAR LAKE" }
    , { "code": "009", "label": "BENEWAH" }
    , { "code": "011", "label": "BINGHAM" }
    , { "code": "013", "label": "BLAINE" }
    , { "code": "015", "label": "BOISE" }
    , { "code": "017", "label": "BONNER" }
    , { "code": "019", "label": "BONNEVILLE" }
    , { "code": "021", "label": "BOUNDARY" }
    , { "code": "023", "label": "BUTTE" }
    , { "code": "025", "label": "CAMAS" }
    , { "code": "027", "label": "CANYON" }
    , { "code": "029", "label": "CARIBOU" }
    , { "code": "031", "label": "CASSIA" }
    , { "code": "033", "label": "CLARK" }
    , { "code": "035", "label": "CLEARWATER" }
    , { "code": "037", "label": "CUSTER" }
    , { "code": "039", "label": "ELMORE" }
    , { "code": "041", "label": "FRANKLIN" }
    , { "code": "043", "label": "FREMONT" }
    , { "code": "045", "label": "GEM" }
    , { "code": "047", "label": "GOODING" }
    , { "code": "049", "label": "IDAHO" }
    , { "code": "051", "label": "JEFFERSON" }
    , { "code": "053", "label": "JEROME" }
    , { "code": "055", "label": "KOOTENAI" }
    , { "code": "057", "label": "LATAH" }
    , { "code": "059", "label": "LEMHI" }
    , { "code": "061", "label": "LEWIS" }
    , { "code": "063", "label": "LINCOLN" }
    , { "code": "065", "label": "MADISON" }
    , { "code": "067", "label": "MENIDOKA" }
    , { "code": "069", "label": "NEZ PERCE" }
    , { "code": "071", "label": "ONEIDA" }
    , { "code": "073", "label": "OWYHEE" }
    , { "code": "075", "label": "PAYETTE" }
    , { "code": "077", "label": "POWER" }
    , { "code": "079", "label": "SHOSHONE" }
    , { "code": "081", "label": "TETON" }
    , { "code": "083", "label": "TWIN FALLS" }
    , { "code": "085", "label": "VALLEY" }
    , { "code": "087", "label": "WASHINGTON" }
    , { "code": "089", "label": "YELLOWSTONE NAT" }
  ],
  "IL": [
    { "code": "001", "label": "ADAMS" }
    , { "code": "003", "label": "ALEXANDER" }
    , { "code": "005", "label": "BOND" }
    , { "code": "007", "label": "BOONE" }
    , { "code": "009", "label": "BROWN" }
    , { "code": "011", "label": "BUREAU" }
    , { "code": "013", "label": "CALHOUN" }
    , { "code": "015", "label": "CARROLL" }
    , { "code": "017", "label": "CASS" }
    , { "code": "019", "label": "CHAMPAIGN" }
    , { "code": "021", "label": "CHRISTIAN" }
    , { "code": "023", "label": "CLARK" }
    , { "code": "025", "label": "CLAY" }
    , { "code": "027", "label": "CLINTON" }
    , { "code": "029", "label": "COLES" }
    , { "code": "031", "label": "COOK" }
    , { "code": "033", "label": "CRAWFORD" }
    , { "code": "035", "label": "CUMBERLAND" }
    , { "code": "037", "label": "DE KALB" }
    , { "code": "039", "label": "DE WITT" }
    , { "code": "041", "label": "DOUGLAS" }
    , { "code": "043", "label": "DU PAGE" }
    , { "code": "045", "label": "EDGAR" }
    , { "code": "047", "label": "EDWARDS" }
    , { "code": "049", "label": "EFFINGHAM" }
    , { "code": "051", "label": "FAYETTE" }
    , { "code": "053", "label": "FORD" }
    , { "code": "055", "label": "FRANKLIN" }
    , { "code": "057", "label": "FULTON" }
    , { "code": "059", "label": "GALLATIN" }
    , { "code": "061", "label": "GREENE" }
    , { "code": "063", "label": "GRUNDY" }
    , { "code": "065", "label": "HAMILTON" }
    , { "code": "067", "label": "HANCOCK" }
    , { "code": "069", "label": "HARDIN" }
    , { "code": "071", "label": "HENDERSON" }
    , { "code": "073", "label": "HENRY" }
    , { "code": "075", "label": "IROQUOIS" }
    , { "code": "077", "label": "JACKSON" }
    , { "code": "079", "label": "JASPER" }
    , { "code": "081", "label": "JEFFERSON" }
    , { "code": "083", "label": "JERSEY" }
    , { "code": "085", "label": "JO DAVIESS" }
    , { "code": "087", "label": "JOHNSON" }
    , { "code": "089", "label": "KANE" }
    , { "code": "091", "label": "KANKAKEE" }
    , { "code": "093", "label": "KENDALL" }
    , { "code": "095", "label": "KNOX" }
    , { "code": "099", "label": "LA SALLE" }
    , { "code": "097", "label": "LAKE" }
    , { "code": "101", "label": "LAWRENCE" }
    , { "code": "103", "label": "LEE" }
    , { "code": "105", "label": "LIVINGSTON" }
    , { "code": "107", "label": "LOGAN" }
    , { "code": "115", "label": "MACON" }
    , { "code": "117", "label": "MACOUPLIN" }
    , { "code": "119", "label": "MADISON" }
    , { "code": "121", "label": "MARION" }
    , { "code": "123", "label": "MARSHALL" }
    , { "code": "125", "label": "MASON" }
    , { "code": "127", "label": "MASSAC" }
    , { "code": "109", "label": "MC DONOUGH" }
    , { "code": "111", "label": "MC HENRY" }
    , { "code": "113", "label": "MC LEAN" }
    , { "code": "129", "label": "MENARD" }
    , { "code": "131", "label": "MERCER" }
    , { "code": "133", "label": "MONROE" }
    , { "code": "135", "label": "MONTGOMERY" }
    , { "code": "137", "label": "MORGAN" }
    , { "code": "139", "label": "MOULTRIE" }
    , { "code": "141", "label": "OGLE" }
    , { "code": "143", "label": "PEORIA" }
    , { "code": "145", "label": "PERRY" }
    , { "code": "147", "label": "PIATT" }
    , { "code": "149", "label": "PIKE" }
    , { "code": "151", "label": "POPE" }
    , { "code": "153", "label": "PULASKI" }
    , { "code": "155", "label": "PUTNAM" }
    , { "code": "157", "label": "RANDOLPH" }
    , { "code": "159", "label": "RICHLAND" }
    , { "code": "161", "label": "ROCK ISLAND" }
    , { "code": "165", "label": "SALINE" }
    , { "code": "167", "label": "SANGAMON" }
    , { "code": "169", "label": "SCHUYLER" }
    , { "code": "171", "label": "SCOTT" }
    , { "code": "173", "label": "SHELBY" }
    , { "code": "163", "label": "ST CLAIR" }
    , { "code": "175", "label": "STARK" }
    , { "code": "177", "label": "STEPHENSON" }
    , { "code": "179", "label": "TAZEWELL" }
    , { "code": "181", "label": "UNION" }
    , { "code": "183", "label": "VERMILION" }
    , { "code": "185", "label": "WABASH" }
    , { "code": "187", "label": "WARREN" }
    , { "code": "189", "label": "WASHINGTON" }
    , { "code": "191", "label": "WAYNE" }
    , { "code": "193", "label": "WHITE" }
    , { "code": "195", "label": "WHITESIDE" }
    , { "code": "197", "label": "WILL" }
    , { "code": "199", "label": "WILLIAMSON" }
    , { "code": "201", "label": "WINNEBAGO" }
    , { "code": "203", "label": "WOODFORD" }
  ],
  "IN": [
    { "code": "001", "label": "ADAMS" }
    , { "code": "003", "label": "ALLEN" }
    , { "code": "005", "label": "BARTHOLOMEW" }
    , { "code": "007", "label": "BENTON" }
    , { "code": "009", "label": "BLACKFORD" }
    , { "code": "011", "label": "BOONE" }
    , { "code": "013", "label": "BROWN" }
    , { "code": "015", "label": "CARROLL" }
    , { "code": "017", "label": "CASS" }
    , { "code": "019", "label": "CLARK" }
    , { "code": "021", "label": "CLAY" }
    , { "code": "023", "label": "CLINTON" }
    , { "code": "025", "label": "CRAWFORD" }
    , { "code": "027", "label": "DAVIESS" }
    , { "code": "033", "label": "DE KALB" }
    , { "code": "029", "label": "DEARBORN" }
    , { "code": "031", "label": "DECATUR" }
    , { "code": "035", "label": "DELAWARE" }
    , { "code": "037", "label": "DUBOIS" }
    , { "code": "039", "label": "ELKHART" }
    , { "code": "041", "label": "FAYETTE" }
    , { "code": "043", "label": "FLOYD" }
    , { "code": "045", "label": "FOUNTAIN" }
    , { "code": "047", "label": "FRANKLIN" }
    , { "code": "049", "label": "FULTON" }
    , { "code": "051", "label": "GIBSON" }
    , { "code": "053", "label": "GRANT" }
    , { "code": "055", "label": "GREENE" }
    , { "code": "057", "label": "HAMILTON" }
    , { "code": "059", "label": "HANCOCK" }
    , { "code": "061", "label": "HARRISON" }
    , { "code": "063", "label": "HENDRICKS" }
    , { "code": "065", "label": "HENRY" }
    , { "code": "067", "label": "HOWARD" }
    , { "code": "069", "label": "HUNTINGTON" }
    , { "code": "071", "label": "JACKSON" }
    , { "code": "073", "label": "JASPER" }
    , { "code": "075", "label": "JAY" }
    , { "code": "077", "label": "JEFFERSON" }
    , { "code": "079", "label": "JENNINGS" }
    , { "code": "081", "label": "JOHNSON" }
    , { "code": "083", "label": "KNOX" }
    , { "code": "085", "label": "KOSCIUSKO" }
    , { "code": "091", "label": "LA PORTE" }
    , { "code": "087", "label": "LAGRANGE" }
    , { "code": "089", "label": "LAKE" }
    , { "code": "093", "label": "LAWRENCE" }
    , { "code": "095", "label": "MADISON" }
    , { "code": "097", "label": "MARION" }
    , { "code": "099", "label": "MARSHALL" }
    , { "code": "101", "label": "MARTIN" }
    , { "code": "103", "label": "MIAMI" }
    , { "code": "105", "label": "MONROE" }
    , { "code": "107", "label": "MONTGOMERY" }
    , { "code": "109", "label": "MORGAN" }
    , { "code": "111", "label": "NEWTON" }
    , { "code": "113", "label": "NOBLE" }
    , { "code": "115", "label": "OHIO" }
    , { "code": "117", "label": "ORANGE" }
    , { "code": "119", "label": "OWEN" }
    , { "code": "121", "label": "PARKE" }
    , { "code": "123", "label": "PERRY" }
    , { "code": "125", "label": "PIKE" }
    , { "code": "127", "label": "PORTER" }
    , { "code": "129", "label": "POSEY" }
    , { "code": "131", "label": "PULASKI" }
    , { "code": "133", "label": "PUTNAM" }
    , { "code": "135", "label": "RANDOLPH" }
    , { "code": "137", "label": "RIPLEY" }
    , { "code": "139", "label": "RUSH" }
    , { "code": "143", "label": "SCOTT" }
    , { "code": "145", "label": "SHELBY" }
    , { "code": "147", "label": "SPENCER" }
    , { "code": "141", "label": "ST JOSEPH" }
    , { "code": "149", "label": "STARKE" }
    , { "code": "151", "label": "STEUBEN" }
    , { "code": "153", "label": "SULLIVAN" }
    , { "code": "155", "label": "SWITZERLAND" }
    , { "code": "157", "label": "TIPPECANOE" }
    , { "code": "159", "label": "TIPTON" }
    , { "code": "161", "label": "UNION" }
    , { "code": "163", "label": "VANDERBURG" }
    , { "code": "165", "label": "VERMILLION" }
    , { "code": "167", "label": "VIGO" }
    , { "code": "169", "label": "WABASH" }
    , { "code": "171", "label": "WARREN" }
    , { "code": "173", "label": "WARRICK" }
    , { "code": "175", "label": "WASHINGTON" }
    , { "code": "177", "label": "WAYNE" }
    , { "code": "179", "label": "WELLS" }
    , { "code": "181", "label": "WHITE" }
    , { "code": "183", "label": "WHITLEY" }
  ],
  "KS": [
    { "code": "001", "label": "ALLEN" }
    , { "code": "003", "label": "ANDERSON" }
    , { "code": "005", "label": "ATCHISON" }
    , { "code": "007", "label": "BARBER" }
    , { "code": "009", "label": "BARTON" }
    , { "code": "011", "label": "BOURBON" }
    , { "code": "013", "label": "BROWN" }
    , { "code": "015", "label": "BUTLER" }
    , { "code": "017", "label": "CHASE" }
    , { "code": "019", "label": "CHAUTAUQUA" }
    , { "code": "021", "label": "CHEROKEE" }
    , { "code": "023", "label": "CHEYENNE" }
    , { "code": "025", "label": "CLARK" }
    , { "code": "027", "label": "CLAY" }
    , { "code": "029", "label": "CLOUD" }
    , { "code": "031", "label": "COFFEY" }
    , { "code": "033", "label": "COMANCHE" }
    , { "code": "035", "label": "COWLEY" }
    , { "code": "037", "label": "CRAWFORD" }
    , { "code": "039", "label": "DECATUR" }
    , { "code": "041", "label": "DICKINSON" }
    , { "code": "043", "label": "DONIPHAN" }
    , { "code": "045", "label": "DOUGLAS" }
    , { "code": "047", "label": "EDWARDS" }
    , { "code": "049", "label": "ELK" }
    , { "code": "051", "label": "ELLIS" }
    , { "code": "053", "label": "ELLSWORTH" }
    , { "code": "055", "label": "FINNEY" }
    , { "code": "057", "label": "FORD" }
    , { "code": "059", "label": "FRANKLIN" }
    , { "code": "061", "label": "GEARY" }
    , { "code": "063", "label": "GOVE" }
    , { "code": "065", "label": "GRAHAM" }
    , { "code": "067", "label": "GRANT" }
    , { "code": "069", "label": "GRAY" }
    , { "code": "071", "label": "GREELEY" }
    , { "code": "073", "label": "GREENWOOD" }
    , { "code": "075", "label": "HAMILTON" }
    , { "code": "077", "label": "HARPER" }
    , { "code": "079", "label": "HARVEY" }
    , { "code": "081", "label": "HASKELL" }
    , { "code": "083", "label": "HODGEMAN" }
    , { "code": "085", "label": "JACKSON" }
    , { "code": "087", "label": "JEFFERSON" }
    , { "code": "089", "label": "JEWELL" }
    , { "code": "091", "label": "JOHNSON" }
    , { "code": "093", "label": "KEARNY" }
    , { "code": "095", "label": "KINGMAN" }
    , { "code": "097", "label": "KIOWA" }
    , { "code": "099", "label": "LABETTE" }
    , { "code": "101", "label": "LANE" }
    , { "code": "103", "label": "LEAVENWORTH" }
    , { "code": "105", "label": "LINCOLN" }
    , { "code": "107", "label": "LINN" }
    , { "code": "109", "label": "LOGAN" }
    , { "code": "111", "label": "LYON" }
    , { "code": "115", "label": "MARION" }
    , { "code": "117", "label": "MARSHALL" }
    , { "code": "113", "label": "MC PHERSON" }
    , { "code": "119", "label": "MEADE" }
    , { "code": "121", "label": "MIAMI" }
    , { "code": "123", "label": "MITCHELL" }
    , { "code": "125", "label": "MONTGOMERY" }
    , { "code": "127", "label": "MORRIS" }
    , { "code": "129", "label": "MORTON" }
    , { "code": "131", "label": "NEMAHA" }
    , { "code": "133", "label": "NEOSHO" }
    , { "code": "135", "label": "NESS" }
    , { "code": "137", "label": "NORTON" }
    , { "code": "139", "label": "OSAGE" }
    , { "code": "141", "label": "OSBORNE" }
    , { "code": "143", "label": "OTTAWA" }
    , { "code": "145", "label": "PAWNEE" }
    , { "code": "147", "label": "PHILLIPS" }
    , { "code": "149", "label": "POTTAWATOMIE" }
    , { "code": "151", "label": "PRATT" }
    , { "code": "153", "label": "RAWLINS" }
    , { "code": "155", "label": "RENO" }
    , { "code": "157", "label": "REPUBLIC" }
    , { "code": "159", "label": "RICE" }
    , { "code": "161", "label": "RILEY" }
    , { "code": "163", "label": "ROOKS" }
    , { "code": "165", "label": "RUSH" }
    , { "code": "167", "label": "RUSSELL" }
    , { "code": "169", "label": "SALINE" }
    , { "code": "171", "label": "SCOTT" }
    , { "code": "173", "label": "SEDGWICK" }
    , { "code": "175", "label": "SEWARD" }
    , { "code": "177", "label": "SHAWNEE" }
    , { "code": "179", "label": "SHERIDAN" }
    , { "code": "181", "label": "SHERMAN" }
    , { "code": "183", "label": "SMITH" }
    , { "code": "185", "label": "STAFFORD" }
    , { "code": "187", "label": "STANTON" }
    , { "code": "189", "label": "STEVENS" }
    , { "code": "191", "label": "SUMNER" }
    , { "code": "193", "label": "THOMAS" }
    , { "code": "195", "label": "TREGO" }
    , { "code": "197", "label": "WABAUNSEE" }
    , { "code": "199", "label": "WALLACE" }
    , { "code": "201", "label": "WASHINGTON" }
    , { "code": "203", "label": "WICHITA" }
    , { "code": "205", "label": "WILSON" }
    , { "code": "207", "label": "WOODSON" }
    , { "code": "209", "label": "WYANDOTTE" }
  ],
  "KY": [
    { "code": "001", "label": "ADAIR" }
    , { "code": "003", "label": "ALLEN" }
    , { "code": "005", "label": "ANDERSON" }
    , { "code": "007", "label": "BALLARD" }
    , { "code": "009", "label": "BARREN" }
    , { "code": "011", "label": "BATH" }
    , { "code": "013", "label": "BELL" }
    , { "code": "015", "label": "BOONE" }
    , { "code": "017", "label": "BOURBON" }
    , { "code": "019", "label": "BOYD" }
    , { "code": "021", "label": "BOYLE" }
    , { "code": "023", "label": "BRACKEN" }
    , { "code": "025", "label": "BREATHITT" }
    , { "code": "027", "label": "BRECKINRIDGE" }
    , { "code": "029", "label": "BULLITT" }
    , { "code": "031", "label": "BUTLER" }
    , { "code": "033", "label": "CALDWELL" }
    , { "code": "035", "label": "CALLOWAY" }
    , { "code": "037", "label": "CAMPBELL" }
    , { "code": "039", "label": "CARLISLE" }
    , { "code": "041", "label": "CARROLL" }
    , { "code": "043", "label": "CARTER" }
    , { "code": "045", "label": "CASEY" }
    , { "code": "047", "label": "CHRISTIAN" }
    , { "code": "049", "label": "CLARK" }
    , { "code": "051", "label": "CLAY" }
    , { "code": "053", "label": "CLINTON" }
    , { "code": "055", "label": "CRITTENDEN" }
    , { "code": "057", "label": "CUMBERLAND" }
    , { "code": "059", "label": "DAVIESS" }
    , { "code": "061", "label": "EDMONSON" }
    , { "code": "063", "label": "ELLIOTT" }
    , { "code": "065", "label": "ESTILL" }
    , { "code": "067", "label": "FAYETTE" }
    , { "code": "069", "label": "FLEMING" }
    , { "code": "071", "label": "FLOYD" }
    , { "code": "073", "label": "FRANKLIN" }
    , { "code": "075", "label": "FULTON" }
    , { "code": "077", "label": "GALLATIN" }
    , { "code": "079", "label": "GARRARD" }
    , { "code": "081", "label": "GRANT" }
    , { "code": "083", "label": "GRAVES" }
    , { "code": "085", "label": "GRAYSON" }
    , { "code": "087", "label": "GREEN" }
    , { "code": "089", "label": "GREENUP" }
    , { "code": "091", "label": "HANCOCK" }
    , { "code": "093", "label": "HARDIN" }
    , { "code": "095", "label": "HARLAN" }
    , { "code": "097", "label": "HARRISON" }
    , { "code": "099", "label": "HART" }
    , { "code": "101", "label": "HENDERSON" }
    , { "code": "103", "label": "HENRY" }
    , { "code": "105", "label": "HICKMAN" }
    , { "code": "107", "label": "HOPKINS" }
    , { "code": "109", "label": "JACKSON" }
    , { "code": "111", "label": "JEFFERSON" }
    , { "code": "113", "label": "JESSAMINE" }
    , { "code": "115", "label": "JOHNSON" }
    , { "code": "117", "label": "KENTON" }
    , { "code": "119", "label": "KNOTT" }
    , { "code": "121", "label": "KNOX" }
    , { "code": "123", "label": "LARUE" }
    , { "code": "125", "label": "LAUREL" }
    , { "code": "127", "label": "LAWRENCE" }
    , { "code": "129", "label": "LEE" }
    , { "code": "131", "label": "LESLIE" }
    , { "code": "133", "label": "LETCHER" }
    , { "code": "135", "label": "LEWIS" }
    , { "code": "137", "label": "LINCOLN" }
    , { "code": "139", "label": "LIVINGSTON" }
    , { "code": "141", "label": "LOGAN" }
    , { "code": "143", "label": "LYON" }
    , { "code": "151", "label": "MADISON" }
    , { "code": "153", "label": "MAGOFFIN" }
    , { "code": "155", "label": "MARION" }
    , { "code": "157", "label": "MARSHALL" }
    , { "code": "159", "label": "MARTIN" }
    , { "code": "161", "label": "MASON" }
    , { "code": "145", "label": "MC CRACKEN" }
    , { "code": "147", "label": "MC CREARY" }
    , { "code": "149", "label": "MC LEAN" }
    , { "code": "163", "label": "MEADE" }
    , { "code": "165", "label": "MENIFEE" }
    , { "code": "167", "label": "MERCER" }
    , { "code": "169", "label": "METCALFE" }
    , { "code": "171", "label": "MONROE" }
    , { "code": "173", "label": "MONTGOMERY" }
    , { "code": "175", "label": "MORGAN" }
    , { "code": "177", "label": "MUHLENBERG" }
    , { "code": "179", "label": "NELSON" }
    , { "code": "181", "label": "NICHOLAS" }
    , { "code": "183", "label": "OHIO" }
    , { "code": "185", "label": "OLDHAM" }
    , { "code": "187", "label": "OWEN" }
    , { "code": "189", "label": "OWSLEY" }
    , { "code": "191", "label": "PENDLETON" }
    , { "code": "193", "label": "PERRY" }
    , { "code": "195", "label": "PIKE" }
    , { "code": "197", "label": "POWELL" }
    , { "code": "199", "label": "PULASKI" }
    , { "code": "201", "label": "ROBERTSON" }
    , { "code": "203", "label": "ROCKCASTLE" }
    , { "code": "205", "label": "ROWAN" }
    , { "code": "207", "label": "RUSSELL" }
    , { "code": "209", "label": "SCOTT" }
    , { "code": "211", "label": "SHELBY" }
    , { "code": "213", "label": "SIMPSON" }
    , { "code": "215", "label": "SPENCER" }
    , { "code": "217", "label": "TAYLOR" }
    , { "code": "219", "label": "TODD" }
    , { "code": "221", "label": "TRIGG" }
    , { "code": "223", "label": "TRIMBLE" }
    , { "code": "225", "label": "UNION" }
    , { "code": "227", "label": "WARREN" }
    , { "code": "229", "label": "WASHINGTON" }
    , { "code": "231", "label": "WAYNE" }
    , { "code": "233", "label": "WEBSTER" }
    , { "code": "235", "label": "WHITLEY" }
    , { "code": "237", "label": "WOLFE" }
    , { "code": "239", "label": "WOODFORD" }
  ],
  "LA": [
    { "code": "001", "label": "ACADIA" }
    , { "code": "003", "label": "ALLEN" }
    , { "code": "005", "label": "ASCENSION" }
    , { "code": "007", "label": "ASSUMPTION" }
    , { "code": "009", "label": "AVOYELLES" }
    , { "code": "714", "label": "BAY MARCHAND" }
    , { "code": "011", "label": "BEAUREGARD" }
    , { "code": "013", "label": "BIENVILLE" }
    , { "code": "015", "label": "BOSSIER" }
    , { "code": "726", "label": "BRETON SOUTH" }
    , { "code": "017", "label": "CADDO" }
    , { "code": "019", "label": "CALCASIEU" }
    , { "code": "021", "label": "CALDWELL" }
    , { "code": "023", "label": "CAMERON" }
    , { "code": "025", "label": "CATAHOULA" }
    , { "code": "728", "label": "CHANDELEUR" }
    , { "code": "727", "label": "CHANDELEUR SOUN" }
    , { "code": "730", "label": "CHANDELEUR SOUT" }
    , { "code": "729", "label": "CHANDELEUR-EAST" }
    , { "code": "027", "label": "CLAIBORNE" }
    , { "code": "029", "label": "CONCORDIA" }
    , { "code": "031", "label": "DE SOTO" }
    , { "code": "033", "label": "EAST BATON ROUG" }
    , { "code": "703", "label": "EAST CAMERON" }
    , { "code": "704", "label": "EAST CAMERON-SO" }
    , { "code": "035", "label": "EAST CARROLL" }
    , { "code": "037", "label": "EAST FELICIANA" }
    , { "code": "709", "label": "EUGENE IS." }
    , { "code": "710", "label": "EUGENE IS. SOUT" }
    , { "code": "039", "label": "EVANGELINE" }
    , { "code": "041", "label": "FRANKLIN" }
    , { "code": "717", "label": "GRAND IS." }
    , { "code": "718", "label": "GRAND IS.-SOUTH" }
    , { "code": "043", "label": "GRANT" }
    , { "code": "045", "label": "IBERIA" }
    , { "code": "047", "label": "IBERVILLE" }
    , { "code": "049", "label": "JACKSON" }
    , { "code": "051", "label": "JEFFERSON" }
    , { "code": "053", "label": "JEFFERSON DAVIS" }
    , { "code": "059", "label": "LA SALLE" }
    , { "code": "055", "label": "LAFAYETTE" }
    , { "code": "057", "label": "LAFOURCHE" }
    , { "code": "061", "label": "LINCOLN" }
    , { "code": "063", "label": "LIVINGSTON" }
    , { "code": "065", "label": "MADISON" }
    , { "code": "725", "label": "MAIN PASS" }
    , { "code": "724", "label": "MAIN PASS-SOUTH" }
    , { "code": "067", "label": "MOREHOUSE" }
    , { "code": "069", "label": "NATCHITOCHES" }
    , { "code": "071", "label": "ORLEANS" }
    , { "code": "073", "label": "OUACHITA" }
    , { "code": "777", "label": "OFFSHORE" }
    , { "code": "075", "label": "PLAQUEMINES" }
    , { "code": "077", "label": "POINTE COUPEE" }
    , { "code": "079", "label": "RAPIDES" }
    , { "code": "081", "label": "RED RIVER" }
    , { "code": "083", "label": "RICHLAND" }
    , { "code": "085", "label": "SABINE" }
    , { "code": "731", "label": "SABINE PASS" }
    , { "code": "711", "label": "SHIP SHOAL" }
    , { "code": "712", "label": "SHIP SHOAL-SOUT" }
    , { "code": "707", "label": "SOUTH MARSH IS." }
    , { "code": "708", "label": "SOUTH MARSH IS." }
    , { "code": "721", "label": "SOUTH PASS" }
    , { "code": "723", "label": "SOUTH PASS-EAST" }
    , { "code": "722", "label": "SOUTH PASS-SOUT" }
    , { "code": "713", "label": "SOUTH PELTO" }
    , { "code": "716", "label": "SOUTH TIMBALIER" }
    , { "code": "715", "label": "SOUTH TIMBALIER" }
    , { "code": "087", "label": "ST BERNARD" }
    , { "code": "089", "label": "ST CHARLES" }
    , { "code": "091", "label": "ST HELENA" }
    , { "code": "093", "label": "ST JAMES" }
    , { "code": "095", "label": "ST JOHN THE BAP" }
    , { "code": "097", "label": "ST LANDRY" }
    , { "code": "099", "label": "ST MARTIN" }
    , { "code": "101", "label": "ST MARY" }
    , { "code": "103", "label": "ST TAMMANY" }
    , { "code": "105", "label": "TANGIPAHOA" }
    , { "code": "107", "label": "TENSAS" }
    , { "code": "109", "label": "TERREBONE" }
    , { "code": "111", "label": "UNION" }
    , { "code": "705", "label": "VERMILION" }
    , { "code": "113", "label": "VERMILION" }
    , { "code": "706", "label": "VERMILION-SOUTH" }
    , { "code": "115", "label": "VERNON" }
    , { "code": "117", "label": "WASHINGTON" }
    , { "code": "119", "label": "WEBSTER" }
    , { "code": "121", "label": "WEST BATON ROUG" }
    , { "code": "700", "label": "WEST CAMERON" }
    , { "code": "702", "label": "WEST CAMERON-SO" }
    , { "code": "701", "label": "WEST CAMERON-WE" }
    , { "code": "123", "label": "WEST CARROLL" }
    , { "code": "719", "label": "WEST DELTA" }
    , { "code": "720", "label": "WEST DELTA-SOUT" }
    , { "code": "125", "label": "WEST FELICIANA" }
    , { "code": "127", "label": "WINN" }
  ],
  "MA": [
    { "code": "001", "label": "BARNSTABLE" }
    , { "code": "201", "label": "BARNSTABLE STAT" }
    , { "code": "003", "label": "BERKSHIRE" }
    , { "code": "005", "label": "BRISTOL" }
    , { "code": "205", "label": "BRISTOL STATE W" }
    , { "code": "007", "label": "DUKES" }
    , { "code": "207", "label": "DUKES STATE WAT" }
    , { "code": "009", "label": "ESSEX" }
    , { "code": "209", "label": "ESSEX STATE WAT" }
    , { "code": "011", "label": "FRANKLIN" }
    , { "code": "013", "label": "HAMPDEN" }
    , { "code": "015", "label": "HAMPSHIRE" }
    , { "code": "017", "label": "MIDDLESEX" }
    , { "code": "019", "label": "NANTUCKET" }
    , { "code": "219", "label": "NANTUCKET STATE" }
    , { "code": "021", "label": "NORFOLK" }
    , { "code": "221", "label": "NORFOLK STATE W" }
    , { "code": "023", "label": "PLYMOUTH" }
    , { "code": "223", "label": "PLYMOUTH STATE" }
    , { "code": "025", "label": "SUFFOLK" }
    , { "code": "225", "label": "SUFFOLK STATE W" }
    , { "code": "027", "label": "WORCHESTER" }
  ],
  "MD": [
    { "code": "001", "label": "ALLEGANY" }
    , { "code": "003", "label": "ANNE ARUNDEL" }
    , { "code": "005", "label": "BALTIMORE" }
    , { "code": "007", "label": "BALTIMORE CITY" }
    , { "code": "009", "label": "CALVERT" }
    , { "code": "011", "label": "CAROLINE" }
    , { "code": "013", "label": "CARROLL" }
    , { "code": "015", "label": "CECIL" }
    , { "code": "017", "label": "CHARLES" }
    , { "code": "019", "label": "DORCHESTER" }
    , { "code": "021", "label": "FREDERICK" }
    , { "code": "023", "label": "GARRETT" }
    , { "code": "025", "label": "HARFORD" }
    , { "code": "027", "label": "HOWARD" }
    , { "code": "029", "label": "KENT" }
    , { "code": "031", "label": "MONTGOMERY" }
    , { "code": "033", "label": "PRINCE GEORGES" }
    , { "code": "035", "label": "QUEEN ANNES" }
    , { "code": "039", "label": "SOMERSET" }
    , { "code": "037", "label": "ST MARYS" }
    , { "code": "041", "label": "TALBOT" }
    , { "code": "043", "label": "WASHINGTON" }
    , { "code": "045", "label": "WICOMICO" }
    , { "code": "047", "label": "WORCESTER" }
    , { "code": "247", "label": "WORCESTER STATE" }
  ],
  "ME": [
    { "code": "001", "label": "ANDROSCOGGIN" }
    , { "code": "003", "label": "AROOSTOOK" }
    , { "code": "005", "label": "CUMBERLAND" }
    , { "code": "205", "label": "CUMBERLAND STAT" }
    , { "code": "007", "label": "FRANKLIN" }
    , { "code": "009", "label": "HANCOCK" }
    , { "code": "209", "label": "HANCOCK STATE W" }
    , { "code": "011", "label": "KENNEBEC" }
    , { "code": "013", "label": "KNOX" }
    , { "code": "213", "label": "KNOX STATE WATE" }
    , { "code": "015", "label": "LINCOLN" }
    , { "code": "215", "label": "LINCOLN STATE W" }
    , { "code": "017", "label": "OXFORD" }
    , { "code": "019", "label": "PENOBSCOT" }
    , { "code": "021", "label": "PISCATAQUIS" }
    , { "code": "023", "label": "SAGADAHAC" }
    , { "code": "223", "label": "SAGADAHAC STATE" }
    , { "code": "025", "label": "SOMERSET" }
    , { "code": "027", "label": "WALDO" }
    , { "code": "029", "label": "WASHINGTON" }
    , { "code": "229", "label": "WASHINGTON STAT" }
    , { "code": "031", "label": "YORK" }
    , { "code": "231", "label": "YORK STATE WATE" }
  ],
  "MI": [
    { "code": "001", "label": "ALCONA" }
    , { "code": "003", "label": "ALGER" }
    , { "code": "005", "label": "ALLEGAN" }
    , { "code": "007", "label": "ALPENA" }
    , { "code": "009", "label": "ANTRIM" }
    , { "code": "011", "label": "ARENAC" }
    , { "code": "013", "label": "BARAGA" }
    , { "code": "015", "label": "BARRY" }
    , { "code": "017", "label": "BAY" }
    , { "code": "019", "label": "BENZIE" }
    , { "code": "021", "label": "BERRIEN" }
    , { "code": "023", "label": "BRANCH" }
    , { "code": "025", "label": "CALHOUN" }
    , { "code": "027", "label": "CASS" }
    , { "code": "029", "label": "CHARLEVOIX" }
    , { "code": "031", "label": "CHEBOYGAN" }
    , { "code": "033", "label": "CHIPPEWA" }
    , { "code": "035", "label": "CLARE" }
    , { "code": "037", "label": "CLINTON" }
    , { "code": "039", "label": "CRAWFORD" }
    , { "code": "041", "label": "DELTA" }
    , { "code": "043", "label": "DICKINSON" }
    , { "code": "045", "label": "EATON" }
    , { "code": "047", "label": "EMMET" }
    , { "code": "049", "label": "GENESEE" }
    , { "code": "051", "label": "GLADWIN" }
    , { "code": "053", "label": "GOGEBIC" }
    , { "code": "055", "label": "GRAND TRAVERSE" }
    , { "code": "057", "label": "GRATIOT" }
    , { "code": "059", "label": "HILLSDALE" }
    , { "code": "061", "label": "HOUGHTON" }
    , { "code": "063", "label": "HURON" }
    , { "code": "065", "label": "INGHAM" }
    , { "code": "067", "label": "IONIA" }
    , { "code": "069", "label": "IOSCO" }
    , { "code": "071", "label": "IRON" }
    , { "code": "073", "label": "ISABELLA" }
    , { "code": "075", "label": "JACKSON" }
    , { "code": "077", "label": "KALAMAZOO" }
    , { "code": "079", "label": "KALKASKA" }
    , { "code": "081", "label": "KENT" }
    , { "code": "083", "label": "KEWEENAW" }
    , { "code": "085", "label": "LAKE" }
    , { "code": "087", "label": "LAPEER" }
    , { "code": "089", "label": "LEELANAU" }
    , { "code": "091", "label": "LENAWEE" }
    , { "code": "093", "label": "LIVINGSTON" }
    , { "code": "095", "label": "LUCE" }
    , { "code": "097", "label": "MACKINAC" }
    , { "code": "099", "label": "MACOMB" }
    , { "code": "101", "label": "MANISTEE" }
    , { "code": "103", "label": "MARQUETTE" }
    , { "code": "105", "label": "MASON" }
    , { "code": "107", "label": "MECOSTA" }
    , { "code": "109", "label": "MENOMINEE" }
    , { "code": "111", "label": "MIDLAND" }
    , { "code": "113", "label": "MISSAUKEE" }
    , { "code": "115", "label": "MONROE" }
    , { "code": "117", "label": "MONTCALM" }
    , { "code": "119", "label": "MONTMORENCY" }
    , { "code": "121", "label": "MUSKEGON" }
    , { "code": "123", "label": "NEWAYGO" }
    , { "code": "125", "label": "OAKLAND" }
    , { "code": "127", "label": "OCEANA" }
    , { "code": "129", "label": "OGEMAW" }
    , { "code": "131", "label": "ONTONAGON" }
    , { "code": "133", "label": "OSCEOLA" }
    , { "code": "135", "label": "OSCODA" }
    , { "code": "137", "label": "OTSEGO" }
    , { "code": "139", "label": "OTTAWA" }
    , { "code": "141", "label": "PRESQUE ISLE" }
    , { "code": "143", "label": "ROSCOMMON" }
    , { "code": "145", "label": "SAGINAW" }
    , { "code": "151", "label": "SANILAC" }
    , { "code": "153", "label": "SCHOOLCRAFT" }
    , { "code": "155", "label": "SHIAWASSEE" }
    , { "code": "147", "label": "ST CLAIR" }
    , { "code": "149", "label": "ST JOSEPH" }
    , { "code": "157", "label": "TUSCOLA" }
    , { "code": "159", "label": "VAN BUREN" }
    , { "code": "161", "label": "WASHTENAW" }
    , { "code": "163", "label": "WAYNE" }
    , { "code": "165", "label": "WEXFORD" }
  ],
  "MN": [
    { "code": "001", "label": "AITKIN" }
    , { "code": "003", "label": "ANOKA" }
    , { "code": "005", "label": "BECKER" }
    , { "code": "007", "label": "BELTRAMI" }
    , { "code": "009", "label": "BENTON" }
    , { "code": "011", "label": "BIG STONE" }
    , { "code": "013", "label": "BLUE EARTH" }
    , { "code": "015", "label": "BROWN" }
    , { "code": "017", "label": "CARLTON" }
    , { "code": "019", "label": "CARVER" }
    , { "code": "021", "label": "CASS" }
    , { "code": "023", "label": "CHIPPEWA" }
    , { "code": "025", "label": "CHISAGO" }
    , { "code": "027", "label": "CLAY" }
    , { "code": "029", "label": "CLEARWATER" }
    , { "code": "031", "label": "COOK" }
    , { "code": "033", "label": "COTTONWOOD" }
    , { "code": "035", "label": "CROW WING" }
    , { "code": "037", "label": "DAKOTA" }
    , { "code": "039", "label": "DODGE" }
    , { "code": "041", "label": "DOUGLAS" }
    , { "code": "043", "label": "FARIBAULT" }
    , { "code": "045", "label": "FILLMORE" }
    , { "code": "047", "label": "FREEBORN" }
    , { "code": "049", "label": "GOODHUE" }
    , { "code": "051", "label": "GRANT" }
    , { "code": "053", "label": "HENNEPIN" }
    , { "code": "055", "label": "HOUSTON" }
    , { "code": "057", "label": "HUBBARD" }
    , { "code": "059", "label": "ISANTI" }
    , { "code": "061", "label": "ITASCA" }
    , { "code": "063", "label": "JACKSON" }
    , { "code": "065", "label": "KANABEC" }
    , { "code": "067", "label": "KANDIYOHI" }
    , { "code": "069", "label": "KITTSON" }
    , { "code": "071", "label": "KOOCHICHING" }
    , { "code": "073", "label": "LAC QUI PARLE" }
    , { "code": "075", "label": "LAKE" }
    , { "code": "077", "label": "LAKE OF WOODS" }
    , { "code": "079", "label": "LE SUEUR" }
    , { "code": "081", "label": "LINCOLN" }
    , { "code": "083", "label": "LYON" }
    , { "code": "087", "label": "MAHNOMEN" }
    , { "code": "089", "label": "MARSHALL" }
    , { "code": "091", "label": "MARTIN" }
    , { "code": "085", "label": "MC LEOD" }
    , { "code": "093", "label": "MEEKER" }
    , { "code": "095", "label": "MILLE LACS" }
    , { "code": "097", "label": "MORRISON" }
    , { "code": "099", "label": "MOWER" }
    , { "code": "101", "label": "MURRAY" }
    , { "code": "103", "label": "NICOLLET" }
    , { "code": "105", "label": "NOBLES" }
    , { "code": "107", "label": "NORMAN" }
    , { "code": "109", "label": "OLMSTED" }
    , { "code": "111", "label": "OTTER TAIL" }
    , { "code": "113", "label": "PENNINGTON" }
    , { "code": "115", "label": "PINE" }
    , { "code": "117", "label": "PIPESTONE" }
    , { "code": "119", "label": "POLK" }
    , { "code": "121", "label": "POPE" }
    , { "code": "123", "label": "RAMSEY" }
    , { "code": "125", "label": "RED LAKE" }
    , { "code": "127", "label": "REDWOOD" }
    , { "code": "129", "label": "RENVILLE" }
    , { "code": "131", "label": "RICE" }
    , { "code": "133", "label": "ROCK" }
    , { "code": "135", "label": "ROSEAU" }
    , { "code": "139", "label": "SCOTT" }
    , { "code": "141", "label": "SHERBURNE" }
    , { "code": "143", "label": "SIBLEY" }
    , { "code": "137", "label": "ST LOUIS" }
    , { "code": "145", "label": "STEARNS" }
    , { "code": "147", "label": "STEELE" }
    , { "code": "149", "label": "STEVENS" }
    , { "code": "151", "label": "SWIFT" }
    , { "code": "153", "label": "TODD" }
    , { "code": "155", "label": "TRAVERSE" }
    , { "code": "157", "label": "WABASHA" }
    , { "code": "159", "label": "WADENA" }
    , { "code": "161", "label": "WASECA" }
    , { "code": "163", "label": "WASHINGTON" }
    , { "code": "165", "label": "WATONWAN" }
    , { "code": "167", "label": "WILKIN" }
    , { "code": "169", "label": "WINONA" }
    , { "code": "171", "label": "WRIGHT" }
    , { "code": "173", "label": "YELLOW MEDICINE" }
  ],
  "MO": [
    { "code": "001", "label": "ADAIR" }
    , { "code": "003", "label": "ANDREW" }
    , { "code": "005", "label": "ATCHISON" }
    , { "code": "007", "label": "AUDRAIN" }
    , { "code": "009", "label": "BARRY" }
    , { "code": "011", "label": "BARTON" }
    , { "code": "013", "label": "BATES" }
    , { "code": "015", "label": "BENTON" }
    , { "code": "017", "label": "BOLLINGER" }
    , { "code": "019", "label": "BOONE" }
    , { "code": "021", "label": "BUCHANAN" }
    , { "code": "023", "label": "BUTLER" }
    , { "code": "025", "label": "CALDWELL" }
    , { "code": "027", "label": "CALLAWAY" }
    , { "code": "029", "label": "CAMDEN" }
    , { "code": "031", "label": "CAPE GIRARDEAU" }
    , { "code": "033", "label": "CARROLL" }
    , { "code": "035", "label": "CARTER" }
    , { "code": "037", "label": "CASS" }
    , { "code": "039", "label": "CEDAR" }
    , { "code": "041", "label": "CHARITON" }
    , { "code": "043", "label": "CHRISTIAN" }
    , { "code": "045", "label": "CLARK" }
    , { "code": "047", "label": "CLAY" }
    , { "code": "049", "label": "CLINTON" }
    , { "code": "051", "label": "COLE" }
    , { "code": "053", "label": "COOPER" }
    , { "code": "055", "label": "CRAWFORD" }
    , { "code": "057", "label": "DADE" }
    , { "code": "059", "label": "DALLAS" }
    , { "code": "061", "label": "DAVIESS" }
    , { "code": "063", "label": "DE KALB" }
    , { "code": "065", "label": "DENT" }
    , { "code": "067", "label": "DOUGLAS" }
    , { "code": "069", "label": "DUNKLIN" }
    , { "code": "071", "label": "FRANKLIN" }
    , { "code": "073", "label": "GASCONADE" }
    , { "code": "075", "label": "GENTRY" }
    , { "code": "077", "label": "GREENE" }
    , { "code": "079", "label": "GRUNDY" }
    , { "code": "081", "label": "HARRISON" }
    , { "code": "083", "label": "HENRY" }
    , { "code": "085", "label": "HICKORY" }
    , { "code": "087", "label": "HOLT" }
    , { "code": "089", "label": "HOWARD" }
    , { "code": "091", "label": "HOWELL" }
    , { "code": "093", "label": "IRON" }
    , { "code": "095", "label": "JACKSON" }
    , { "code": "097", "label": "JASPER" }
    , { "code": "099", "label": "JEFFERSON" }
    , { "code": "101", "label": "JOHNSON" }
    , { "code": "103", "label": "KNOX" }
    , { "code": "105", "label": "LACLEDE" }
    , { "code": "107", "label": "LAFAYETTE" }
    , { "code": "109", "label": "LAWRENCE" }
    , { "code": "111", "label": "LEWIS" }
    , { "code": "113", "label": "LINCOLN" }
    , { "code": "115", "label": "LINN" }
    , { "code": "117", "label": "LIVINGSTON" }
    , { "code": "121", "label": "MACON" }
    , { "code": "123", "label": "MADISON" }
    , { "code": "125", "label": "MARIES" }
    , { "code": "127", "label": "MARION" }
    , { "code": "119", "label": "MC DONALD" }
    , { "code": "129", "label": "MERCER" }
    , { "code": "131", "label": "MILLER" }
    , { "code": "133", "label": "MISSISSIPPI" }
    , { "code": "135", "label": "MONITEAU" }
    , { "code": "137", "label": "MONROE" }
    , { "code": "139", "label": "MONTGOMERY" }
    , { "code": "141", "label": "MORGAN" }
    , { "code": "143", "label": "NEW MADRID" }
    , { "code": "145", "label": "NEWTON" }
    , { "code": "147", "label": "NODAWAY" }
    , { "code": "149", "label": "OREGON" }
    , { "code": "151", "label": "OSAGE" }
    , { "code": "153", "label": "OZARK" }
    , { "code": "155", "label": "PEMISCOT" }
    , { "code": "157", "label": "PERRY" }
    , { "code": "159", "label": "PETTIS" }
    , { "code": "161", "label": "PHELPS" }
    , { "code": "163", "label": "PIKE" }
    , { "code": "165", "label": "PLATTE" }
    , { "code": "167", "label": "POLK" }
    , { "code": "169", "label": "PULASKI" }
    , { "code": "171", "label": "PUTNAM" }
    , { "code": "173", "label": "RALLS" }
    , { "code": "175", "label": "RANDOLPH" }
    , { "code": "177", "label": "RAY" }
    , { "code": "179", "label": "REYNOLDS" }
    , { "code": "181", "label": "RIPLEY" }
    , { "code": "195", "label": "SALINE" }
    , { "code": "197", "label": "SCHUYLER" }
    , { "code": "199", "label": "SCOTLAND" }
    , { "code": "201", "label": "SCOTT" }
    , { "code": "203", "label": "SHANNON" }
    , { "code": "205", "label": "SHELBY" }
    , { "code": "183", "label": "ST CHARLES" }
    , { "code": "185", "label": "ST CLAIR" }
    , { "code": "187", "label": "ST FRANCOIS" }
    , { "code": "189", "label": "ST LOUIS" }
    , { "code": "191", "label": "ST LOUIS CITY" }
    , { "code": "193", "label": "STE GENEVIEVE" }
    , { "code": "207", "label": "STODDARD" }
    , { "code": "209", "label": "STONE" }
    , { "code": "211", "label": "SULLIVAN" }
    , { "code": "213", "label": "TANEY" }
    , { "code": "215", "label": "TEXAS" }
    , { "code": "217", "label": "VERNON" }
    , { "code": "219", "label": "WARREN" }
    , { "code": "221", "label": "WASHINGTON" }
    , { "code": "223", "label": "WAYNE" }
    , { "code": "225", "label": "WEBSTER" }
    , { "code": "227", "label": "WORTH" }
    , { "code": "229", "label": "WRIGHT" }
  ],
  "MS": [
    { "code": "001", "label": "ADAMS" }
    , { "code": "003", "label": "ALCORN" }
    , { "code": "005", "label": "AMITE" }
    , { "code": "007", "label": "ATTALA" }
    , { "code": "009", "label": "BENTON" }
    , { "code": "011", "label": "BOLIVAR" }
    , { "code": "013", "label": "CALHOUN" }
    , { "code": "015", "label": "CARROLL" }
    , { "code": "017", "label": "CHICKASAW" }
    , { "code": "019", "label": "CHOCTAW" }
    , { "code": "021", "label": "CLAIBORNE" }
    , { "code": "023", "label": "CLARKE" }
    , { "code": "025", "label": "CLAY" }
    , { "code": "027", "label": "COAHOMA" }
    , { "code": "029", "label": "COPIAH" }
    , { "code": "031", "label": "COVINGTON" }
    , { "code": "033", "label": "DE SOTO" }
    , { "code": "035", "label": "FORREST" }
    , { "code": "037", "label": "FRANKLIN" }
    , { "code": "039", "label": "GEORGE" }
    , { "code": "043", "label": "GRANADA" }
    , { "code": "041", "label": "GREENE" }
    , { "code": "045", "label": "HANCOCK" }
    , { "code": "245", "label": "HANCOCK STATE W" }
    , { "code": "047", "label": "HARRISON" }
    , { "code": "247", "label": "HARRISON STATE" }
    , { "code": "049", "label": "HINDS" }
    , { "code": "051", "label": "HOLMES" }
    , { "code": "053", "label": "HUMPHREYS" }
    , { "code": "055", "label": "ISSAQUENA" }
    , { "code": "057", "label": "ITAWAMBA" }
    , { "code": "059", "label": "JACKSON" }
    , { "code": "259", "label": "JACKSON STATE W" }
    , { "code": "061", "label": "JASPER" }
    , { "code": "063", "label": "JEFFERSON" }
    , { "code": "065", "label": "JEFFERSON DAVIS" }
    , { "code": "067", "label": "JONES" }
    , { "code": "069", "label": "KEMPER" }
    , { "code": "071", "label": "LAFAYETTE" }
    , { "code": "073", "label": "LAMAR" }
    , { "code": "075", "label": "LAUDERDALE" }
    , { "code": "077", "label": "LAWRENCE" }
    , { "code": "079", "label": "LEAKE" }
    , { "code": "081", "label": "LEE" }
    , { "code": "083", "label": "LEFLORE" }
    , { "code": "085", "label": "LINCOLN" }
    , { "code": "087", "label": "LOWNDES" }
    , { "code": "089", "label": "MADISON" }
    , { "code": "091", "label": "MARION" }
    , { "code": "093", "label": "MARSHALL" }
    , { "code": "095", "label": "MONROE" }
    , { "code": "097", "label": "MONTGOMERY" }
    , { "code": "099", "label": "NESHOBA" }
    , { "code": "101", "label": "NEWTON" }
    , { "code": "103", "label": "NOXUBEE" }
    , { "code": "105", "label": "OKTIBBEHA" }
    , { "code": "107", "label": "PANOLA" }
    , { "code": "109", "label": "PEARL RIVER" }
    , { "code": "111", "label": "PERRY" }
    , { "code": "113", "label": "PIKE" }
    , { "code": "115", "label": "PONTOTOC" }
    , { "code": "117", "label": "PRENTISS" }
    , { "code": "119", "label": "QUITMAN" }
    , { "code": "121", "label": "RANKIN" }
    , { "code": "123", "label": "SCOTT" }
    , { "code": "125", "label": "SHARKEY" }
    , { "code": "127", "label": "SIMPSON" }
    , { "code": "129", "label": "SMITH" }
    , { "code": "131", "label": "STONE" }
    , { "code": "133", "label": "SUNFLOWER" }
    , { "code": "135", "label": "TALLAHATCHIE" }
    , { "code": "137", "label": "TATE" }
    , { "code": "139", "label": "TIPPAH" }
    , { "code": "141", "label": "TISHOMINGO" }
    , { "code": "143", "label": "TUNICA" }
    , { "code": "145", "label": "UNION" }
    , { "code": "147", "label": "WALTHALL" }
    , { "code": "149", "label": "WARREN" }
    , { "code": "151", "label": "WASHINGTON" }
    , { "code": "153", "label": "WAYNE" }
    , { "code": "155", "label": "WEBSTER" }
    , { "code": "157", "label": "WILKINSON" }
    , { "code": "159", "label": "WINSTON" }
    , { "code": "161", "label": "YALOBUSHA" }
    , { "code": "163", "label": "YAZOO" }
  ],
  "MT": [
    { "code": "001", "label": "BEAVERHEAD" }
    , { "code": "003", "label": "BIG HORN" }
    , { "code": "005", "label": "BLAINE" }
    , { "code": "007", "label": "BROADWATER" }
    , { "code": "009", "label": "CARBON" }
    , { "code": "011", "label": "CARTER" }
    , { "code": "013", "label": "CASCADE" }
    , { "code": "015", "label": "CHOUTEAU" }
    , { "code": "017", "label": "CUSTER" }
    , { "code": "019", "label": "DANIELS" }
    , { "code": "021", "label": "DAWSON" }
    , { "code": "023", "label": "DEER LODGE" }
    , { "code": "025", "label": "FALLON" }
    , { "code": "027", "label": "FERGUS" }
    , { "code": "029", "label": "FLATHEAD" }
    , { "code": "031", "label": "GALLATIN" }
    , { "code": "033", "label": "GARFIELD" }
    , { "code": "035", "label": "GLACIER" }
    , { "code": "037", "label": "GOLDEN VALLEY" }
    , { "code": "039", "label": "GRANITE" }
    , { "code": "041", "label": "HILL" }
    , { "code": "043", "label": "JEFFERSON" }
    , { "code": "045", "label": "JUDITH BASIN" }
    , { "code": "047", "label": "LAKE" }
    , { "code": "049", "label": "LEWIS AND CLARK" }
    , { "code": "051", "label": "LIBERTY" }
    , { "code": "053", "label": "LINCOLN" }
    , { "code": "057", "label": "MADISON" }
    , { "code": "055", "label": "MCCONE" }
    , { "code": "059", "label": "MEAGHER" }
    , { "code": "061", "label": "MINERAL" }
    , { "code": "063", "label": "MISSOULA" }
    , { "code": "065", "label": "MUSSELSHELL" }
    , { "code": "067", "label": "PARK" }
    , { "code": "069", "label": "PETROLEUM" }
    , { "code": "071", "label": "PHILLIPS" }
    , { "code": "073", "label": "PONDERA" }
    , { "code": "075", "label": "POWDER RIVER" }
    , { "code": "077", "label": "POWELL" }
    , { "code": "079", "label": "PRAIRIE" }
    , { "code": "081", "label": "RAVALLI" }
    , { "code": "083", "label": "RICHLAND" }
    , { "code": "085", "label": "ROOSEVELT" }
    , { "code": "087", "label": "ROSEBUD" }
    , { "code": "089", "label": "SANDERS" }
    , { "code": "091", "label": "SHERIDAN" }
    , { "code": "093", "label": "SILVER BOW" }
    , { "code": "095", "label": "STILLWATER" }
    , { "code": "097", "label": "SWEET GRASS" }
    , { "code": "099", "label": "TETON" }
    , { "code": "101", "label": "TOOLE" }
    , { "code": "103", "label": "TREASURE" }
    , { "code": "105", "label": "VALLEY" }
    , { "code": "107", "label": "WHEATLAND" }
    , { "code": "109", "label": "WIBAUX" }
    , { "code": "111", "label": "YELLOWSTONE" }
    , { "code": "113", "label": "YELLOWSTONE NAT" }
  ],
  "NC": [
    { "code": "001", "label": "ALAMANCE" }
    , { "code": "003", "label": "ALEXANDER" }
    , { "code": "005", "label": "ALLEGHANY" }
    , { "code": "007", "label": "ANSON" }
    , { "code": "009", "label": "ASHE" }
    , { "code": "011", "label": "AVERY" }
    , { "code": "013", "label": "BEAUFORT" }
    , { "code": "015", "label": "BERTIE" }
    , { "code": "017", "label": "BLADEN" }
    , { "code": "019", "label": "BRUNSWICK" }
    , { "code": "219", "label": "BRUNSWICK STATE" }
    , { "code": "021", "label": "BUNCOMBE" }
    , { "code": "023", "label": "BURKE" }
    , { "code": "025", "label": "CABARRUS" }
    , { "code": "027", "label": "CALDWELL" }
    , { "code": "029", "label": "CAMDEN" }
    , { "code": "031", "label": "CARTERET" }
    , { "code": "231", "label": "CARTERET STATE" }
    , { "code": "033", "label": "CASWELL" }
    , { "code": "035", "label": "CATAWBA" }
    , { "code": "037", "label": "CHATHAM" }
    , { "code": "039", "label": "CHEROKEE" }
    , { "code": "041", "label": "CHOWAN" }
    , { "code": "043", "label": "CLAY" }
    , { "code": "045", "label": "CLEVELAND" }
    , { "code": "047", "label": "COLUMBUS" }
    , { "code": "049", "label": "CRAVEN" }
    , { "code": "051", "label": "CUMBERLAND" }
    , { "code": "053", "label": "CURRITUCK" }
    , { "code": "253", "label": "CURRITUCK STATE" }
    , { "code": "055", "label": "DARE" }
    , { "code": "255", "label": "DARE STATE WATE" }
    , { "code": "057", "label": "DAVIDSON" }
    , { "code": "059", "label": "DAVIE" }
    , { "code": "061", "label": "DUPLIN" }
    , { "code": "063", "label": "DURHAM" }
    , { "code": "065", "label": "EDGECOMBE" }
    , { "code": "067", "label": "FORSYTH" }
    , { "code": "069", "label": "FRANKLIN" }
    , { "code": "071", "label": "GASTON" }
    , { "code": "073", "label": "GATES" }
    , { "code": "075", "label": "GRAHAM" }
    , { "code": "077", "label": "GRANVILLE" }
    , { "code": "079", "label": "GREENE" }
    , { "code": "081", "label": "GUILFORD" }
    , { "code": "083", "label": "HALIFAX" }
    , { "code": "085", "label": "HARNETT" }
    , { "code": "087", "label": "HAYWOOD" }
    , { "code": "089", "label": "HENDERSON" }
    , { "code": "091", "label": "HERTFORD" }
    , { "code": "093", "label": "HOKE" }
    , { "code": "095", "label": "HYDE" }
    , { "code": "295", "label": "HYDE STATE WATE" }
    , { "code": "097", "label": "IREDELL" }
    , { "code": "099", "label": "JACKSON" }
    , { "code": "101", "label": "JOHNSTON" }
    , { "code": "103", "label": "JONES" }
    , { "code": "105", "label": "LEE" }
    , { "code": "107", "label": "LENOIR" }
    , { "code": "109", "label": "LINCOLN" }
    , { "code": "113", "label": "MACON" }
    , { "code": "115", "label": "MADISON" }
    , { "code": "117", "label": "MARTIN" }
    , { "code": "111", "label": "MC DOWELL" }
    , { "code": "119", "label": "MECKLENBURG" }
    , { "code": "121", "label": "MITCHELL" }
    , { "code": "123", "label": "MONTGOMERY" }
    , { "code": "125", "label": "MOORE" }
    , { "code": "127", "label": "NASH" }
    , { "code": "129", "label": "NEW HANOVER" }
    , { "code": "329", "label": "NEW HANOVER STA" }
    , { "code": "131", "label": "NORTHAMPTON" }
    , { "code": "133", "label": "ONSLOW" }
    , { "code": "333", "label": "ONSLOW STATE WA" }
    , { "code": "135", "label": "ORANGE" }
    , { "code": "137", "label": "PAMLICO" }
    , { "code": "139", "label": "PASQUOTANK" }
    , { "code": "141", "label": "PENDER" }
    , { "code": "341", "label": "PENDER STATE WA" }
    , { "code": "143", "label": "PERQUIMANS" }
    , { "code": "145", "label": "PERSON" }
    , { "code": "147", "label": "PITT" }
    , { "code": "149", "label": "POLK" }
    , { "code": "151", "label": "RANDOLPH" }
    , { "code": "153", "label": "RICHMOND" }
    , { "code": "155", "label": "ROBESON" }
    , { "code": "157", "label": "ROCKINGHAM" }
    , { "code": "159", "label": "ROWAN" }
    , { "code": "161", "label": "RUTHERFORD" }
    , { "code": "163", "label": "SAMPSON" }
    , { "code": "165", "label": "SCOTLAND" }
    , { "code": "167", "label": "STANLY" }
    , { "code": "169", "label": "STOKES" }
    , { "code": "171", "label": "SURRY" }
    , { "code": "173", "label": "SWAIN" }
    , { "code": "175", "label": "TRANSYLVANIA" }
    , { "code": "177", "label": "TYRRELL" }
    , { "code": "179", "label": "UNION" }
    , { "code": "181", "label": "VANCE" }
    , { "code": "183", "label": "WAKE" }
    , { "code": "185", "label": "WARREN" }
    , { "code": "187", "label": "WASHINGTON" }
    , { "code": "189", "label": "WATAUGA" }
    , { "code": "191", "label": "WAYNE" }
    , { "code": "193", "label": "WILKES" }
    , { "code": "195", "label": "WILSON" }
    , { "code": "197", "label": "YADKIN" }
    , { "code": "199", "label": "YANCEY" }
  ],
  "ND": [
    { "code": "001", "label": "ADAMS" }
    , { "code": "003", "label": "BARNES" }
    , { "code": "005", "label": "BENSON" }
    , { "code": "007", "label": "BILLINGS" }
    , { "code": "009", "label": "BOTTINEAU" }
    , { "code": "011", "label": "BOWMAN" }
    , { "code": "013", "label": "BURKE" }
    , { "code": "015", "label": "BURLEIGH" }
    , { "code": "017", "label": "CASS" }
    , { "code": "019", "label": "CAVALIER" }
    , { "code": "021", "label": "DICKEY" }
    , { "code": "023", "label": "DIVIDE" }
    , { "code": "025", "label": "DUNN" }
    , { "code": "027", "label": "EDDY" }
    , { "code": "029", "label": "EMMONS" }
    , { "code": "031", "label": "FOSTER" }
    , { "code": "033", "label": "GOLDEN VALLEY" }
    , { "code": "037", "label": "GRAND" }
    , { "code": "035", "label": "GRAND FORKS" }
    , { "code": "039", "label": "GRIGGS" }
    , { "code": "041", "label": "HETTINGER" }
    , { "code": "043", "label": "KIDDER" }
    , { "code": "045", "label": "LA MOURE" }
    , { "code": "047", "label": "LOGAN" }
    , { "code": "049", "label": "MC HENRY" }
    , { "code": "051", "label": "MC INTOSH" }
    , { "code": "053", "label": "MC KENZIE" }
    , { "code": "055", "label": "MC LEAN" }
    , { "code": "057", "label": "MERCER" }
    , { "code": "059", "label": "MORTON" }
    , { "code": "061", "label": "MOUNTRAIL" }
    , { "code": "063", "label": "NELSON" }
    , { "code": "065", "label": "OLIVER" }
    , { "code": "067", "label": "PEMBINA" }
    , { "code": "069", "label": "PIERCE" }
    , { "code": "071", "label": "RAMSEY" }
    , { "code": "073", "label": "RANSON" }
    , { "code": "075", "label": "RENVILLE" }
    , { "code": "077", "label": "RICHLAND" }
    , { "code": "079", "label": "ROLETTE" }
    , { "code": "081", "label": "SARGENT" }
    , { "code": "083", "label": "SHERIDAN" }
    , { "code": "085", "label": "SIOUX" }
    , { "code": "087", "label": "SLOPE" }
    , { "code": "089", "label": "STARK" }
    , { "code": "091", "label": "STEELE" }
    , { "code": "093", "label": "STUTSMAN" }
    , { "code": "095", "label": "TOWNER" }
    , { "code": "097", "label": "TRAILL" }
    , { "code": "099", "label": "WALSH" }
    , { "code": "101", "label": "WARD" }
    , { "code": "103", "label": "WELLS" }
    , { "code": "105", "label": "WILLIAMS" }
  ],
  "NE": [
    { "code": "001", "label": "ADAMS" }
    , { "code": "003", "label": "ANTELOPE" }
    , { "code": "005", "label": "ARTHUR" }
    , { "code": "007", "label": "BANNER" }
    , { "code": "009", "label": "BLAINE" }
    , { "code": "011", "label": "BOONE" }
    , { "code": "013", "label": "BOX BUTTE" }
    , { "code": "015", "label": "BOYD" }
    , { "code": "017", "label": "BROWN" }
    , { "code": "019", "label": "BUFFALO" }
    , { "code": "021", "label": "BURT" }
    , { "code": "023", "label": "BUTLER" }
    , { "code": "025", "label": "CASS" }
    , { "code": "027", "label": "CEDAR" }
    , { "code": "029", "label": "CHASE" }
    , { "code": "031", "label": "CHERRY" }
    , { "code": "033", "label": "CHEYENNE" }
    , { "code": "035", "label": "CLAY" }
    , { "code": "037", "label": "COLFAX" }
    , { "code": "039", "label": "CUMING" }
    , { "code": "041", "label": "CUSTER" }
    , { "code": "043", "label": "DAKOTA" }
    , { "code": "045", "label": "DAWES" }
    , { "code": "047", "label": "DAWSON" }
    , { "code": "049", "label": "DEUEL" }
    , { "code": "051", "label": "DIXON" }
    , { "code": "053", "label": "DODGE" }
    , { "code": "055", "label": "DOUGLAS" }
    , { "code": "057", "label": "DUNDY" }
    , { "code": "059", "label": "FILLMORE" }
    , { "code": "061", "label": "FRANKLIN" }
    , { "code": "063", "label": "FRONTIER" }
    , { "code": "065", "label": "FURNAS" }
    , { "code": "067", "label": "GAGE" }
    , { "code": "069", "label": "GARDEN" }
    , { "code": "071", "label": "GARFIELD" }
    , { "code": "073", "label": "GOSPER" }
    , { "code": "075", "label": "GRANT" }
    , { "code": "077", "label": "GREELEY" }
    , { "code": "079", "label": "HALL" }
    , { "code": "081", "label": "HAMILTON" }
    , { "code": "083", "label": "HARLAN" }
    , { "code": "085", "label": "HAYES" }
    , { "code": "087", "label": "HITCHCOCK" }
    , { "code": "089", "label": "HOLT" }
    , { "code": "091", "label": "HOOKER" }
    , { "code": "093", "label": "HOWARD" }
    , { "code": "095", "label": "JEFFERSON" }
    , { "code": "097", "label": "JOHNSON" }
    , { "code": "099", "label": "KEARNEY" }
    , { "code": "101", "label": "KEITH" }
    , { "code": "103", "label": "KEYA PAHA" }
    , { "code": "105", "label": "KIMBALL" }
    , { "code": "107", "label": "KNOX" }
    , { "code": "109", "label": "LANCASTER" }
    , { "code": "111", "label": "LINCOLN" }
    , { "code": "113", "label": "LOGAN" }
    , { "code": "115", "label": "LOUP" }
    , { "code": "119", "label": "MADISON" }
    , { "code": "117", "label": "MC PHERSON" }
    , { "code": "121", "label": "MERRICK" }
    , { "code": "123", "label": "MORRILL" }
    , { "code": "125", "label": "NANCE" }
    , { "code": "127", "label": "NEMEHA" }
    , { "code": "129", "label": "NUCKOLLS" }
    , { "code": "131", "label": "OTOE" }
    , { "code": "133", "label": "PAWNEE" }
    , { "code": "135", "label": "PERKINS" }
    , { "code": "137", "label": "PHELPS" }
    , { "code": "139", "label": "PIERCE" }
    , { "code": "141", "label": "PLATTE" }
    , { "code": "143", "label": "POLK" }
    , { "code": "145", "label": "RED WILLOW" }
    , { "code": "147", "label": "RICHARDSON" }
    , { "code": "149", "label": "ROCK" }
    , { "code": "151", "label": "SALINE" }
    , { "code": "153", "label": "SARPY" }
    , { "code": "155", "label": "SAUNDERS" }
    , { "code": "157", "label": "SCOTTS BLUFF" }
    , { "code": "159", "label": "SEWARD" }
    , { "code": "161", "label": "SHERIDAN" }
    , { "code": "163", "label": "SHERMAN" }
    , { "code": "165", "label": "SIOUX" }
    , { "code": "167", "label": "STANTON" }
    , { "code": "169", "label": "THAYER" }
    , { "code": "171", "label": "THOMAS" }
    , { "code": "173", "label": "THURSTON" }
    , { "code": "175", "label": "VALLEY" }
    , { "code": "177", "label": "WASHINGTON" }
    , { "code": "179", "label": "WAYNE" }
    , { "code": "181", "label": "WEBSTER" }
    , { "code": "183", "label": "WHEELER" }
    , { "code": "185", "label": "YORK" }
  ],
  "NH": [
    { "code": "001", "label": "BELKNAP" }
    , { "code": "003", "label": "CARROLL" }
    , { "code": "005", "label": "CHESHIRE" }
    , { "code": "007", "label": "COOS" }
    , { "code": "009", "label": "GRAFTON" }
    , { "code": "011", "label": "HILLSBOROUGH" }
    , { "code": "013", "label": "MERRIMACK" }
    , { "code": "015", "label": "ROCKINGHAM" }
    , { "code": "215", "label": "ROCKINGHAM STAT" }
    , { "code": "017", "label": "STRAFFORD" }
    , { "code": "019", "label": "SULLIVAN" }
  ],
  "NJ": [
    { "code": "001", "label": "ATLANTIC" }
    , { "code": "201", "label": "ATLANTIC STATE" }
    , { "code": "003", "label": "BERGEN" }
    , { "code": "005", "label": "BURLINGTON" }
    , { "code": "007", "label": "CAMDEN" }
    , { "code": "009", "label": "CAPE      MAY" }
    , { "code": "209", "label": "CAPE MAY STATE" }
    , { "code": "011", "label": "CUMBERLAND" }
    , { "code": "013", "label": "ESSEX" }
    , { "code": "015", "label": "GLOUCESTER" }
    , { "code": "017", "label": "HUDSON" }
    , { "code": "019", "label": "HUNTERDON" }
    , { "code": "021", "label": "MERCER" }
    , { "code": "023", "label": "MIDDLESEX" }
    , { "code": "025", "label": "MONMOUTH" }
    , { "code": "225", "label": "MONMOUTH STATE" }
    , { "code": "027", "label": "MORRIS" }
    , { "code": "029", "label": "OCEAN" }
    , { "code": "229", "label": "OCEAN STATE WAT" }
    , { "code": "777", "label": "OFFSHORE" }
    , { "code": "031", "label": "PASSAIC" }
    , { "code": "033", "label": "SALEM" }
    , { "code": "035", "label": "SOMERSET" }
    , { "code": "037", "label": "SUSSEX" }
    , { "code": "039", "label": "UNION" }
    , { "code": "041", "label": "WARREN" }
  ],
  "NM": [
    { "code": "001", "label": "BERNALILLO" }
    , { "code": "003", "label": "CATRON" }
    , { "code": "005", "label": "CHAVES" }
    , { "code": "006", "label": "CIBOLA" }
    , { "code": "007", "label": "COLFAX" }
    , { "code": "009", "label": "CURRY" }
    , { "code": "011", "label": "DE BACA" }
    , { "code": "013", "label": "DONA ANA" }
    , { "code": "015", "label": "EDDY" }
    , { "code": "017", "label": "GRANT" }
    , { "code": "019", "label": "GUADALUPE" }
    , { "code": "021", "label": "HARDING" }
    , { "code": "023", "label": "HIDALGO" }
    , { "code": "107", "label": "KENNA MESA" }
    , { "code": "025", "label": "LEA" }
    , { "code": "027", "label": "LINCOLN" }
    , { "code": "028", "label": "LOS ALAMOS" }
    , { "code": "029", "label": "LUNA" }
    , { "code": "031", "label": "MCKINLEY" }
    , { "code": "033", "label": "MORA" }
    , { "code": "035", "label": "OTERO" }
    , { "code": "037", "label": "QUAY" }
    , { "code": "039", "label": "RIO ARRIBA" }
    , { "code": "041", "label": "ROOSEVELT" }
    , { "code": "045", "label": "SAN JUAN" }
    , { "code": "047", "label": "SAN MIGUEL" }
    , { "code": "043", "label": "SANDOVAL" }
    , { "code": "049", "label": "SANTA FE" }
    , { "code": "051", "label": "SIERRA" }
    , { "code": "053", "label": "SOCORRO" }
    , { "code": "055", "label": "TAOS" }
    , { "code": "057", "label": "TORRANCE" }
    , { "code": "059", "label": "UNION" }
    , { "code": "061", "label": "VALENCIA" }
  ],
  "NV": [
    { "code": "001", "label": "CHURCHILL" }
    , { "code": "003", "label": "CLARK" }
    , { "code": "005", "label": "DOUGLAS" }
    , { "code": "007", "label": "ELKO" }
    , { "code": "009", "label": "ESMERALDA" }
    , { "code": "011", "label": "EUREKA" }
    , { "code": "013", "label": "HUMBOLDT" }
    , { "code": "015", "label": "LANDER" }
    , { "code": "017", "label": "LINCOLN" }
    , { "code": "019", "label": "LYON" }
    , { "code": "021", "label": "MINERAL" }
    , { "code": "023", "label": "NYE" }
    , { "code": "025", "label": "ORMSBY" }
    , { "code": "027", "label": "PERSHING" }
    , { "code": "029", "label": "STOREY" }
    , { "code": "031", "label": "WASHOE" }
    , { "code": "033", "label": "WHITE PINE" }
  ],
  "NY": [
    { "code": "001", "label": "ALBANY" }
    , { "code": "003", "label": "ALLEGANY" }
    , { "code": "005", "label": "BRONX" }
    , { "code": "007", "label": "BROOME" }
    , { "code": "009", "label": "CATTARAUGUS" }
    , { "code": "011", "label": "CAYUGA" }
    , { "code": "017", "label": "CHANANGO" }
    , { "code": "013", "label": "CHAUTAUQUA" }
    , { "code": "015", "label": "CHEMUNG" }
    , { "code": "019", "label": "CLINTON" }
    , { "code": "021", "label": "COLUMBIA" }
    , { "code": "023", "label": "CORTLAND" }
    , { "code": "025", "label": "DELAWARE" }
    , { "code": "027", "label": "DUTCHESS" }
    , { "code": "029", "label": "ERIE" }
    , { "code": "031", "label": "ESSEX" }
    , { "code": "033", "label": "FRANKLIN" }
    , { "code": "035", "label": "FULTON" }
    , { "code": "037", "label": "GENESEE" }
    , { "code": "039", "label": "GREENE" }
    , { "code": "041", "label": "HAMILTON" }
    , { "code": "043", "label": "HERKIMER" }
    , { "code": "045", "label": "JEFFERSON" }
    , { "code": "047", "label": "KINGS" }
    , { "code": "247", "label": "KINGS STATE WAT" }
    , { "code": "049", "label": "LEWIS" }
    , { "code": "051", "label": "LIVINGSTON" }
    , { "code": "053", "label": "MADISON" }
    , { "code": "055", "label": "MONROE" }
    , { "code": "057", "label": "MONTGOMERY" }
    , { "code": "059", "label": "NASSAU" }
    , { "code": "259", "label": "NASSAU STATE WA" }
    , { "code": "061", "label": "NEW YORK" }
    , { "code": "063", "label": "NIAGARA" }
    , { "code": "065", "label": "ONEIDA" }
    , { "code": "067", "label": "ONONDAGA" }
    , { "code": "069", "label": "ONTARIO" }
    , { "code": "071", "label": "ORANGE" }
    , { "code": "073", "label": "ORLEANS" }
    , { "code": "075", "label": "OSWEGO" }
    , { "code": "077", "label": "OTSEGO" }
    , { "code": "777", "label": "OFFSHORE" }
    , { "code": "079", "label": "PUTNAM" }
    , { "code": "281", "label": "QUEENS STATE WA" }
    , { "code": "081", "label": "QUEENS" }
    , { "code": "083", "label": "RENSSELAER" }
    , { "code": "285", "label": "RICHMOND STATE" }
    , { "code": "087", "label": "ROCKLAND" }
    , { "code": "085", "label": "RICHMOND" }
    , { "code": "091", "label": "SARATOGA" }
    , { "code": "093", "label": "SCHENECTADY" }
    , { "code": "095", "label": "SCHOHARIE" }
    , { "code": "097", "label": "SCHUYLER" }
    , { "code": "099", "label": "SENECA" }
    , { "code": "089", "label": "ST. LAWRENCE" }
    , { "code": "101", "label": "STEUBEN" }
    , { "code": "103", "label": "SUFFOLK" }
    , { "code": "303", "label": "SUFFOLK STATE W" }
    , { "code": "105", "label": "SULLIVAN" }
    , { "code": "107", "label": "TIOGA" }
    , { "code": "109", "label": "TOMPKINS" }
    , { "code": "111", "label": "ULSTER" }
    , { "code": "113", "label": "WARREN" }
    , { "code": "115", "label": "WASHINGTON" }
    , { "code": "117", "label": "WAYNE" }
    , { "code": "119", "label": "WESTCHESTER" }
    , { "code": "121", "label": "WYOMING" }
    , { "code": "123", "label": "YATES" }
  ],
  "OH": [
    { "code": "001", "label": "ADAMS" }
    , { "code": "003", "label": "ALLEN" }
    , { "code": "005", "label": "ASHLAND" }
    , { "code": "007", "label": "ASHTABULA" }
    , { "code": "009", "label": "ATHENS" }
    , { "code": "011", "label": "AUGLAIZE" }
    , { "code": "013", "label": "BELMONT" }
    , { "code": "015", "label": "BROWN" }
    , { "code": "017", "label": "BUTLER" }
    , { "code": "019", "label": "CARROLL" }
    , { "code": "021", "label": "CHAMPAIGN" }
    , { "code": "023", "label": "CLARK" }
    , { "code": "025", "label": "CLERMONT" }
    , { "code": "027", "label": "CLINTON" }
    , { "code": "029", "label": "COLUMBIANA" }
    , { "code": "031", "label": "COSHOCTON" }
    , { "code": "033", "label": "CRAWFORD" }
    , { "code": "035", "label": "CUYAHOGA" }
    , { "code": "037", "label": "DARKE" }
    , { "code": "039", "label": "DEFIANCE" }
    , { "code": "041", "label": "DELAWARE" }
    , { "code": "043", "label": "ERIE" }
    , { "code": "045", "label": "FAIRFIELD" }
    , { "code": "047", "label": "FAYETTE" }
    , { "code": "049", "label": "FRANKLIN" }
    , { "code": "051", "label": "FULTON" }
    , { "code": "053", "label": "GALLIA" }
    , { "code": "055", "label": "GEAUGA" }
    , { "code": "057", "label": "GREENE" }
    , { "code": "059", "label": "GUERNSEY" }
    , { "code": "061", "label": "HAMILTON" }
    , { "code": "063", "label": "HANCOCK" }
    , { "code": "065", "label": "HARDIN" }
    , { "code": "067", "label": "HARRISON" }
    , { "code": "069", "label": "HENRY" }
    , { "code": "071", "label": "HIGHLAND" }
    , { "code": "073", "label": "HOCKING" }
    , { "code": "075", "label": "HOLMES" }
    , { "code": "077", "label": "HURON" }
    , { "code": "079", "label": "JACKSON" }
    , { "code": "081", "label": "JEFFERSON" }
    , { "code": "083", "label": "KNOX" }
    , { "code": "085", "label": "LAKE" }
    , { "code": "087", "label": "LAWRENCE" }
    , { "code": "089", "label": "LICKING" }
    , { "code": "091", "label": "LOGAN" }
    , { "code": "093", "label": "LORAIN" }
    , { "code": "095", "label": "LUCAS" }
    , { "code": "097", "label": "MADISON" }
    , { "code": "099", "label": "MAHONING" }
    , { "code": "101", "label": "MARION" }
    , { "code": "103", "label": "MEDINA" }
    , { "code": "105", "label": "MEIGS" }
    , { "code": "107", "label": "MERCER" }
    , { "code": "109", "label": "MIAMI" }
    , { "code": "111", "label": "MONROE" }
    , { "code": "113", "label": "MONTGOMERY" }
    , { "code": "115", "label": "MORGAN" }
    , { "code": "117", "label": "MORROW" }
    , { "code": "119", "label": "MUSKINGUM" }
    , { "code": "121", "label": "NOBLE" }
    , { "code": "123", "label": "OTTAWA" }
    , { "code": "125", "label": "PAULDING" }
    , { "code": "127", "label": "PERRY" }
    , { "code": "129", "label": "PICKAWAY" }
    , { "code": "131", "label": "PIKE" }
    , { "code": "133", "label": "PORTAGE" }
    , { "code": "135", "label": "PREBLE" }
    , { "code": "137", "label": "PUTNAM" }
    , { "code": "139", "label": "RICHLAND" }
    , { "code": "141", "label": "ROSS" }
    , { "code": "143", "label": "SANDUSKY" }
    , { "code": "145", "label": "SCIOTO" }
    , { "code": "147", "label": "SENECA" }
    , { "code": "149", "label": "SHELBY" }
    , { "code": "151", "label": "STARK" }
    , { "code": "153", "label": "SUMMIT" }
    , { "code": "155", "label": "TRUMBULL" }
    , { "code": "157", "label": "TUSCARAWAS" }
    , { "code": "159", "label": "UNION" }
    , { "code": "161", "label": "VAN WERT" }
    , { "code": "163", "label": "VINTON" }
    , { "code": "165", "label": "WARREN" }
    , { "code": "167", "label": "WASHINGTON" }
    , { "code": "169", "label": "WAYNE" }
    , { "code": "171", "label": "WILLIAMS" }
    , { "code": "173", "label": "WOOD" }
    , { "code": "175", "label": "WYANDOT" }
  ],
  "OK": [
    { "code": "001", "label": "ADAIR" }
    , { "code": "003", "label": "ALFALFA" }
    , { "code": "005", "label": "ATOKA" }
    , { "code": "007", "label": "BEAVER" }
    , { "code": "009", "label": "BECKHAM" }
    , { "code": "011", "label": "BLAINE" }
    , { "code": "013", "label": "BRYAN" }
    , { "code": "015", "label": "CADDO" }
    , { "code": "017", "label": "CANADIAN" }
    , { "code": "019", "label": "CARTER" }
    , { "code": "021", "label": "CHEROKEE" }
    , { "code": "023", "label": "CHOCTAW" }
    , { "code": "025", "label": "CIMARRON" }
    , { "code": "027", "label": "CLEVELAND" }
    , { "code": "029", "label": "COAL" }
    , { "code": "031", "label": "COMANCHE" }
    , { "code": "033", "label": "COTTON" }
    , { "code": "035", "label": "CRAIG" }
    , { "code": "037", "label": "CREEK" }
    , { "code": "039", "label": "CUSTER" }
    , { "code": "041", "label": "DELAWARE" }
    , { "code": "043", "label": "DEWEY" }
    , { "code": "045", "label": "ELLIS" }
    , { "code": "047", "label": "GARFIELD" }
    , { "code": "049", "label": "GARVIN" }
    , { "code": "051", "label": "GRADY" }
    , { "code": "053", "label": "GRANT" }
    , { "code": "055", "label": "GREER" }
    , { "code": "057", "label": "HARMON" }
    , { "code": "059", "label": "HARPER" }
    , { "code": "061", "label": "HASKELL" }
    , { "code": "063", "label": "HUGHES" }
    , { "code": "065", "label": "JACKSON" }
    , { "code": "067", "label": "JEFFERSON" }
    , { "code": "069", "label": "JOHNSTON" }
    , { "code": "071", "label": "KAY" }
    , { "code": "073", "label": "KINGFISHER" }
    , { "code": "075", "label": "KIOWA" }
    , { "code": "077", "label": "LATIMER" }
    , { "code": "079", "label": "LE FLORE" }
    , { "code": "081", "label": "LINCOLN" }
    , { "code": "083", "label": "LOGAN" }
    , { "code": "085", "label": "LOVE" }
    , { "code": "093", "label": "MAJOR" }
    , { "code": "095", "label": "MARSHALL" }
    , { "code": "097", "label": "MAYES" }
    , { "code": "087", "label": "MC CLAIN" }
    , { "code": "089", "label": "MC CURTAIN" }
    , { "code": "091", "label": "MC INTOSH" }
    , { "code": "099", "label": "MURRAY" }
    , { "code": "101", "label": "MUSKOGEE" }
    , { "code": "103", "label": "NOBLE" }
    , { "code": "105", "label": "NOWATA" }
    , { "code": "107", "label": "OKFUSKEE" }
    , { "code": "109", "label": "OKLAHOMA" }
    , { "code": "111", "label": "OKMULGEE" }
    , { "code": "113", "label": "OSAGE" }
    , { "code": "115", "label": "OTTAWA" }
    , { "code": "117", "label": "PAWNEE" }
    , { "code": "119", "label": "PAYNE" }
    , { "code": "121", "label": "PITTSBURG" }
    , { "code": "123", "label": "PONTOTOC" }
    , { "code": "125", "label": "POTTAWATOMIE" }
    , { "code": "127", "label": "PUSHMATAHA" }
    , { "code": "129", "label": "ROGER MILLS" }
    , { "code": "131", "label": "ROGERS" }
    , { "code": "133", "label": "SEMINOLE" }
    , { "code": "135", "label": "SEQUOYAH" }
    , { "code": "137", "label": "STEPHENS" }
    , { "code": "139", "label": "TEXAS" }
    , { "code": "141", "label": "TILLMAN" }
    , { "code": "143", "label": "TULSA" }
    , { "code": "145", "label": "WAGONER" }
    , { "code": "147", "label": "WASHINGTON" }
    , { "code": "149", "label": "WASHITA" }
    , { "code": "151", "label": "WOODS" }
    , { "code": "153", "label": "WOODWARD" }
  ],
  "OR": [
    { "code": "001", "label": "BAKER" }
    , { "code": "003", "label": "BENTON" }
    , { "code": "005", "label": "CLACKAMAS" }
    , { "code": "007", "label": "CLATSOP" }
    , { "code": "207", "label": "CLATSOP STATE W" }
    , { "code": "009", "label": "COLUMBIA" }
    , { "code": "011", "label": "COOS" }
    , { "code": "211", "label": "COOS STATE WATE" }
    , { "code": "013", "label": "CROOK" }
    , { "code": "015", "label": "CURRY" }
    , { "code": "215", "label": "CURRY STATE WAT" }
    , { "code": "017", "label": "DESCHUTES" }
    , { "code": "019", "label": "DOUGLAS" }
    , { "code": "219", "label": "DOUGLAS STATE W" }
    , { "code": "021", "label": "GILLIAM" }
    , { "code": "023", "label": "GRANT" }
    , { "code": "025", "label": "HARNEY" }
    , { "code": "027", "label": "HOOD RIVER" }
    , { "code": "029", "label": "JACKSON" }
    , { "code": "031", "label": "JEFFERSON" }
    , { "code": "033", "label": "JOSEPHINE" }
    , { "code": "035", "label": "KLAMATH" }
    , { "code": "037", "label": "LAKE" }
    , { "code": "039", "label": "LANE" }
    , { "code": "239", "label": "LANE STATE WATE" }
    , { "code": "041", "label": "LINCOLN" }
    , { "code": "241", "label": "LINCOLN STATE W" }
    , { "code": "043", "label": "LINN" }
    , { "code": "045", "label": "MALHEUR" }
    , { "code": "047", "label": "MARION" }
    , { "code": "049", "label": "MORROW" }
    , { "code": "051", "label": "MULTNOMAH" }
    , { "code": "053", "label": "POLK" }
    , { "code": "055", "label": "SHERMAN" }
    , { "code": "XXX", "label": "TEST" }
    , { "code": "057", "label": "TILLAMOOK" }
    , { "code": "257", "label": "TILLAMOOK STATE" }
    , { "code": "059", "label": "UMATILLA" }
    , { "code": "061", "label": "UNION" }
    , { "code": "063", "label": "WALLOWA" }
    , { "code": "065", "label": "WASCO" }
    , { "code": "067", "label": "WASHINGTON" }
    , { "code": "069", "label": "WHEELER" }
    , { "code": "071", "label": "YAMHILL" }
  ],
  "PA": [
    { "code": "001", "label": "ADAMS" }
    , { "code": "003", "label": "ALLEGHENY" }
    , { "code": "005", "label": "ARMSTRONG" }
    , { "code": "007", "label": "BEAVER" }
    , { "code": "009", "label": "BEDFORD" }
    , { "code": "011", "label": "BERKS" }
    , { "code": "013", "label": "BLAIR" }
    , { "code": "015", "label": "BRADFORD" }
    , { "code": "017", "label": "BUCKS" }
    , { "code": "019", "label": "BUTLER" }
    , { "code": "021", "label": "CAMBRIA" }
    , { "code": "023", "label": "CAMERON" }
    , { "code": "025", "label": "CARBON" }
    , { "code": "027", "label": "CENTRE" }
    , { "code": "029", "label": "CHESTER" }
    , { "code": "031", "label": "CLARION" }
    , { "code": "033", "label": "CLEARFIELD" }
    , { "code": "035", "label": "CLINTON" }
    , { "code": "037", "label": "COLUMBIA" }
    , { "code": "039", "label": "CRAWFORD" }
    , { "code": "041", "label": "CUMBERLAND" }
    , { "code": "043", "label": "DAUPHIN" }
    , { "code": "045", "label": "DELAWARE" }
    , { "code": "047", "label": "ELK" }
    , { "code": "049", "label": "ERIE" }
    , { "code": "051", "label": "FAYETTE" }
    , { "code": "053", "label": "FOREST" }
    , { "code": "055", "label": "FRANKLIN" }
    , { "code": "057", "label": "FULTON" }
    , { "code": "059", "label": "GREENE" }
    , { "code": "061", "label": "HUNTINGDON" }
    , { "code": "063", "label": "INDIANA" }
    , { "code": "665", "label": "JEFFERSON" }
    , { "code": "067", "label": "JUNIATA" }
    , { "code": "065", "label": "JEFFERSON" }
    , { "code": "069", "label": "LACKAWANNA" }
    , { "code": "071", "label": "LANCASTER" }
    , { "code": "073", "label": "LAWRENCE" }
    , { "code": "075", "label": "LEBANON" }
    , { "code": "077", "label": "LEHIGH" }
    , { "code": "079", "label": "LUZERNE" }
    , { "code": "081", "label": "LYCOMING" }
    , { "code": "083", "label": "MC KEAN" }
    , { "code": "085", "label": "MERCER" }
    , { "code": "087", "label": "MIFFLIN" }
    , { "code": "089", "label": "MONROE" }
    , { "code": "091", "label": "MONTGOMERY" }
    , { "code": "093", "label": "MONTOUR" }
    , { "code": "095", "label": "NORTHHAMPTON" }
    , { "code": "097", "label": "NORTHUMBERLAND" }
    , { "code": "099", "label": "PERRY" }
    , { "code": "101", "label": "PHILADELPHIA" }
    , { "code": "103", "label": "PIKE" }
    , { "code": "105", "label": "POTTER" }
    , { "code": "107", "label": "SCHUYLKILL" }
    , { "code": "109", "label": "SNYDER" }
    , { "code": "111", "label": "SOMERSET" }
    , { "code": "113", "label": "SULLIVAN" }
    , { "code": "115", "label": "SUSQUEHANNA" }
    , { "code": "117", "label": "TIOGA" }
    , { "code": "119", "label": "UNION" }
    , { "code": "121", "label": "VENANGO" }
    , { "code": "123", "label": "WARREN" }
    , { "code": "125", "label": "WASHINGTON" }
    , { "code": "127", "label": "WAYNE" }
    , { "code": "129", "label": "WESTMORELAND" }
    , { "code": "131", "label": "WYOMING" }
    , { "code": "133", "label": "YORK" }
  ],
  "RI": [
    { "code": "001", "label": "BRISTOL" }
    , { "code": "003", "label": "KENT" }
    , { "code": "005", "label": "NEWPORT" }
    , { "code": "205", "label": "NEWPORT STATE W" }
    , { "code": "007", "label": "PROVIDENCE" }
    , { "code": "009", "label": "WASHINGTON" }
    , { "code": "209", "label": "WASHINGTON STAT" }
  ],
  "SC": [
    { "code": "001", "label": "ABBEVILLE" }
    , { "code": "003", "label": "AIKEN" }
    , { "code": "005", "label": "ALLENDALE" }
    , { "code": "007", "label": "ANDERSON" }
    , { "code": "009", "label": "BAMBERG" }
    , { "code": "011", "label": "BARNWELL" }
    , { "code": "013", "label": "BEAUFORT" }
    , { "code": "213", "label": "BEAUFORT STATE" }
    , { "code": "015", "label": "BERKELEY" }
    , { "code": "017", "label": "CALHOUN" }
    , { "code": "019", "label": "CHARLESTON" }
    , { "code": "219", "label": "CHARLESTON STAT" }
    , { "code": "021", "label": "CHEROKEE" }
    , { "code": "023", "label": "CHESTER" }
    , { "code": "025", "label": "CHESTERFIELD" }
    , { "code": "027", "label": "CLARENDON" }
    , { "code": "029", "label": "COLLETON" }
    , { "code": "229", "label": "COLLETON STATE" }
    , { "code": "031", "label": "DARLINGTON" }
    , { "code": "033", "label": "DILLON" }
    , { "code": "035", "label": "DORCHESTER" }
    , { "code": "037", "label": "EDGEFIELD" }
    , { "code": "039", "label": "FAIRFIELD" }
    , { "code": "041", "label": "FLORENCE" }
    , { "code": "043", "label": "GEORGETOWN" }
    , { "code": "243", "label": "GEORGETOWN STAT" }
    , { "code": "045", "label": "GREENVILLE" }
    , { "code": "047", "label": "GREENWOOD" }
    , { "code": "049", "label": "HAMPTON" }
    , { "code": "051", "label": "HORRY" }
    , { "code": "251", "label": "HORRY STATE WAT" }
    , { "code": "053", "label": "JASPER" }
    , { "code": "055", "label": "KERSHAW" }
    , { "code": "057", "label": "LANCASTER" }
    , { "code": "059", "label": "LAURENS" }
    , { "code": "061", "label": "LEE" }
    , { "code": "063", "label": "LEXINGTON" }
    , { "code": "069", "label": "MARBORO" }
    , { "code": "067", "label": "MARION" }
    , { "code": "065", "label": "MC CORMICK" }
    , { "code": "071", "label": "NEWBERRY" }
    , { "code": "073", "label": "OCONEE" }
    , { "code": "075", "label": "ORANGEBURG" }
    , { "code": "077", "label": "PICKENS" }
    , { "code": "079", "label": "RICHLAND" }
    , { "code": "081", "label": "SALUDA" }
    , { "code": "083", "label": "SPARTANBURG" }
    , { "code": "085", "label": "SUMTER" }
    , { "code": "087", "label": "UNION" }
    , { "code": "089", "label": "WILLIAMSBURG" }
    , { "code": "091", "label": "YORK" }
  ],
  "SD": [
    { "code": "003", "label": "AURORA" }
    , { "code": "005", "label": "BEADLE" }
    , { "code": "007", "label": "BENNETT" }
    , { "code": "009", "label": "BON HOMME" }
    , { "code": "011", "label": "BROOKINGS" }
    , { "code": "013", "label": "BROWN" }
    , { "code": "015", "label": "BRULE" }
    , { "code": "017", "label": "BUFFALO" }
    , { "code": "019", "label": "BUTTE" }
    , { "code": "021", "label": "CAMPBELL" }
    , { "code": "023", "label": "CHARLES MIX" }
    , { "code": "025", "label": "CLARK" }
    , { "code": "027", "label": "CLAY" }
    , { "code": "029", "label": "CODINGTON" }
    , { "code": "031", "label": "CORSON" }
    , { "code": "033", "label": "CUSTER" }
    , { "code": "035", "label": "DAVISON" }
    , { "code": "037", "label": "DAY" }
    , { "code": "039", "label": "DEUEL" }
    , { "code": "041", "label": "DEWEY" }
    , { "code": "043", "label": "DOUGLAS" }
    , { "code": "045", "label": "EDMUNDS" }
    , { "code": "047", "label": "FALL RIVER" }
    , { "code": "049", "label": "FAULK" }
    , { "code": "051", "label": "GRANT" }
    , { "code": "053", "label": "GREGORY" }
    , { "code": "055", "label": "HAAKON" }
    , { "code": "057", "label": "HAMLIN" }
    , { "code": "059", "label": "HAND" }
    , { "code": "061", "label": "HANSON" }
    , { "code": "063", "label": "HARDING" }
    , { "code": "065", "label": "HUGHES" }
    , { "code": "067", "label": "HUTCHINSON" }
    , { "code": "069", "label": "HYDE" }
    , { "code": "071", "label": "JACKSON" }
    , { "code": "073", "label": "JERAULD" }
    , { "code": "075", "label": "JONES" }
    , { "code": "077", "label": "KINGSBURY" }
    , { "code": "079", "label": "LAKE" }
    , { "code": "081", "label": "LAWRENCE" }
    , { "code": "083", "label": "LINCOLN" }
    , { "code": "085", "label": "LYMAN" }
    , { "code": "091", "label": "MARSHALL" }
    , { "code": "087", "label": "MC COOK" }
    , { "code": "089", "label": "MC PHERSON" }
    , { "code": "093", "label": "MEADE" }
    , { "code": "095", "label": "MELLETTE" }
    , { "code": "097", "label": "MINER" }
    , { "code": "099", "label": "MINNEHAHA" }
    , { "code": "101", "label": "MOODY" }
    , { "code": "102", "label": "OGLALA LAKOTA" }
    , { "code": "103", "label": "PENNINGTON" }
    , { "code": "105", "label": "PERKINS" }
    , { "code": "107", "label": "POTTER" }
    , { "code": "109", "label": "ROBERTS" }
    , { "code": "111", "label": "SANBORN" }
    , { "code": "115", "label": "SPINK" }
    , { "code": "117", "label": "STANLEY" }
    , { "code": "119", "label": "SULLY" }
    , { "code": "121", "label": "TODD" }
    , { "code": "123", "label": "TRIPP" }
    , { "code": "125", "label": "TURNER" }
    , { "code": "127", "label": "UNION" }
    , { "code": "129", "label": "WALWORTH" }
    , { "code": "131", "label": "WASHABAUGH" }
    , { "code": "135", "label": "YANKTON" }
    , { "code": "137", "label": "ZIEBACH" }
  ],
  "TN": [
    { "code": "001", "label": "ANDERSON" }
    , { "code": "003", "label": "BEDFORD" }
    , { "code": "005", "label": "BENTON" }
    , { "code": "007", "label": "BLEDSOE" }
    , { "code": "009", "label": "BLOUNT" }
    , { "code": "011", "label": "BRADLEY" }
    , { "code": "013", "label": "CAMPBELL" }
    , { "code": "015", "label": "CANNON" }
    , { "code": "017", "label": "CARROLL" }
    , { "code": "019", "label": "CARTER" }
    , { "code": "021", "label": "CHEATHAM" }
    , { "code": "023", "label": "CHESTER" }
    , { "code": "025", "label": "CLAIBORNE" }
    , { "code": "027", "label": "CLAY" }
    , { "code": "029", "label": "COCKE" }
    , { "code": "031", "label": "COFFEE" }
    , { "code": "033", "label": "CROCKETT" }
    , { "code": "035", "label": "CUMBERLAND" }
    , { "code": "041", "label": "DA KALB" }
    , { "code": "037", "label": "DAVIDSON" }
    , { "code": "039", "label": "DECATUR" }
    , { "code": "043", "label": "DICKSON" }
    , { "code": "045", "label": "DYER" }
    , { "code": "047", "label": "FAYETTE" }
    , { "code": "049", "label": "FENTRESS" }
    , { "code": "051", "label": "FRANKLIN" }
    , { "code": "053", "label": "GIBSON" }
    , { "code": "055", "label": "GILES" }
    , { "code": "057", "label": "GRAINGER" }
    , { "code": "059", "label": "GREENE" }
    , { "code": "061", "label": "GRUNDY" }
    , { "code": "063", "label": "HAMBLEN" }
    , { "code": "065", "label": "HAMILTON" }
    , { "code": "067", "label": "HANCOCK" }
    , { "code": "069", "label": "HARDEMAN" }
    , { "code": "071", "label": "HARDIN" }
    , { "code": "073", "label": "HAWKINS" }
    , { "code": "075", "label": "HAYWOOD" }
    , { "code": "077", "label": "HENDERSON" }
    , { "code": "079", "label": "HENRY" }
    , { "code": "081", "label": "HICKMAN" }
    , { "code": "083", "label": "HOUSTON" }
    , { "code": "085", "label": "HUMPHREYS" }
    , { "code": "087", "label": "JACKSON" }
    , { "code": "089", "label": "JEFFERSON" }
    , { "code": "091", "label": "JOHNSON" }
    , { "code": "093", "label": "KNOX" }
    , { "code": "095", "label": "LAKE" }
    , { "code": "097", "label": "LAUDERDALE" }
    , { "code": "099", "label": "LAWRENCE" }
    , { "code": "101", "label": "LEWIS" }
    , { "code": "103", "label": "LINCOLN" }
    , { "code": "105", "label": "LOUDON" }
    , { "code": "111", "label": "MACON" }
    , { "code": "113", "label": "MADISON" }
    , { "code": "115", "label": "MARION" }
    , { "code": "117", "label": "MARSHALL" }
    , { "code": "119", "label": "MAURY" }
    , { "code": "107", "label": "MC MINN" }
    , { "code": "109", "label": "MC NAIRY" }
    , { "code": "121", "label": "MEIGS" }
    , { "code": "123", "label": "MONROE" }
    , { "code": "125", "label": "MONTGOMERY" }
    , { "code": "127", "label": "MOORE" }
    , { "code": "129", "label": "MORGAN" }
    , { "code": "131", "label": "OBION" }
    , { "code": "133", "label": "OVERTON" }
    , { "code": "135", "label": "PERRY" }
    , { "code": "137", "label": "PICKETT" }
    , { "code": "139", "label": "POLK" }
    , { "code": "141", "label": "PUTNAM" }
    , { "code": "143", "label": "RHEA" }
    , { "code": "145", "label": "ROANE" }
    , { "code": "147", "label": "ROBERTSON" }
    , { "code": "149", "label": "RUTHERFORD" }
    , { "code": "151", "label": "SCOTT" }
    , { "code": "153", "label": "SEQUATCHIE" }
    , { "code": "155", "label": "SEVIER" }
    , { "code": "157", "label": "SHELBY" }
    , { "code": "159", "label": "SMITH" }
    , { "code": "161", "label": "STEWART" }
    , { "code": "163", "label": "SULLIVAN" }
    , { "code": "165", "label": "SUMNER" }
    , { "code": "167", "label": "TIPTON" }
    , { "code": "169", "label": "TROUSDALE" }
    , { "code": "171", "label": "UNICOI" }
    , { "code": "173", "label": "UNION" }
    , { "code": "175", "label": "VAN BUREN" }
    , { "code": "177", "label": "WARREN" }
    , { "code": "179", "label": "WASHINGTON" }
    , { "code": "181", "label": "WAYNE" }
    , { "code": "183", "label": "WEAKLEY" }
    , { "code": "185", "label": "WHITE" }
    , { "code": "187", "label": "WILLIAMSON" }
    , { "code": "189", "label": "WILSON" }
  ],
  "TX": [
    { "code": "001", "label": "ANDERSON" }
    , { "code": "003", "label": "ANDREWS" }
    , { "code": "005", "label": "ANGELINA" }
    , { "code": "007", "label": "ARANSAS" }
    , { "code": "009", "label": "ARCHER" }
    , { "code": "011", "label": "ARMSTRONG" }
    , { "code": "013", "label": "ATASCOSA" }
    , { "code": "015", "label": "AUSTIN" }
    , { "code": "017", "label": "BAILEY" }
    , { "code": "019", "label": "BANDERA" }
    , { "code": "021", "label": "BASTROP" }
    , { "code": "023", "label": "BAYLOR" }
    , { "code": "025", "label": "BEE" }
    , { "code": "027", "label": "BELL" }
    , { "code": "029", "label": "BEXAR" }
    , { "code": "031", "label": "BLANCO" }
    , { "code": "033", "label": "BORDEN" }
    , { "code": "035", "label": "BOSQUE" }
    , { "code": "037", "label": "BOWIE" }
    , { "code": "039", "label": "BRAZORIA" }
    , { "code": "041", "label": "BRAZOS" }
    , { "code": "704", "label": "BRAZOS-L.B." }
    , { "code": "604", "label": "BRAZOS-S.B." }
    , { "code": "705", "label": "BRAZOS-SOUTH" }
    , { "code": "043", "label": "BREWSTER" }
    , { "code": "045", "label": "BRISCOE" }
    , { "code": "047", "label": "BROOKS" }
    , { "code": "049", "label": "BROWN" }
    , { "code": "051", "label": "BURLESON" }
    , { "code": "053", "label": "BURNET" }
    , { "code": "055", "label": "CALDWELL" }
    , { "code": "057", "label": "CALHOUN" }
    , { "code": "059", "label": "CALLAHAN" }
    , { "code": "061", "label": "CAMERON" }
    , { "code": "063", "label": "CAMP" }
    , { "code": "065", "label": "CARSON" }
    , { "code": "067", "label": "CASS" }
    , { "code": "069", "label": "CASTRO" }
    , { "code": "071", "label": "CHAMBERS" }
    , { "code": "073", "label": "CHEROKEE" }
    , { "code": "075", "label": "CHILDRESS" }
    , { "code": "077", "label": "CLAY" }
    , { "code": "079", "label": "COCHRAN" }
    , { "code": "081", "label": "COKE" }
    , { "code": "083", "label": "COLEMAN" }
    , { "code": "085", "label": "COLLIN" }
    , { "code": "087", "label": "COLLINGSWORTH" }
    , { "code": "089", "label": "COLORADO" }
    , { "code": "091", "label": "COMAL" }
    , { "code": "093", "label": "COMANCHE" }
    , { "code": "095", "label": "CONCHO" }
    , { "code": "097", "label": "COOKE" }
    , { "code": "099", "label": "CORYELL" }
    , { "code": "101", "label": "COTTLE" }
    , { "code": "103", "label": "CRANE" }
    , { "code": "105", "label": "CROCKETT" }
    , { "code": "107", "label": "CROSBY" }
    , { "code": "109", "label": "CULBERSON" }
    , { "code": "111", "label": "DALLAM" }
    , { "code": "113", "label": "DALLAS" }
    , { "code": "115", "label": "DAWSON" }
    , { "code": "123", "label": "DE WITT" }
    , { "code": "117", "label": "DEAF SMITH" }
    , { "code": "119", "label": "DELTA" }
    , { "code": "121", "label": "DENTON" }
    , { "code": "125", "label": "DICKENS" }
    , { "code": "127", "label": "DIMMIT" }
    , { "code": "129", "label": "DONLEY" }
    , { "code": "131", "label": "DUVAL" }
    , { "code": "133", "label": "EASTLAND" }
    , { "code": "135", "label": "ECTOR" }
    , { "code": "137", "label": "EDWARDS" }
    , { "code": "141", "label": "EL PASO" }
    , { "code": "139", "label": "ELLIS" }
    , { "code": "143", "label": "ERATH" }
    , { "code": "145", "label": "FALLS" }
    , { "code": "147", "label": "FANNIN" }
    , { "code": "149", "label": "FAYETTE" }
    , { "code": "151", "label": "FISHER" }
    , { "code": "153", "label": "FLOYD" }
    , { "code": "155", "label": "FOARD" }
    , { "code": "157", "label": "FORT BEND" }
    , { "code": "159", "label": "FRANKLIN" }
    , { "code": "161", "label": "FREESTONE" }
    , { "code": "163", "label": "FRIO" }
    , { "code": "165", "label": "GAINES" }
    , { "code": "167", "label": "GALVESTON" }
    , { "code": "706", "label": "GALVESTON-L.B." }
    , { "code": "605", "label": "GALVESTON-S.B." }
    , { "code": "707", "label": "GALVESTON-SOUTH" }
    , { "code": "169", "label": "GARZA" }
    , { "code": "171", "label": "GILLESPIE" }
    , { "code": "173", "label": "GLASSCOCK" }
    , { "code": "175", "label": "GOLIAD" }
    , { "code": "177", "label": "GONZALES" }
    , { "code": "179", "label": "GRAY" }
    , { "code": "181", "label": "GRAYSON" }
    , { "code": "183", "label": "GREGG" }
    , { "code": "185", "label": "GRIMES" }
    , { "code": "187", "label": "GUADALUPE" }
    , { "code": "189", "label": "HALE" }
    , { "code": "191", "label": "HALL" }
    , { "code": "193", "label": "HAMILTON" }
    , { "code": "195", "label": "HANSFORD" }
    , { "code": "197", "label": "HARDEMAN" }
    , { "code": "199", "label": "HARDIN" }
    , { "code": "201", "label": "HARRIS" }
    , { "code": "203", "label": "HARRISON" }
    , { "code": "205", "label": "HARTLEY" }
    , { "code": "207", "label": "HASKELL" }
    , { "code": "209", "label": "HAYS" }
    , { "code": "211", "label": "HEMPHILL" }
    , { "code": "213", "label": "HENDERSON" }
    , { "code": "215", "label": "HIDALGO" }
    , { "code": "710", "label": "HIGH IS.-EAST" }
    , { "code": "711", "label": "HIGH IS.-EAST,S" }
    , { "code": "708", "label": "HIGH IS.-L.B." }
    , { "code": "606", "label": "HIGH IS.-S.B." }
    , { "code": "709", "label": "HIGH IS.-SOUTH" }
    , { "code": "217", "label": "HILL" }
    , { "code": "219", "label": "HOCKLEY" }
    , { "code": "221", "label": "HOOD" }
    , { "code": "223", "label": "HOPKINS" }
    , { "code": "225", "label": "HOUSTON" }
    , { "code": "227", "label": "HOWARD" }
    , { "code": "229", "label": "HUDSPETH" }
    , { "code": "231", "label": "HUNT" }
    , { "code": "233", "label": "HUTCHINSON" }
    , { "code": "235", "label": "IRION" }
    , { "code": "237", "label": "JACK" }
    , { "code": "239", "label": "JACKSON" }
    , { "code": "241", "label": "JASPER" }
    , { "code": "243", "label": "JEFF DAVIS" }
    , { "code": "245", "label": "JEFFERSON" }
    , { "code": "247", "label": "JIM HOGG" }
    , { "code": "249", "label": "JIM WELLS" }
    , { "code": "251", "label": "JOHNSON" }
    , { "code": "253", "label": "JONES" }
    , { "code": "255", "label": "KARNES" }
    , { "code": "257", "label": "KAUFMAN" }
    , { "code": "259", "label": "KENDALL" }
    , { "code": "261", "label": "KENEDY" }
    , { "code": "263", "label": "KENT" }
    , { "code": "265", "label": "KERR" }
    , { "code": "267", "label": "KIMBLE" }
    , { "code": "269", "label": "KING" }
    , { "code": "271", "label": "KINNEY" }
    , { "code": "273", "label": "KLEBERG" }
    , { "code": "275", "label": "KNOX" }
    , { "code": "283", "label": "LA SALLE" }
    , { "code": "277", "label": "LAMAR" }
    , { "code": "279", "label": "LAMB" }
    , { "code": "281", "label": "LAMPASAS" }
    , { "code": "285", "label": "LAVACA" }
    , { "code": "287", "label": "LEE" }
    , { "code": "289", "label": "LEON" }
    , { "code": "291", "label": "LIBERTY" }
    , { "code": "293", "label": "LIMESTONE" }
    , { "code": "295", "label": "LIPSCOMB" }
    , { "code": "297", "label": "LIVE OAK" }
    , { "code": "299", "label": "LLANO" }
    , { "code": "301", "label": "LOVING" }
    , { "code": "303", "label": "LUBBOCK" }
    , { "code": "305", "label": "LYNN" }
    , { "code": "313", "label": "MADISON" }
    , { "code": "315", "label": "MARION" }
    , { "code": "317", "label": "MARTIN" }
    , { "code": "319", "label": "MASON" }
    , { "code": "321", "label": "MATAGORDA" }
    , { "code": "703", "label": "MATAGORDA IS.-L" }
    , { "code": "603", "label": "MATAGORDA IS.-S" }
    , { "code": "323", "label": "MAVERICK" }
    , { "code": "307", "label": "MC CULLOCH" }
    , { "code": "309", "label": "MC LENNAN" }
    , { "code": "311", "label": "MC MULLEN" }
    , { "code": "325", "label": "MEDINA" }
    , { "code": "327", "label": "MENARD" }
    , { "code": "329", "label": "MIDLAND" }
    , { "code": "331", "label": "MILAM" }
    , { "code": "333", "label": "MILLS" }
    , { "code": "335", "label": "MITCHELL" }
    , { "code": "337", "label": "MONTAGUE" }
    , { "code": "339", "label": "MONTGOMERY" }
    , { "code": "341", "label": "MOORE" }
    , { "code": "343", "label": "MORRIS" }
    , { "code": "345", "label": "MOTLEY" }
    , { "code": "712", "label": "MUSTANG IS.-EAS" }
    , { "code": "702", "label": "MUSTANG IS.-L.B" }
    , { "code": "602", "label": "MUSTANG IS.-S.B" }
    , { "code": "347", "label": "NACOGDOCHES" }
    , { "code": "349", "label": "NAVARRO" }
    , { "code": "351", "label": "NEWTON" }
    , { "code": "353", "label": "NOLAN" }
    , { "code": "701", "label": "NORTH PADRE IS." }
    , { "code": "713", "label": "NORTH PADRE IS." }
    , { "code": "601", "label": "NORTH PADRE IS." }
    , { "code": "355", "label": "NUECES" }
    , { "code": "357", "label": "OCHILTREE" }
    , { "code": "359", "label": "OLDHAM" }
    , { "code": "361", "label": "ORANGE" }
    , { "code": "777", "label": "OFFSHORE" }
    , { "code": "363", "label": "PALO PINTO" }
    , { "code": "365", "label": "PANOLA" }
    , { "code": "367", "label": "PARKER" }
    , { "code": "369", "label": "PARMER" }
    , { "code": "371", "label": "PECOS" }
    , { "code": "373", "label": "POLK" }
    , { "code": "375", "label": "POTTER" }
    , { "code": "377", "label": "PRESIDIO" }
    , { "code": "379", "label": "RAINS" }
    , { "code": "381", "label": "RANDALL" }
    , { "code": "383", "label": "REAGAN" }
    , { "code": "385", "label": "REAL" }
    , { "code": "387", "label": "RED RIVER" }
    , { "code": "389", "label": "REEVES" }
    , { "code": "391", "label": "REFUGIO" }
    , { "code": "393", "label": "ROBERTS" }
    , { "code": "395", "label": "ROBERTSON" }
    , { "code": "397", "label": "ROCKWALL" }
    , { "code": "399", "label": "RUNNELS" }
    , { "code": "401", "label": "RUSK" }
    , { "code": "403", "label": "SABINE" }
    , { "code": "715", "label": "SABINE PASS" }
    , { "code": "405", "label": "SAN AUGUSTINE" }
    , { "code": "407", "label": "SAN JACINTO" }
    , { "code": "409", "label": "SAN PATRICIO" }
    , { "code": "411", "label": "SAN SABA" }
    , { "code": "413", "label": "SCHLEICHER" }
    , { "code": "415", "label": "SCURRY" }
    , { "code": "417", "label": "SHACKELFORD" }
    , { "code": "419", "label": "SHELBY" }
    , { "code": "421", "label": "SHERMAN" }
    , { "code": "423", "label": "SMITH" }
    , { "code": "425", "label": "SOMERVELL" }
    , { "code": "714", "label": "SOUTH PADRE IS." }
    , { "code": "600", "label": "SOUTH PADRE IS." }
    , { "code": "700", "label": "SOUTH PADRE IS." }
    , { "code": "427", "label": "STARR" }
    , { "code": "429", "label": "STEPHENS" }
    , { "code": "431", "label": "STERLING" }
    , { "code": "433", "label": "STONEWALL" }
    , { "code": "435", "label": "SUTTON" }
    , { "code": "437", "label": "SWISHER" }
    , { "code": "439", "label": "TARRANT" }
    , { "code": "441", "label": "TAYLOR" }
    , { "code": "443", "label": "TERRELL" }
    , { "code": "445", "label": "TERRY" }
    , { "code": "1", "label": "TEST" }
    , { "code": "447", "label": "THROCKMORTON" }
    , { "code": "449", "label": "TITUS" }
    , { "code": "451", "label": "TOM GREEN" }
    , { "code": "453", "label": "TRAVIS" }
    , { "code": "455", "label": "TRINITY" }
    , { "code": "457", "label": "TYLER" }
    , { "code": "459", "label": "UPSHUR" }
    , { "code": "461", "label": "UPTON" }
    , { "code": "463", "label": "UVALDE" }
    , { "code": "465", "label": "VAL VERDE" }
    , { "code": "467", "label": "VAN ZANDT" }
    , { "code": "469", "label": "VICTORIA" }
    , { "code": "471", "label": "WALKER" }
    , { "code": "473", "label": "WALLER" }
    , { "code": "475", "label": "WARD" }
    , { "code": "477", "label": "WASHINGTON" }
    , { "code": "479", "label": "WEBB" }
    , { "code": "481", "label": "WHARTON" }
    , { "code": "483", "label": "WHEELER" }
    , { "code": "485", "label": "WICHITA" }
    , { "code": "487", "label": "WILBARGER" }
    , { "code": "489", "label": "WILLACY" }
    , { "code": "491", "label": "WILLIAMSON" }
    , { "code": "493", "label": "WILSON" }
    , { "code": "495", "label": "WINKLER" }
    , { "code": "497", "label": "WISE" }
    , { "code": "499", "label": "WOOD" }
    , { "code": "501", "label": "YOAKUM" }
    , { "code": "503", "label": "YOUNG" }
    , { "code": "505", "label": "ZAPATA" }
    , { "code": "507", "label": "ZAVALA" }
  ],
  "UT": [
    { "code": "001", "label": "BEAVER" }
    , { "code": "003", "label": "BOX ELDER" }
    , { "code": "005", "label": "CACHE" }
    , { "code": "007", "label": "CARBON" }
    , { "code": "009", "label": "DAGGETT" }
    , { "code": "011", "label": "DAVIS" }
    , { "code": "013", "label": "DUCHESNE" }
    , { "code": "015", "label": "EMERY" }
    , { "code": "017", "label": "GARFIELD" }
    , { "code": "019", "label": "GRAND" }
    , { "code": "021", "label": "IRON" }
    , { "code": "023", "label": "JUAB" }
    , { "code": "025", "label": "KANE" }
    , { "code": "027", "label": "MILLARD" }
    , { "code": "029", "label": "MORGAN" }
    , { "code": "031", "label": "PIUTE" }
    , { "code": "033", "label": "RICH" }
    , { "code": "035", "label": "SALT LAKE" }
    , { "code": "037", "label": "SAN JUAN" }
    , { "code": "039", "label": "SANPETE" }
    , { "code": "041", "label": "SEVIER" }
    , { "code": "043", "label": "SUMMIT" }
    , { "code": "045", "label": "TOOELE" }
    , { "code": "047", "label": "UINTAH" }
    , { "code": "049", "label": "UTAH" }
    , { "code": "051", "label": "WASATCH" }
    , { "code": "053", "label": "WASHINGTON" }
    , { "code": "055", "label": "WAYNE" }
    , { "code": "057", "label": "WEBER" }
  ],
  "VA": [
    { "code": "001", "label": "ACCOMACK" }
    , { "code": "201", "label": "ACCOMACK STATE" }
    , { "code": "002", "label": "ALBEMARLE" }
    , { "code": "013", "label": "ALEXANDRIA" }
    , { "code": "004", "label": "ALLEGHANY" }
    , { "code": "007", "label": "AMELIA" }
    , { "code": "009", "label": "AMHERST" }
    , { "code": "011", "label": "APPOMATTOX" }
    , { "code": "012", "label": "ARLINGTON" }
    , { "code": "014", "label": "AUGUSTA" }
    , { "code": "017", "label": "BATH" }
    , { "code": "019", "label": "BEDFORD" }
    , { "code": "021", "label": "BLAND" }
    , { "code": "023", "label": "BOTETOURT" }
    , { "code": "192", "label": "BRISTOL" }
    , { "code": "025", "label": "BRUNSWICK" }
    , { "code": "027", "label": "BUCHANAN" }
    , { "code": "029", "label": "BUCKINGHAM" }
    , { "code": "163", "label": "BUENA VISTA" }
    , { "code": "031", "label": "CAMPBELL" }
    , { "code": "033", "label": "CAROLINE" }
    , { "code": "035", "label": "CARROLL" }
    , { "code": "037", "label": "CHARLES CITY" }
    , { "code": "039", "label": "CHARLOTTE" }
    , { "code": "003", "label": "CHARLOTTESVILLE" }
    , { "code": "129", "label": "CHESAPEAKE" }
    , { "code": "041", "label": "CHESTERFIELD" }
    , { "code": "043", "label": "CLARKE" }
    , { "code": "006", "label": "CLIFTON FORGE" }
    , { "code": "042", "label": "COLONIAL HEIGHT" }
    , { "code": "005", "label": "COVINGTON" }
    , { "code": "045", "label": "CRAIG" }
    , { "code": "047", "label": "CULPEPER" }
    , { "code": "049", "label": "CUMBERLAND" }
    , { "code": "144", "label": "DANVILLE" }
    , { "code": "051", "label": "DICKENSON" }
    , { "code": "053", "label": "DINWIDDIE" }
    , { "code": "082", "label": "EMPORIA" }
    , { "code": "057", "label": "ESSEX" }
    , { "code": "059", "label": "FAIRFAX" }
    , { "code": "060", "label": "FALLS CHURCH" }
    , { "code": "061", "label": "FAUQUIER" }
    , { "code": "063", "label": "FLOYD" }
    , { "code": "065", "label": "FLUVANNA" }
    , { "code": "176", "label": "FRANKLIN" }
    , { "code": "067", "label": "FRANKLIN" }
    , { "code": "069", "label": "FREDERICK" }
    , { "code": "178", "label": "FREDERICKSBURG" }
    , { "code": "036", "label": "GALAX" }
    , { "code": "071", "label": "GILES" }
    , { "code": "073", "label": "GLOUCESTER" }
    , { "code": "075", "label": "GOOCHLAND" }
    , { "code": "077", "label": "GRAYSON" }
    , { "code": "079", "label": "GREENE" }
    , { "code": "081", "label": "GREENSVILLE" }
    , { "code": "083", "label": "HALIFAX" }
    , { "code": "198", "label": "HAMPTON" }
    , { "code": "085", "label": "HANOVER" }
    , { "code": "166", "label": "HARRISONBURG" }
    , { "code": "087", "label": "HENRICO" }
    , { "code": "089", "label": "HENRY" }
    , { "code": "091", "label": "HIGHLAND" }
    , { "code": "149", "label": "HOPEWELL" }
    , { "code": "055", "label": "HAMPTON CITY" }
    , { "code": "093", "label": "ISLE OF WIGHT" }
    , { "code": "095", "label": "JAMES CITY" }
    , { "code": "097", "label": "KING AND QUEEN" }
    , { "code": "099", "label": "KING GEORGE" }
    , { "code": "101", "label": "KING WILLIAM" }
    , { "code": "103", "label": "LANCASTER" }
    , { "code": "105", "label": "LEE" }
    , { "code": "164", "label": "LEXINGTON" }
    , { "code": "107", "label": "LOUDOUN" }
    , { "code": "109", "label": "LOUISA" }
    , { "code": "111", "label": "LUNENBURG" }
    , { "code": "032", "label": "LYNCHBURG" }
    , { "code": "113", "label": "MADISON" }
    , { "code": "090", "label": "MARTINSVILLE" }
    , { "code": "115", "label": "MATHEWS" }
    , { "code": "117", "label": "MECKLENBURG" }
    , { "code": "119", "label": "MIDDLESEX" }
    , { "code": "121", "label": "MONTGOMERY" }
    , { "code": "123", "label": "NANSEMOND" }
    , { "code": "125", "label": "NELSON" }
    , { "code": "127", "label": "NEW KENT" }
    , { "code": "200", "label": "NEWPORT NEWS" }
    , { "code": "128", "label": "NORFOLK" }
    , { "code": "131", "label": "NORTHAMPTON" }
    , { "code": "331", "label": "NORTHAMPTON STA" }
    , { "code": "133", "label": "NORTHUMBERLAND" }
    , { "code": "196", "label": "NORTON" }
    , { "code": "135", "label": "NOTTOWAY" }
    , { "code": "189", "label": "NEWPORT NEWS CI" }
    , { "code": "137", "label": "ORANGE" }
    , { "code": "139", "label": "PAGE" }
    , { "code": "141", "label": "PATRICK" }
    , { "code": "054", "label": "PETERSBURG" }
    , { "code": "143", "label": "PITTSYLVANIA" }
    , { "code": "130", "label": "PORTSMOUTH" }
    , { "code": "145", "label": "POWHATAN" }
    , { "code": "147", "label": "PRINCE EDWARD" }
    , { "code": "148", "label": "PRINCE GEORGE" }
    , { "code": "153", "label": "PRINCE WILLIAM" }
    , { "code": "150", "label": "PRINCESS ANNE" }
    , { "code": "155", "label": "PULASKI" }
    , { "code": "122", "label": "RADFORD" }
    , { "code": "157", "label": "RAPPAHANNOCK" }
    , { "code": "159", "label": "RICHMOND" }
    , { "code": "088", "label": "RICHMOND" }
    , { "code": "160", "label": "ROANOKE" }
    , { "code": "162", "label": "ROCKBRIDGE" }
    , { "code": "165", "label": "ROCKINGHAM" }
    , { "code": "167", "label": "RUSSELL" }
    , { "code": "161", "label": "SALEM" }
    , { "code": "169", "label": "SCOTT" }
    , { "code": "171", "label": "SHENANDOAH" }
    , { "code": "173", "label": "SMYTH" }
    , { "code": "084", "label": "SOUTH BOSTON" }
    , { "code": "175", "label": "SOUTHAMPTON" }
    , { "code": "177", "label": "SPOTSYLVANIA" }
    , { "code": "179", "label": "STAFFORD" }
    , { "code": "015", "label": "STAUNTON" }
    , { "code": "124", "label": "SUFFOLK" }
    , { "code": "181", "label": "SURRY" }
    , { "code": "183", "label": "SUSSEX" }
    , { "code": "185", "label": "TAZEWELL" }
    , { "code": "151", "label": "VIRGINIA BEACH" }
    , { "code": "351", "label": "VIRGINIA BEACH" }
    , { "code": "186", "label": "VIRGINIA BEACH" }
    , { "code": "187", "label": "WARREN" }
    , { "code": "191", "label": "WASHINGTON" }
    , { "code": "016", "label": "WAYNESBORO" }
    , { "code": "193", "label": "WESTMORELAND" }
    , { "code": "096", "label": "WILLIAMSBURG" }
    , { "code": "070", "label": "WINCHESTER" }
    , { "code": "195", "label": "WISE" }
    , { "code": "197", "label": "WYTHE" }
    , { "code": "199", "label": "YORK" }
    , { "code": "399", "label": "YORK STATE WATE" }
  ],
  "VT": [
    { "code": "001", "label": "ADDISON" }
    , { "code": "003", "label": "BENNINGTON" }
    , { "code": "005", "label": "CALEDONIA" }
    , { "code": "007", "label": "CHITTENDEN" }
    , { "code": "009", "label": "ESSEX" }
    , { "code": "011", "label": "FRANKLIN" }
    , { "code": "013", "label": "GRAND ISLE" }
    , { "code": "015", "label": "LAMOILLE" }
    , { "code": "017", "label": "ORANGE" }
    , { "code": "019", "label": "ORLEANS" }
    , { "code": "021", "label": "RUTLAND" }
    , { "code": "023", "label": "WASHINGTON" }
    , { "code": "025", "label": "WINDHAM" }
    , { "code": "027", "label": "WINDSOR" }
  ],
  "WA": [
    { "code": "001", "label": "ADAMS" }
    , { "code": "003", "label": "ASOTIN" }
    , { "code": "005", "label": "BENTON" }
    , { "code": "007", "label": "CHELAN" }
    , { "code": "009", "label": "CLALLAM" }
    , { "code": "209", "label": "CLALLAM STATE W" }
    , { "code": "011", "label": "CLARK" }
    , { "code": "013", "label": "COLUMBIA" }
    , { "code": "015", "label": "COWLITZ" }
    , { "code": "017", "label": "DOUGLAS" }
    , { "code": "019", "label": "FERRY" }
    , { "code": "021", "label": "FRANKLIN" }
    , { "code": "023", "label": "GARFIELD" }
    , { "code": "025", "label": "GRANT" }
    , { "code": "027", "label": "GRAYS HARBOR" }
    , { "code": "227", "label": "GRAYS HARBOR ST" }
    , { "code": "029", "label": "ISLAND" }
    , { "code": "031", "label": "JEFFERSON" }
    , { "code": "231", "label": "JEFFERSON STATE" }
    , { "code": "033", "label": "KING" }
    , { "code": "035", "label": "KITSAP" }
    , { "code": "037", "label": "KITTITAS" }
    , { "code": "039", "label": "KLICKITAT" }
    , { "code": "041", "label": "LEWIS" }
    , { "code": "043", "label": "LINCOLN" }
    , { "code": "045", "label": "MASON" }
    , { "code": "047", "label": "OKANOGAN" }
    , { "code": "049", "label": "PACIFIC" }
    , { "code": "249", "label": "PACIFIC STATE W" }
    , { "code": "051", "label": "PEND OREILLE" }
    , { "code": "053", "label": "PIERCE" }
    , { "code": "055", "label": "SAN JUAN" }
    , { "code": "057", "label": "SKAGIT" }
    , { "code": "059", "label": "SKAMANIA" }
    , { "code": "061", "label": "SNOHOMISH" }
    , { "code": "063", "label": "SPOKANE" }
    , { "code": "065", "label": "STEVENS" }
    , { "code": "067", "label": "THURSTON" }
    , { "code": "069", "label": "WAHKIAKUM" }
    , { "code": "071", "label": "WALLA WALLA" }
    , { "code": "073", "label": "WHATCOM" }
    , { "code": "075", "label": "WHITMAN" }
    , { "code": "077", "label": "YAKIMA" }
  ],
  "WI": [
    { "code": "001", "label": "ADAMS" }
    , { "code": "003", "label": "ASHLAND" }
    , { "code": "005", "label": "BARRON" }
    , { "code": "007", "label": "BAYFIELD" }
    , { "code": "009", "label": "BROWN" }
    , { "code": "011", "label": "BUFFALO" }
    , { "code": "013", "label": "BURNETT" }
    , { "code": "015", "label": "CALUMET" }
    , { "code": "017", "label": "CHIPPEWA" }
    , { "code": "019", "label": "CLARK" }
    , { "code": "021", "label": "COLUMBIA" }
    , { "code": "023", "label": "CRAWFORD" }
    , { "code": "025", "label": "DANE" }
    , { "code": "027", "label": "DODGE" }
    , { "code": "029", "label": "DOOR" }
    , { "code": "031", "label": "DOUGLAS" }
    , { "code": "033", "label": "DUNN" }
    , { "code": "035", "label": "EAU CLAIRE" }
    , { "code": "037", "label": "FLORENCE" }
    , { "code": "039", "label": "FOND DU LAC" }
    , { "code": "041", "label": "FOREST" }
    , { "code": "043", "label": "GRANT" }
    , { "code": "045", "label": "GREEN" }
    , { "code": "047", "label": "GREEN LAKE" }
    , { "code": "049", "label": "IOWA" }
    , { "code": "051", "label": "IRON" }
    , { "code": "053", "label": "JACKSON" }
    , { "code": "055", "label": "JEFFERSON" }
    , { "code": "057", "label": "JUNEAU" }
    , { "code": "059", "label": "KENOSHA" }
    , { "code": "061", "label": "KEWAUNEE" }
    , { "code": "063", "label": "LA CROSSE" }
    , { "code": "065", "label": "LAFAYETTE" }
    , { "code": "067", "label": "LANGLADE" }
    , { "code": "069", "label": "LINCOLN" }
    , { "code": "071", "label": "MANITOWOC" }
    , { "code": "073", "label": "MARATHON" }
    , { "code": "075", "label": "MARINETTE" }
    , { "code": "077", "label": "MARQUETTE" }
    , { "code": "078", "label": "MENOMINEE" }
    , { "code": "079", "label": "MILWAUKEE" }
    , { "code": "081", "label": "MONROE" }
    , { "code": "083", "label": "OCONTO" }
    , { "code": "085", "label": "ONEIDA" }
    , { "code": "087", "label": "OUTAGAMIE" }
    , { "code": "089", "label": "OZAUKEE" }
    , { "code": "091", "label": "PEPIN" }
    , { "code": "093", "label": "PIERCE" }
    , { "code": "095", "label": "POLK" }
    , { "code": "097", "label": "PORTAGE" }
    , { "code": "099", "label": "PRICE" }
    , { "code": "101", "label": "RACINE" }
    , { "code": "103", "label": "RICHLAND" }
    , { "code": "105", "label": "ROCK" }
    , { "code": "107", "label": "RUSK" }
    , { "code": "111", "label": "SAUK" }
    , { "code": "113", "label": "SAWYER" }
    , { "code": "115", "label": "SHAWANO" }
    , { "code": "117", "label": "SHEBOYGAN" }
    , { "code": "109", "label": "ST CROIX" }
    , { "code": "119", "label": "TAYLOR" }
    , { "code": "121", "label": "TREMPEALEAU" }
    , { "code": "123", "label": "VERNON" }
    , { "code": "125", "label": "VILAS" }
    , { "code": "127", "label": "WALWORTH" }
    , { "code": "129", "label": "WASHBURN" }
    , { "code": "131", "label": "WASHINGTON" }
    , { "code": "133", "label": "WAUKESHA" }
    , { "code": "135", "label": "WAUPACA" }
    , { "code": "137", "label": "WAUSHARA" }
    , { "code": "139", "label": "WINNEBAGO" }
    , { "code": "141", "label": "WOOD" }
  ],
  "WV": [
    { "code": "001", "label": "BARBOUR" }
    , { "code": "003", "label": "BERKELEY" }
    , { "code": "005", "label": "BOONE" }
    , { "code": "007", "label": "BRAXTON" }
    , { "code": "009", "label": "BROOKE" }
    , { "code": "011", "label": "CABELL" }
    , { "code": "013", "label": "CALHOUN" }
    , { "code": "015", "label": "CLAY" }
    , { "code": "017", "label": "DODDRIDGE" }
    , { "code": "019", "label": "FAYETTE" }
    , { "code": "021", "label": "GILMER" }
    , { "code": "023", "label": "GRANT" }
    , { "code": "025", "label": "GREENBRIER" }
    , { "code": "027", "label": "HAMPSHIRE" }
    , { "code": "029", "label": "HANCOCK" }
    , { "code": "031", "label": "HARDY" }
    , { "code": "033", "label": "HARRISON" }
    , { "code": "035", "label": "JACKSON" }
    , { "code": "037", "label": "JEFFERSON" }
    , { "code": "039", "label": "KANAWHA" }
    , { "code": "041", "label": "LEWIS" }
    , { "code": "043", "label": "LINCOLN" }
    , { "code": "045", "label": "LOGAN" }
    , { "code": "049", "label": "MARION" }
    , { "code": "051", "label": "MARSHALL" }
    , { "code": "053", "label": "MASON" }
    , { "code": "047", "label": "MC DOWELL" }
    , { "code": "055", "label": "MERCER" }
    , { "code": "057", "label": "MINERAL" }
    , { "code": "059", "label": "MINGO" }
    , { "code": "061", "label": "MONONGALIA" }
    , { "code": "063", "label": "MONROE" }
    , { "code": "065", "label": "MORGAN" }
    , { "code": "067", "label": "NICHOLAS" }
    , { "code": "069", "label": "OHIO" }
    , { "code": "071", "label": "PENDLETON" }
    , { "code": "073", "label": "PLEASANTS" }
    , { "code": "075", "label": "POCAHONTAS" }
    , { "code": "077", "label": "PRESTON" }
    , { "code": "079", "label": "PUTNAM" }
    , { "code": "081", "label": "RALEIGH" }
    , { "code": "083", "label": "RANDOLPH" }
    , { "code": "085", "label": "RITCHIE" }
    , { "code": "087", "label": "ROANE" }
    , { "code": "089", "label": "SUMMERS" }
    , { "code": "091", "label": "TAYLOR" }
    , { "code": "093", "label": "TUCKER" }
    , { "code": "095", "label": "TYLER" }
    , { "code": "097", "label": "UPSHUR" }
    , { "code": "099", "label": "WAYNE" }
    , { "code": "101", "label": "WEBSTER" }
    , { "code": "103", "label": "WETZEL" }
    , { "code": "105", "label": "WIRT" }
    , { "code": "107", "label": "WOOD" }
    , { "code": "109", "label": "WYOMING" }
  ],
  "WY": [
    { "code": "001", "label": "ALBANY" }
    , { "code": "003", "label": "BIG HORN" }
    , { "code": "005", "label": "CAMPBELL" }
    , { "code": "007", "label": "CARBON" }
    , { "code": "009", "label": "CONVERSE" }
    , { "code": "011", "label": "CROOK" }
    , { "code": "013", "label": "FREMONT" }
    , { "code": "015", "label": "GOSHEN" }
    , { "code": "017", "label": "HOT SPRINGS" }
    , { "code": "019", "label": "JOHNSON" }
    , { "code": "021", "label": "LARAMIE" }
    , { "code": "023", "label": "LINCOLN" }
    , { "code": "025", "label": "NATRONA" }
    , { "code": "027", "label": "NIOBRARA" }
    , { "code": "029", "label": "PARK" }
    , { "code": "031", "label": "PLATTE" }
    , { "code": "033", "label": "SHERIDAN" }
    , { "code": "035", "label": "SUBLETTE" }
    , { "code": "037", "label": "SWEETWATER" }
    , { "code": "039", "label": "TETON" }
    , { "code": "041", "label": "UINTA" }
    , { "code": "043", "label": "WASHAKIE" }
    , { "code": "045", "label": "WESTON" }
    , { "code": "047", "label": "YELLOWSTON NATL" }
  ],
}

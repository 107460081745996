import api from "ui/modules/common/axios";
import { isNotEmpty } from "ui/modules/common/logic/utilities/utilities";

/* *********************************************** */

export const handlePostAuthenticateUser = async(payload) => {
    var results = await authenticate({username:payload.email,password:payload.password});
    return results;
}

export const handleGetAllUsers = async(ev) => {
    var results = await getAllUsers();
    return results;
}

export const handleGetFilteredUsers = async (payload) => {
    var results = await getFilteredUsers(payload);
    return results;
}

export const handleGetUser = async(ev) => {
    ev.preventDefault();
    var results = await getUser();
    return results;
}

export const handleGetLoggedInUser = async(ev) => {
    ev.preventDefault();
    var results = await getLoggedInUser();
    return results;
}

export const handleGetPortalSession = async() => {
    var results = await getPortalSession();
    return results;
}

export const handlePortalLogout = async() => {
    var results = await getPortalLogout();
    return results;
}

/* *********************************************** */
const authenticate = async (payload) => {
    const data = `username=${encodeURIComponent(payload.username)}&password=${encodeURIComponent(payload.password)}&remember-me=${true}&submit=Login`;
    let response:any = {}
    try {
       response = await authenticateCall(data);
       if (isNotEmpty(response) && response.status == 200) {
        let loginUser = await handleGetPortalSession();
        return loginUser;
       };
    } catch {

    };
}

const authenticateCall = async (data) => {
  let response:any ={};
  try {
    response = await api.post<any>('/perform_login', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  } catch (err) {
    if (isNotEmpty(err) && isNotEmpty(err.response) && 
          isNotEmpty(err.response.data) && isNotEmpty(err.response.data.error) && 
          (err.response.data.error == 'Unauthorized')) {
        response = {token:-999,loginUser:{id:-999}};};};
    return response;
};

const getAllUsers = async() => {
    try {
        const response = await api.get('/users').then(response => {return response});
        return response.data;
    } catch (err) {
        console.log(err);
    }
}

const getFilteredUsers = async (payload) => {
    try {
        var searchParams = '';
        if (isNotEmpty(payload)) {
            if (isNotEmpty(payload.userEmail)) {
                searchParams = '?userEmail=' + payload.userEmail;
                if (isNotEmpty(payload.userName)) {
                    searchParams = searchParams + '&userName=' + payload.userName;
                }
            } else if (isNotEmpty(payload.userName)) {
                searchParams = '?userName=' + payload.userName;
            }
        }
        const response = await api.get('/users/filtered' + searchParams);
        return response.data;
    } catch (err) {
        console.log(err);
    }
}

const getUser = async() => {
    try {
        const response = await api.get('/users/testuser2@bia.gov').then(response => {return response});
        return response;
    } catch (err) {
        console.log(err);
    }
}

const getLoggedInUser = async() => {
    try {
        const response = await api.get('/loggedInUser').then(response => {return response});
        return response;
    } catch (err) {
        console.log(err);
    }
}

const getPortalSession = async() => {
    let response:any = {};
    try {
        response = await api.get('/portalSession').then(response => {return response});
        return response;
    } catch (err) {
        if (isNotEmpty(err) && isNotEmpty(err.response) && isNotEmpty(err.response.data) &&
           (err.response.data == "User is not authenticated User could not be found")) {
           console.log('Detected user is not logged in');   
           response = {token:-999,loginUser:{id:-999}};
           return response;
        }
        throw (err);
    }
}

// const getPortalLogin = async() => {
//     try {
//         const response = await api.get('/').then(response => {return response});
//         return response;
//     } catch (err) {
//         console.log(err);
//     }
// }

// const getPortalUserLogin = async() => {
//     try {
//         const response = await api.get('/').then(response => {return response});
//         return response;
//     } catch (err) {
//         console.log(err);
//     }
// }

const getPortalLogout = async() => {
    try {
        const response = await api.get('/logout').then(response => {return response});
        return response;
    } catch (err) {
        console.log(err);
    }
}
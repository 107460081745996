
/*** math utilties ***/
export function calcRandomInt(max) {
  return Math.floor(Math.random() * max);
}

/*** copy utilties ***/
export const deepCopy = (toCopy) => {
  if (toCopy == undefined) { return {}; }
  return JSON.parse(JSON.stringify(toCopy));
};

/*** inspection utilties ***/
export const isNotEmpty = (toVerify) => {
  if (!isDefined(toVerify)) { return false; };
  if (!isBlankableType(toVerify)) { return true; };
  if (isString(toVerify)) { if (toVerify != '' && toVerify.length > 0) { return true; }; };
  if (isArray(toVerify)) { if (toVerify.length > 0) { return true; }; };
  if (isObject(toVerify)) { if (Object.keys(toVerify).length > 0) { return true; }; };
  return false;
};

export const isEmpty = (toVerify) =>{
  return !isNotEmpty(toVerify);
}

export const isDefined = (toVerify) => {
  if ((toVerify == undefined) || (toVerify == null)) {
    return false;
  }; return true;
};

/*** clean up utilties ***/
export const cleanUpNum = (toClean) => {
  return ((isNum(toClean)) ? Number(toClean) : -1);
};

export const cleanUpArray = (toClean) => {
  if (isNotEmpty(toClean) == false || isArray(toClean) == false) { return []; };
  return toClean
};


/*** verification utilties ***/
export const isString = (toVerify) => {
  if ((isDefined(toVerify)) &&
    ((typeof toVerify === 'string') || (toVerify instanceof String))) {
    return true;
  } return false;
};

export const isArray = (toVerify) => {
  if (isDefined(toVerify) &&
    ((Array.isArray(toVerify)) || (toVerify instanceof Array))) {
    return true;
  } return false;
};

export const isObject = (toVerify) => {
  if (isDefined(toVerify) && (Array.isArray(toVerify) == false) &&
    ((toVerify instanceof Object) || (typeof toVerify === 'object'))) {
    return true;
  } return false;
};

export const isBlankableType = (toVerify) => {
  if ((isDefined(toVerify)) &&
    ((typeof toVerify === 'string') || (typeof toVerify === 'object'))) {
    return true;
  } return false;
};

export const isNum = (toVerify) => {

  if ((isDefined(toVerify)) && (
    (typeof Number(toVerify) === 'number') || (typeof Number(toVerify) === 'bigint'))) {
    return true;
  } return false;
};

export const truncateWithEllipses = (text, max=200) => {
  if (isEmpty(text)) {return '';}
  return text.substr(0, max - 1) + (text.length > max ? '...' : '');
}
export const truncateWithEllipsesCustom = (text, max) => {
  if (isEmpty(text)) {return '';}
  return text.substr(0, max - 1) + (text.length > max ? '...' : '');
}

export const truncateWithEllipsesMax50 = (text, max=50) => {
  if (isEmpty(text)) {return '';}
  return text.substr(0, max - 1) + (text.length > max ? '...' : '');
}

export class PaginationState {

  pathname: string = "";
  currentPage: number = 1;
  maxSlots: number = 7;
  totalPages: number = 1;
  pageSize: number = 20;
  totalObjects: number = 0;
  onClickNext: () => void = () => { if (this.currentPage < this.totalPages) this.currentPage += 1; this.handleStateChange() };
  onClickPrevious: () => void = () => { if (this.currentPage > 1) this.currentPage -= 1; this.handleStateChange() };
  onClickPageNumber: (event: React.MouseEvent<HTMLButtonElement>, page: number) => void = (event, page) => { this.currentPage = page; this.handleStateChange() };
  handleStateChange: () => void;

  constructor(handleStateChange: () => void) {
    this.handleStateChange = handleStateChange;
  }

  getAsPaginationProps() {
    return {
      pathname: this.pathname, currentPage: this.currentPage, maxSlots: this.maxSlots, totalPages: this.totalPages,
      onClickNext: this.onClickNext, onClickPrevious: this.onClickPrevious, onClickPageNumber: this.onClickPageNumber
    };
  }

  computeTotalPages(totalObjects: number) {
    this.totalObjects = totalObjects;
    try {
      this.totalPages = Math.ceil(this.totalObjects / this.pageSize);
    }
    catch (e) {
      this.totalPages = 1;
    }
  }

  resetPageNumber() {
    try {
      this.currentPage = 1;
    } catch (e) {
    }
  }
}

export const trimStringToLength = (string, length) => {
  try{
    if (isNotEmpty(string) && isNotEmpty(length)) {
      return string.subString(0, length);
    }
  } catch (e) {
    return "";
  }
}



import { API_DATA_SOURCE, ENV_LOCAL, ENV_PROD, ENV_QA10, ENV_QA9, MOCK_DATA_SOURCE } from "ui/config/constants";

export const lookupDataSourceType = (env) => {
    switch (env) {
        case ENV_LOCAL   : return API_DATA_SOURCE;
        case ENV_QA10    : return MOCK_DATA_SOURCE;
        case ENV_QA9     : return API_DATA_SOURCE;
        case ENV_PROD    : return API_DATA_SOURCE;
    }
    return MOCK_DATA_SOURCE;
}

import React, { useRef, useState } from 'react';

import * as ImgConst from 'ui/modules/imageConstants';
import { ModalRef,} from '@trussworks/react-uswds';

import '../../common/portal-standards.scss'
import { prepareControllers } from 'ui/modules/common/logic/utilities/controller-utilities';
import PortalSuccessModal from './PortalSuccessModal';
import PortalErrorModal from './PortalErrorModal';

export const PortalModalControl = (myProps) => {

 // let modalRefs ={detailRef : detailLaunchRef, submitRef : submitLaunchRef,
 //   validationRef : validationLaunchRef, successRef:successLaunchRef};  

 
  /************************************************* */
  const errorLaunchRef = useRef<ModalRef>(null);
  const successLaunchRef = useRef<ModalRef>(null);
  /* *** *** */
  let modalRefs = {errorRef :errorLaunchRef, successRef:successLaunchRef};
  /************************************************* */
  const contentRefreshLoader:any = {};
  const refreshModal = () => {
    contentRefreshLoader.refreshErrorView();contentRefreshLoader.refreshSuccessView();return;};
  /************************************************* */
  prepareControllers({modal :{refs:modalRefs,refreshContent:refreshModal,}});
  /************************************************* */

  /* *** *** */
  return (<><PortalErrorModal   contentRefreshLoader={contentRefreshLoader} registerRef={modalRefs.errorRef}/>
            <PortalSuccessModal contentRefreshLoader={contentRefreshLoader} registerRef={modalRefs.successRef}/></>);};

export default PortalModalControl;
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { serializeAxiosError } from './reducer.utils';

import { AppThunk } from 'ui/config/store';
import api from '../axios';
import { ENV_LOCAL, ENV_PROD } from 'ui/config/constants';

export const initialState = {
  loading: false,
  isAuthenticated: false,
  isRegistered: false,
  loginSuccess: false,
  loginError: false, // Errors returned from server side
  isAllowedPastWall: false,
  account: {} as any,
  errorMessage: null as unknown as string, // Errors returned from server side
  authErrorMessage: null as unknown as string,
  redirectMessage: null as unknown as string,
  sessionHasBeenFetched: false,
  logoutUrl: null as unknown as string,
  taapenv: "prod" as unknown as string,
  lastSuccessfulResponseTime: 0 as number,
};

export type AuthenticationState = Readonly<typeof initialState>;

// Actions

export const getSession = (): AppThunk => (dispatch, getState) => {
  dispatch(getAccount());
};

export const getAccount = createAsyncThunk('authentication/get_account',
  async () => api.get<any>('/loggedInUser'), {
  serializeError: serializeAxiosError,
});

export const authenticate = createAsyncThunk(
  'authentication/login',
  async (data: string) => api.post<any>('/perform_login', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }),
  {
    serializeError: serializeAxiosError,
  }
);

export const login: (username: string, password: string, rememberMe?: boolean) => AppThunk =
  (username, password, rememberMe = false) =>
    async dispatch => {
      const data = `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}&remember-me=${rememberMe}&submit=Login`;
      await dispatch(authenticate(data));
      dispatch(getSession());
    };

export const logoutServer = createAsyncThunk('authentication/logout', async () => axios.post<any>('api/logout', {}), {
  serializeError: serializeAxiosError,
});

export const logout: () => AppThunk = () => async dispatch => {
  await dispatch(logoutServer());
  // fetch new csrf token
  dispatch(getSession());
};

export const clearAuthentication = messageKey => dispatch => {
  dispatch(authError(messageKey));
  dispatch(clearAuth());
};
export const onResponseSuccess = () => dispatch => {
  dispatch(onSuccessfulResponse());
};

export const register = createAsyncThunk(
  'authentication/register',
  async (data: any) => api.post<any>('/register', data),
  {
    serializeError: serializeAxiosError,
  }
);

export const submitFromPreProdWall = createAsyncThunk(
  'authentication/submitFromPreProdWall',
  async (data: any) => api.post<any>('/submitFromPreProdWall', data),
  {
    serializeError: serializeAxiosError,
  }
);

export const getEnvironment = createAsyncThunk('authentication/getEnvironment',
  async () => api.get<any>('/environment'), {
  serializeError: serializeAxiosError,
});

export const ajaxPost = (e, path, data) => { e.preventDefault(); api.post<any>(path, data); };
export const ajaxGet = (e, path) => { e.preventDefault(); api.get(path); };

export const getResponseState = action => { try { return action.payload.data.responseState } catch { } };
export const getErrorMessage = action => { try { return action.payload.data.errors[0] } catch { } };
export const getResponsePayload = action => { try { return action && action.payload && action.payload.data && action.payload.data.payload } catch { } };


export const AuthenticationSlice = createSlice({
  name: 'authentication',
  initialState: initialState as AuthenticationState,
  reducers: {
    authError(state, action) {
      return {
        ...state,
        redirectMessage: action.payload,
      };
    },
    clearAuth(state) {
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
      };
    },
    onSuccessfulResponse(state) {
      return {
        ...state,
        lastSuccessfulResponseTime: Date.now(),
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(authenticate.rejected, (state, action) => ({
        ...initialState,
        errorMessage: action.error.message,
        loginError: true,
      }))
      .addCase(authenticate.fulfilled, state => ({
        ...state,
        loading: false,
        loginError: false,
        loginSuccess: true,
      }))
      .addCase(getAccount.rejected, (state, action) => ({
        ...state,
        loading: false,
        isAuthenticated: false,
        isRegistered: false,
        sessionHasBeenFetched: true,
        errorMessage: action.error.message,
      }))
      .addCase(getAccount.fulfilled, (state, action) => {
        // const isAuthenticated = action.payload && action.payload.data && action.payload.data.activated;
        const isAuthenticated = getResponsePayload(action) && !getErrorMessage(action);
        const isRegistered = isAuthenticated && action.payload.data.payload.registered;
        const authErrorMessage = 'user_not_authorized' == getErrorMessage(action) ? 'You are not authorized to access this application' : null;
        const account = isAuthenticated && action.payload.data.payload
        const isAllowedPastWall = getResponsePayload(action) && getResponsePayload(action).allowedPastWall;
        return {
          ...state,
          isAuthenticated,
          isRegistered,
          isAllowedPastWall,
          authErrorMessage,
          loading: false,
          sessionHasBeenFetched: true,
          account,
        };
      })
      .addCase(register.rejected, (state, action) => ({
        ...state,
        errorMessage: action.error.message,
        loginError: true,
      }))
      .addCase(register.fulfilled, (state, action) => {
        return {
          ...state,
          isRegistered: getResponseState(action) == 'SUCCESS',
          errorMessage: getErrorMessage(action)
        };
      })
      .addCase(getEnvironment.rejected, (state, action) => {
        const taapenv = window.location.href.includes('localhost:') ? ENV_LOCAL : ENV_PROD
        return {
          ...state,
          taapenv,
          errorMessage: action.error.message,
        };
      })
      .addCase(getEnvironment.fulfilled, (state, action) => {
        const responsePayload = getResponsePayload(action);
        const taapenv = (responsePayload && responsePayload.taapenv) || ENV_PROD;
        return {
          ...state,
          taapenv,
          errorMessage: getErrorMessage(action)
        };
      })
      .addCase(submitFromPreProdWall.rejected, (state, action) => {
        return {
          ...state,
          isAllowedPastWall: false,
          errorMessage: action.error.message,
        };
      })
      .addCase(submitFromPreProdWall.fulfilled, (state, action) => {
        const responsePayload = getResponsePayload(action);
        const authErrorMessage = 'user_not_authorized' == getErrorMessage(action) ? 'Incorrect password' : null;
        const isAllowedPastWall = responsePayload && !getErrorMessage(action);
        return {
          ...state,
          authErrorMessage,
          isAllowedPastWall,
          errorMessage: getErrorMessage(action)
        };
      })
      .addCase(logoutServer.fulfilled, state => ({
        ...initialState,
      }))
      .addCase(authenticate.pending, state => {
        state.loading = true;
      })
      .addCase(getAccount.pending, state => {
        state.loading = true;
      });
  },
});

export const { authError, clearAuth, onSuccessfulResponse } = AuthenticationSlice.actions;

// Reducer
export default AuthenticationSlice.reducer;

import { countiesByStateMap } from "../../constants/stateCountyConstants";
import { deepCopy, isNotEmpty } from "./utilities";

export class CacheExplorer {
    protected _store;
    protected _codesMap;

    public prepare(storeParam) {
        this._store = storeParam;
        
    }

    public fetchCodeDescription(codeType, code) {
        try {
            this.loadCodesMap();
            if (isNotEmpty(codeType) && isNotEmpty(code)) {
                return this._codesMap.get(codeType).get(code);
            }
        } catch (e) {
        }
        return "";
    }

    private loadCodesMap() {
        if (isNotEmpty(this._codesMap) == false) {
            var codesList: any;
            if (isNotEmpty(this._store.getState().ftt)) {
                codesList = deepCopy(this._store.getState().ftt.codesList);
            } else if (isNotEmpty(this._store.getState().mrtg)) {
                codesList = deepCopy(this._store.getState().mrtg.codesList);
            }
            this._codesMap = this.createCodesMap(codesList);
        }
    }

    private createCodesMap(codesListParam) {
        const result = new Map();
        if (isNotEmpty(codesListParam)) {
            codesListParam.forEach(codeTypeList => {
                var codeType = codeTypeList.codeType;
                result.set(codeType, new Map());
                if (isNotEmpty(codeTypeList.codeList)) {
                    codeTypeList.codeList.forEach(codeObj => {
                        result.get(codeType).set(codeObj.code, codeObj.codeDescription);
                    });
                }
            });
        }
        return result;
    }

    public fetchCountyLabel(stateCode, countyCode) {
        try {
            return countiesByStateMap[stateCode].find(county => county.code === countyCode).label;
        } catch (e) {
        }
        return "";
    }
}

export const PortalCacheExplorer = new CacheExplorer();
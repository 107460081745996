
import { deepCopy, isNotEmpty } from "ui/modules/common/logic/utilities/utilities";
import { LoginPortalCommand } from "../../core/commands/core-commands";
import { PortalAppController } from "../../core/controllers";


/* ***     *** */
const __lookupLoginUser = (store) => {
  return deepCopy(store.getState().portalServ.session.loginUser);};
const __verifyLoginUser = (loginUser) => {
  return ((isNotEmpty(loginUser) && (loginUser.id!=-999))? true: false);};
  
/* ***     *** */
const __lookupSession = (store) => {
  return deepCopy(store.getState().portalServ.session);};
const __verifySession = (session) => {
  return ((isNotEmpty(session) && isNotEmpty(session.token) && (session.token!=-999))?true:false);};
  
/* ***     *** */
const __lookupAccounts = (store) => {
  return deepCopy(store.getState().portalServ.accounts);};

/* ***     *** */
export const MockFetchSessionPortalServCommand = {
  key:'portal-serv-fetch-session',
  controllerValidationKey : 'portal-service',
  executeCommand : async function(payload,store,controls) {
    if (__verifySession(__lookupSession(store)) == false) {
      store.dispatch({type:"portal-serv/session/set",payload:{token:1299,loginUser:{id:-999},sessionTimeoutSeconds:15*60}});
    }
    return __lookupSession(store);
  },
};

export const MockLoginPortalServCommand = {
  key:'portal-serv-login',
  controllerValidationKey : 'portal-service',

  executeCommand : async function(payload,store,controls) {
    var loginUser = __lookupAccounts(store).filter(user => (user.email === payload.email));
    store.dispatch({type:"portal-serv/session/login",payload:loginUser[0]});
    store.dispatch({type:"portal-serv/session/data-owner/set",payload:loginUser[0]});
    return __lookupLoginUser(store);
  },
};

export const MockLogoutPortalServCommand = {
  key:"portal-serv-logout",
  controllerValidationKey : 'portal-service',

  executeCommand : async function(payload,store,controls) {
    store.dispatch({type:"portal-serv/session/logout",payload:{id:-999}});
    return __lookupLoginUser(store);
  },
};

export const MockFetchAllUsersServCommand = {
  key:"portal-serv-logout",
  controllerValidationKey : 'portal-service',

  executeCommand : async function(payload,store,controls) {
    let accountList = __lookupAccounts(store);
    return accountList;
  },
}

export const MockFetchFilteredUsersServCommand = {
  key:"fetch-users-by-email-serv",
  controllerValidationKey : 'portal-service',

  executeCommand : async function(payload,store,controls) {
    let filteredList = __lookupAccounts(store);
    if (isNotEmpty(filteredList)) {
      filteredList.forEach(account => {
        let fullName = buildAccountName(account.portalInfo);
        account.fullName = fullName;
        account.loginName = account.email;
      });

      if (isNotEmpty(payload.userEmail)) {
        filteredList = filteredList.filter((account, index) => {
          return (account.email.includes(payload.userEmail));
        })
      }
      if (isNotEmpty(payload.userName)) {
        filteredList = filteredList.filter((account, index) => {
          return (account.fullName.toLowerCase().includes(payload.userName.toLowerCase()));
        })
      }
    }
    return filteredList;
  },
}

export const buildAccountName = (account) => {
  let result = "";
  if (isNotEmpty(account)) {
    if (isNotEmpty(account.firstName)) {
      result = result + account.firstName;
    }
    if (isNotEmpty(account.middleName)) {
      result = result + " " + account.middleName;
    }
    if (isNotEmpty(account.lastName)) {
      result = result + " " + account.lastName;
    }
  }
  return result;
}

/** *******************************************************
 Mock Commands
******************************************************* **/
export const MockOffloadSessionPortalServCommand = {
  key:'portal-serv-mock-offload-session',
  controllerValidationKey : 'portal-service',

  executeCommand : async function(payload,store,controls) {
    store.dispatch({type:"portal/session/unload",payload:undefined});},};

export const MockRegisteredSessionPortalServCommand = {
  key:'portal-serv-mock-login-registered',  
  controllerValidationKey : 'portal-service',

  executeCommand : async function(payload,store,controls) {
    let emailVal = '';
    switch (payload) {
      case 'EMPTY' : emailVal='empty@bia.gov';break;
      case 'SINGLE' : emailVal='single@bia.gov';break;
      case 'MULTIPLE' : emailVal='multiple@bia.gov';break;};
    await PortalAppController.executeCommand(LoginPortalCommand,{email:emailVal,password:'eeje'});},};

export const MockUnregisteredSessionPortalServCommand = {
  key:'portal-serv-mock-login-unregistered',
  controllerValidationKey : 'portal-service',

  executeCommand : async function(payload,store,controls) {
    await PortalAppController.executeCommand(LoginPortalCommand,{email:'unregistered@bia.gov',password:'eeje'});},};


import React, { useState } from 'react';
import Helmet from 'react-helmet';

import { GridContainer, Grid, Button, Fieldset, Form, Label, TextInput} from '@trussworks/react-uswds';

import './portalLogin.scss';
import '../../../common/portal-standards.scss';
import MockButtonRow from 'ui/modules/common/ui-components/mock-buttons';
import { PortalAppController } from '../../logic/core/controllers';
import { LaunchLoginInfoModalPortalCommand } from '../../logic/core/commands/view-actions-commands';
import PortalModalControl from '../../modal/PortalModalControl';
import { useAppDispatch, useAppSelector } from 'ui/config/store';
import { lookupDataSourceType } from 'ui/modules/common/logic/utilities/environment-utilities';
import { API_DATA_SOURCE, MOCK_DATA_SOURCE } from 'ui/config/constants';
import { getEnvironment } from 'ui/modules/common/reducers/authentication';



export const PortalLoginScreenDev = () => {

  const [showPassword, setShowPassword] = React.useState(false);

  const [loginEmail, setLoginEmail] = React.useState('');
  const [loginPassword, setLoginPassword] = React.useState('');

  const taapenv = useAppSelector(state => state.authentication.taapenv);
  const dispatch = useAppDispatch();

  const handleEntry = (event) => {
    switch (event.target.id) {
      case 'email-field' : setLoginEmail(event.target.value);return;
      case 'password-field' : setLoginPassword(event.target.value);return;
    };return;
  };


  const handleLoginClick = (eventVal) => {
    PortalAppController.executeCommand(LaunchLoginInfoModalPortalCommand,{email:loginEmail,password:loginPassword});
    dispatch(getEnvironment());
    return;
  };

  const displayLoginInfo = () => {
    let ds = lookupDataSourceType(taapenv);

    if (ds==MOCK_DATA_SOURCE) {
      return (<>
      <p> This login is specfic to the QA-TRAIN-UAT test environments.  We had to implement this login since there is no access to the internet from the PDC environment.  
      Login.gov is not avaiable without an internet connection.</p>
      <ul><li key='email-1'>Login with user "empty@bia.gov" and a random non-blank password to work as a user who has yet to submit an application</li>
        <li key='email-2'>Login with user "single@bia.gov" and a random non-blank password to work as a user who has submitted a single application</li>
        <li key='email-3'>Login with user "multiple@bia.gov" and a random non-blank password to work as user who has submitted multiple applications</li>
        <li key='email-4'>Login with user "unregistered@bia.gov" and a random non-blank password to work as user a who hasnt registered with the FTT Tool</li></ul></>);};

    if (ds==API_DATA_SOURCE) {
      return (<>
      <p> This login is specfic to the QA-TRAIN-UAT test environments.  We had to implement this login since there is no access to the internet from the PDC environment.  
      Login.gov is not avaiable without an internet connection.  Here is a list of available users.</p>
      <ul>
      <li key='email-1'>testuser10@bia.gov    P@$$w0rd192</li>
      <li key='email-2'>testuser9@bia.gov     P@$$w0rd906</li>
      <li key='email-3'>testuser8@bia.gov     P@$$w0rd844</li>
      <li key='email-4'>testuser7@bia.gov     P@$$w0rd712</li>
      <li key='email-5'>testuser6@bia.gov     P@$$w0rd955</li>
      <li key='email-6'>testuser5@bia.gov     P@$$w0rd234</li>
      <li key='email-7'>testuser4@bia.gov     P@$$w0rd611</li>
      <li key='email-8'>testuser3@bia.gov     P@$$w0rd582</li>
      <li key='email-9'>testuser2@bia.gov     P@$$w0rd496</li>
      <li key='email-10'>testuser@bia.gov     P@$$w0rd835</li></ul></>);};};

  return ( 
  <>
    <Helmet><title>TAAP : Trust Asset Assistance Portal : Login Dev page </title></Helmet>
    <main className='.portal-main'>
    <MockButtonRow/>
    <div className="bg-base-lightest">
          <GridContainer className="usa-section">
            <Grid
              row={true}
              className="margin-x-neg-205 margin-bottom-7 flex-justify-center">
              <Grid  col={12}  mobileLg={{ col: 10 }}  tablet={{ col: 8 }} desktop={{ col: 6 }} className="padding-x-205 margin-bottom-7">

                <h1 className="desktop:display-none font-sans-lg margin-bottom-4 tablet:margin-top-neg-3"></h1>
                <div className="bg-white padding-y-3 padding-x-5 border border-base-lighter">
                  <h1 className="margin-bottom-0">Dev Login</h1>
                  <br/><br/>
                  <Form onSubmit={((event) => {})}>
                    <Fieldset legend="">
                      {displayLoginInfo()}

                      <Label htmlFor="email-field"> Email:{' '}</Label>
                      <TextInput id="email-field"  name="email-field" type='text' value={loginEmail}
                                  onChange={handleEntry} autoCapitalize="off"  autoCorrect="off" required={true} aria-required="true"/>

                      <Label htmlFor="password-field">Password:{' '}</Label>
                      <TextInput id="password-field" name="password-field" value={loginPassword}
                                  onChange={handleEntry} type={showPassword ? 'text' : 'password'}
                                  autoCapitalize="off" autoCorrect="off" required={true} aria-required="true"/>

                      <p className="usa-form__note">
                        <a  title="Show password"  href="javascript:void(0);"  className="usa-show-password"
                          aria-controls="password-create-account password-create-account-confirm"
                          onClick={(): void => setShowPassword((showPassword) => !showPassword)}>
                          {showPassword ? 'Hide password' : 'Show password'}
                        </a>
                      </p>
                      <Button type="button" onClick={handleLoginClick}>Login</Button>                        
                    </Fieldset>
                  </Form>
                </div>
              </Grid>

            </Grid>
          </GridContainer>
        </div>
    </main><PortalModalControl/></>);};
export default PortalLoginScreenDev;
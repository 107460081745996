import { isNotEmpty } from "ui/modules/common/logic/utilities/utilities";
import { PortalAppController } from "../controllers";
import { LoginPortalCommand } from "./core-commands";


/* ******************************************************** */
export const LaunchLoginInfoModalPortalCommand = {
  key:'portal-launch-success-message',
  controllerValidationKey : 'portal-app',
   //{email:loginEmail,password:loginPassword}
  executeCommand : async function(payload,store,controls) {
    let isLoggedIn:any = false;
    if (isNotEmpty(payload) && isNotEmpty(payload.email) && isNotEmpty(payload.password)) {
      isLoggedIn = await PortalAppController.executeCommand(LoginPortalCommand,payload);};

    controls.modal.refreshContent();
    if (isLoggedIn) {
      controls.modal.refs.successRef.current.toggleModal(payload);
    } else {
      controls.modal.refs.errorRef.current.toggleModal(payload);};},};
/* ******************************************************** */
export const ResetModalDataFTTCommand = {
  key:'portak;-reset-modal-data',
  controllerValidationKey : 'portal-app',

  executeCommand : async function(payload,store,controls) {
    store.dispatch({type:'portal/modal-data/reset',payload:undefined});return;},};
/* ******************************************************** */